const english = {
    roles: "Roles",
    name: "Name",
    sl: "SL",
    action: "Action",
    add: "Add",
    save: "Save",
    update: "Update",
    saveMessage: "Data has been saved successfully",
    updateMessage: "Data has been updated successfully",
    deleteMessage: "Data has been deleted successfully",
    cancel: "Cancel",
    success: "Success",
    error: "Error",
    areYouSure: "Are you sure?",
  deleteWarning: "You won't be able to revert this!",
  yesDelete: "Yes, delete it!",
  yes: "Yes",
  no: "No",

}
const malay = {
    roles: "Peranan",
    name: "Nama",
    sl: "SL",
    action: "Tindakan",
    add: "Tambah",
    save: "Simpan",
    update: "Kemas Kini",
    saveMessage: "Data telah berjaya disimpan",
    updateMessage: "Data telah berjaya dikemas kini",
    deleteMessage: "Data telah berjaya dipadam",
    areYouSure: "Adakah anda pasti?",
    youWillNotBeAbleToRevertThis: "Anda tidak akan dapat membatalkan ini!",
    yesDeleteIt: "Ya, padamkan ia!",
    cancel: "Batal",
    success: "Berjaya",
    error: "Ralat",
      deleteWarning: "Anda tidak akan dapat membalikkan ini!",
      yesDelete: "Ya, padamkannya!",
  
  }
  const thai = {
    roles: "บทบาท",
    name: "ชื่อ",
    sl: "ลำดับ",
    action: "การดำเนินการ",
    add: "เพิ่ม",
    save: "บันทึก",
    update: "อัปเดต",
    saveMessage: "บันทึกข้อมูลเรียบร้อยแล้ว",
    updateMessage: "อัปเดตข้อมูลเรียบร้อยแล้ว",
    deleteMessage: "ลบข้อมูลเรียบร้อยแล้ว",
    areYouSure: "คุณแน่ใจหรือไม่?",
    youWillNotBeAbleToRevertThis: "คุณจะไม่สามารถยกเลิกการกระทำนี้ได้!",
    yesDeleteIt: "ใช่, ลบเลย!",
    cancel: "ยกเลิก",
    success: "สำเร็จ",
    error: "ข้อผิดพลาด",
    deleteWarning: "คุณจะไม่สามารถย้อนกลับได้!",
    yesDelete: "ใช่, ลบมัน!",
  
  
  }
  const arabic = {
    roles: "الأدوار",
    name: "الاسم",
    sl: "تسلسل",
    action: "الإجراء",
    add: "إضافة",
    save: "حفظ",
    update: "تحديث",
    saveMessage: "تم حفظ البيانات بنجاح",
    updateMessage: "تم تحديث البيانات بنجاح",
    deleteMessage: "تم حذف البيانات بنجاح",
    areYouSure: "هل أنت متأكد؟",
    youWillNotBeAbleToRevertThis: "لن تتمكن من التراجع عن هذا!",
    yesDeleteIt: "نعم، احذفه!",
    cancel: "إلغاء",
    success: "نجاح",
    error: "خطأ",
    deleteWarning: "لن تتمكن من التراجع عن هذا!",
    yesDelete: "نعم، احذفه!",
  
  }
  const bengali = {
    roles: "ভূমিকা",
    name: "নাম",
    sl: "ক্রম",
    action: "কর্ম",
    add: "যোগ করুন",
    save: "সংরক্ষণ",
    update: "আপডেট",
    saveMessage: "তথ্য সফলভাবে সংরক্ষিত হয়েছে",
    updateMessage: "তথ্য সফলভাবে আপডেট হয়েছে",
    deleteMessage: "তথ্য সফলভাবে মুছে ফেলা হয়েছে",
    areYouSure: "আপনি কি নিশ্চিত?",
    youWillNotBeAbleToRevertThis: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yesDeleteIt: "হ্যাঁ, মুছে ফেলুন!",
    cancel: "বাতিল",
    success: "সফলতা",
    error: "ত্রুটি",
    deleteWarning: "আপনি এটি ফিরিয়ে আনতে পারবেন না!",
    yesDelete: "হ্যাঁ, এটি মুছুন!",
  }
        
export const roleLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}