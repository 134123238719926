import React, { useEffect, useRef, useState } from "react";
import http from "../../../http";
import moment from "moment";
import Setting from "../../Setting/Setting";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import Select from "react-select";
import allCustomStyles from "../../../CSS/ReactModalStyle";
import "./Billing.css";
import { FiRefreshCcw } from "react-icons/fi";
import { FiPrinter } from "react-icons/fi";
import { CircularProgress } from "@mui/material";
import PrintStatmentHeader from "../../Componets/PrintStatmentHeader";
import dayjs from "dayjs";
import useLanguage from "../../../language/useLanguage";
import { billingStatementLang } from "../../../language/billingManagement/billingStatementLang";

export default function BillingStatmentHistory() {
  const lan = useLanguage(billingStatementLang)
  const { currencyFormat, dateFormat } = Setting;
  const { colourStyles } = allCustomStyles;

  const [billingData, setbillingData] = useState();
  const [billingDataMaster, setbillingDataMaster] = useState();
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "month")?.$d);
  const [endDate, setEndDate] = useState(dayjs().add(2, "month")?.$d);
  const [buildings, setbuildings] = useState([]);
  const [Floor, setFloor] = useState([]);
  const [Units, setUnits] = useState([]);
  const [searchInfo, setsearchInfo] = useState({});

  const [isloading, setisloading] = useState(false);

  const [propertyData, setpropertyData] = useState()
  useEffect(() => {
    let sub = false;
    if (!sub) {
      const value = {
        startDate: startDate,
        endDate: endDate,
      };
      dataGetDateWise(value);

      http
        .get("buidling-wise-floor-info")
        .then((res) => {
          setpropertyData(res.data.data)
          // setbuildings(res.data.data);
        })
        .catch((err) => console.log(err));
    }
    return () => {
      sub = true;
    };
  }, []);

  const reciveTotal = billingData?.reduce(
    (acc, detail) => acc + Number(detail?.received_amount),
    0
  );
  const grandTotal = billingData?.reduce(
    (acc, detail) => acc + Number(detail?.grand_total),
    0
  );
  const Due = billingData?.reduce(
    (acc, detail) => acc + Number(detail?.due),
    0
  );
  const getOrdinal = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = number % 10;
    const suffix =
      lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13
        ? suffixes[lastDigit]
        : suffixes[0];
    return number + suffix;
  };

  const statement = useRef();
  const billingSM = useReactToPrint({
    content: () => statement.current,
    pageStyle: `
      @page {
        size: landscape;
      }
    `,
  });

  const refresh = () => {
    const value = {
      startDate: dayjs().subtract(1, "month")?.$d,
      endDate: dayjs().add(2, "month")?.$d,
    };
    dataGetDateWise(value);
    setsearchInfo();
    setStartDate(value?.startDate);
    setEndDate(value?.endDate);

    clearData()
  };

  const clearData = () => {
    setbuildings([])
    setFloor([])
    setUnits([])
  }


  const dataGetDateWise = (value) => {
    setisloading(true);
    http
      .post("billing-statment-date-wise", value)
      .then((res) => {
        setbillingData(res.data.data);
        setbillingDataMaster(res.data.data);
        setisloading(false);
      })
      .catch((err) => setisloading(false));
  };

  return (
    <div className="page-content adjustment-type-table">
      <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
        <h6>{lan?.billingStatement}</h6>
        <div>
          <button
            style={{ marginTop: "1px" }}
            onClick={billingSM}
            className="btn btn-sm btn-primary float-end ms-1"
          >
            <FiPrinter /> {lan?.print}
          </button>
          <button
            style={{ marginTop: "1px" }}
            onClick={refresh}
            className="btn btn-sm btn-primary float-end ms-1"
          >
            <FiRefreshCcw /> {lan?.refresh}
          </button>
        </div>
      </div>
      <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
        <div className="d-flex gap-2">
          <DatePicker
            selected={startDate}
            placeholderText="Start Date"
            onChange={(date) => {
              setStartDate(date);
              const value = {
                startDate: date,
                endDate: endDate,
              };
              dataGetDateWise(value);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            className="custom_date_picker_styles"
          />
          <p className="mt-2"> {lan?.to} </p>
          <DatePicker
            selected={endDate}
            placeholderText="End Date"
            onChange={(date) => {
              setEndDate(date);

              const value = {
                startDate: startDate,
                endDate: date,
              };
              dataGetDateWise(value);
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="dd/MM/yyyy"
            className="custom_date_picker_styles"
          />
          <div className="col-lg-3">
            <Select
              styles={colourStyles}
              options={propertyData}
              getOptionLabel={(option) => option?.property_name}
              value={propertyData?.filter(
                (item) => item?.id == searchInfo?.project_details_id
              )}
              onChange={(v) => {
                setbuildings(v?.building);
                setsearchInfo({
                  ...searchInfo,
                  project_details_id: v?.id,
                });
                const filter = billingDataMaster?.filter(
                  (i) => Number(i?.floor.project.id) === Number(v?.id)
                );
                console.log("fil p", filter);
                setbillingData(filter)
              }}
              className="me-2"
              placeholder="Property"
            />
          </div>
          <div className="col-lg-3">
            <Select
              styles={colourStyles}
              options={buildings}
              getOptionLabel={(option) => option?.title}
              value={buildings?.filter(
                (item) => item?.building_id == searchInfo?.building_id
              )}
              onChange={(v) => {
                setsearchInfo({ ...searchInfo, building_id: v?.building_id });

                const groupedData = {};
                v?.units?.forEach((unit) => {
                  const floor = unit.floor;

                  if (!groupedData[floor]) {
                    groupedData[floor] = [];
                  }
                  groupedData[floor].push(unit);
                });

                const result = Object.keys(groupedData).map((age) => ({
                  floor: age,
                  unit: groupedData[age],
                }));
                setFloor(result);
                console.log("id", v?.building_id);
                const filter = billingDataMaster?.filter(
                  (item) => Number(item?.building.id) === Number(v?.building_id)
                );
                console.log(filter);
                setbillingData(filter);
              }}
              className="me-2"
              placeholder="Building"
            />
          </div>
          <div className="col-lg-2">
            <Select
              styles={colourStyles}
              options={Floor}
              getOptionLabel={(option) => `${getOrdinal(option?.floor)} Floor`}
              value={Floor?.filter((item) => item?.floor == searchInfo?.floor)}
              onChange={(v) => {
                setsearchInfo({ ...searchInfo, floor: v?.floor });
                setUnits(v?.unit);
                const filterByBuilding = billingDataMaster.filter(
                  (i) =>
                    Number(i?.building.id) === Number(searchInfo?.building_id)
                );
                const filterByFloor = filterByBuilding.filter(
                  (i) => Number(i?.floor?.floor) == Number(v?.floor)
                );
                setbillingData(filterByFloor);
              }}
              className="me-2"
              placeholder="Floor"
            />
          </div>
          <div className="col-lg-2">
            <Select
              styles={colourStyles}
              options={Units}
              value={Units?.filter(
                (item) => item?.unit_name == searchInfo?.unit_name
              )}
              getOptionLabel={(option) => option.unit_name}
              className="me-2"
              placeholder="Unit"
              onChange={(v) => {
                setsearchInfo({ ...searchInfo, unit_name: v?.unit_name });
                // console.log(v)
                const filterByBuilding = billingDataMaster.filter(
                  (i) =>
                    Number(i?.building.id) === Number(searchInfo?.building_id)
                );
                const filterByFloor = filterByBuilding.filter(
                  (i) => Number(i?.floor?.floor) === Number(searchInfo?.floor)
                );
                const filterByUnit = filterByFloor.filter(
                  (i) => i?.floor?.unit_name === v?.unit_name
                );

                setbillingData(filterByUnit);
              }}
            />
          </div>
        </div>
      </div>


      <div ref={statement} className="card">
        <div className="card-body bill-statment">
          {/* <StatmentHeading name={"Billing Statement"} /> */}
          <PrintStatmentHeader name={"Billing Statement"} start={startDate} end={endDate} />
          <div className="unit-manager-table invoice-item-table">
            <table>
              <tr className="billing_Statment_header">
                <td style={{ borderRight: "none !important" }}>
                  {lan?.billingMonth}
                </td>

                <td>{lan?.invoice}</td>
                <td>{lan?.date}</td>
                <td>{lan?.property}</td>
                <td>{lan?.building}</td>
                <td>{lan?.floor}</td>
                <td>{lan?.flat}</td>
                <td>{lan?.owner}</td>
                <td>{lan?.tenant}</td>

                <td>{lan?.mrnSlip}</td>
                <td>{lan?.billAmount}</td>
                <td>{lan?.received}</td>

                <td>{lan?.dueBalance}</td>
              </tr>
              {isloading ? (
                <tr>
                  <td colSpan={12}>
                    
                    <CircularProgress />
                  </td>
                </tr>
              ) : billingData?.length > 0 ? (
                billingData?.map((item, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td>
                          {moment(item?.billing_month).format("MMMM, YYYY")}
                        </td>

                        <td>{item?.invoice}</td>
                        <td>{moment(item?.billing_date).format(dateFormat)}</td>
                        <td>{item?.floor?.project.property_name}</td>
                        <td>{item?.building?.title}</td>
                        <td>{getOrdinal(item?.floor?.floor)}</td>
                        <td>{item?.floor?.unit_name}</td>
                        <td>{item?.floor?.owner?.name}</td>
                        <td>{item?.floor?.tenant?.name}</td>
                        <td></td>
                        <td align="right">
                          {Number(item?.grand_total).toFixed(2)}
                        </td>
                        <td></td>
                        <td align="right">
                          {Number(item?.grand_total).toFixed(2)}
                        </td>
                      </tr>
                      {item?.payment_history.map((v, i) => {
                        const subT = item?.payment_history
                          ?.slice(0, i + 1)
                          .reduce(
                            (acc, detail) => acc + Number(detail?.amount),
                            0
                          );

                        return (
                          <tr>
                            <td></td>

                            <td></td>
                            <td>{moment(v?.created_at).format(dateFormat)}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{v?.slip_number}</td>
                            <td></td>
                            <td align="right">
                              {Number(v?.amount).toFixed(2)}
                            </td>

                            <td align="right">
                              {(
                                Number(item?.grand_total) - Number(subT)
                              ).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td style={{ color: "red" }} colSpan={12}>
                    
                    {lan?.recordsAreNotAvailable}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="10">
                  <b>{lan?.total}</b>
                </td>
                <td align="right">
                  <b>{currencyFormat(reciveTotal)}</b>
                </td>
                <td align="right">
                  <b>{currencyFormat(grandTotal)}</b>
                </td>
                <td align="right">
                  <b>{currencyFormat(Due)}</b>
                </td>
              </tr>
            </table>

            <div
              className="d-flex justify-content-between mt-1"
              style={{ fontWeight: "700", fontSize: "12px" }}
            >
              <div className="d-flex gap-3">
                <p> {lan?.totalBillAmount} : {currencyFormat(grandTotal)}</p>
                <p style={{ color: "green" }}>
                  
                  {lan?.totalReceivedAmount} : {currencyFormat(reciveTotal)}
                </p>
                <p style={{ color: "red" }}>
                  
                  {lan?.totalDueAmount} : {currencyFormat(Due)}
                </p>
              </div>

              <p
                onClick={billingSM}
                className="print_icon"
                style={{ color: "#2b6afd", cursor: "pointer" }}
              >
                
                <FiPrinter /> {lan?.print}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
