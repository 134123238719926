
import MaterialTable from 'material-table'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import http from '../../../http';
import allCustomStyles from '../../../CSS/ReactModalStyle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useLanguage from '../../../language/useLanguage';
import { adminSetupLang } from '../../../language/adminSetup/adminSetupLang';
export default function PoliceStations() {
    const lan = useLanguage(adminSetupLang)
    const [updateButton, setUpdateButton] = useState(false);
    const { policeStationsModule } = allCustomStyles;
    const addData = () => {
        setIsOpen(true);
    }
    const [modalIsOpen, setIsOpen] = useState(false);
    const [inputData, setInputData] = useState({ police_station: "", division_id: "", district_id: "", area_id: "", area_name: "", district_name: "", division_name: '' })
    const deleteRowData = (id) => {
        Swal.fire({
            title: lan?.areYouSure,
            text: lan?.deleteWarning,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: lan?.yesDelete
        })
            .then((result) => {
                if (result.isConfirmed) {
                    http.delete(`police-stations/${id}`)
                        .then((res) => {
                            setUpdate(!update);
                            setIsOpen(false);
                            Swal.fire({
                                title: lan?.deleted,
                                text: lan?.deletedMessage,
                                icon: "success"
                            });
                        })
                }
            })
    }
    const editData = (id) => {
        setIsOpen(true)
        http.get(`police-stations/${id}/edit`)
            .then((res) => {
                console.log(res, 'getEdit')
                setInputData({
                    inputData,
                    id: res?.data?.data?.id,
                    police_station: res?.data?.data?.police_station,
                    division_name: res?.data?.data?.division?.name,
                    division_id: res?.data?.data?.division_id,
                    district_id: res?.data?.data?.district_id,
                    area_id: res?.data?.data?.area_id,
                    district_name: res?.data?.data?.district?.name,
                    area_name: res?.data?.data?.area?.area,
                })
                console.log(inputData, 'edit')
                setIsOpen(true);
                setUpdateButton(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const columns = [
        {
            title: lan?.sl,
            field: "",
            render: (row) => <div>{row?.tableData?.id + 1}</div>,
            width: "20 !important",
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.division,
            render: (row) => <div>{row?.division?.name}</div>,
            field: `division`,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.district,
            render: (row) => <div>{row?.district?.name}</div>,
            field: `division`,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: lan?.area,
            render: (row) => <div>{row?.area?.area}</div>,
            field: `area`,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.policeStation,
            field: `police_station`,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: lan?.action,
            field: "patient",
            render: (row) => <div>{
                row?.deletable == 1 ? (
                    <></>
                ) : (
                    <>
                        <button
                            onClick={() => editData(row?.id)}
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-edit"></i>
                        </button>

                        <button
                            onClick={() => deleteRowData(row?.id)
                            }
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-trash"></i>
                        </button >
                    </>
                )
            }</div >,
            cellStyle: {
                textAlign: "center",
            },
        },
    ];

    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [district, setDistrict] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [area, setArea] = useState([]);
    useEffect(() => {
        const controller = new AbortController();
        setSpinner(true);
        http
            .get(`police-stations`)
            .then((res) => {
                console.log(res, 'res')
                setData(res?.data?.data);
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });
        http
            .get(`get-division-and-district-data`)
            .then((res) => {
                setDivisions(res?.data?.data)
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });


        return () => {
            controller.abort();
        };
    }, [update]);
    // add modal 
    console.log(data, 'setData')

    const handleChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }
    const [isLoading, setisLoading] = useState(false)

    const submitData = (e) => {
        e.preventDefault()
        setisLoading(true)

        if (inputData?.id) {
            http.put(`police-stations/${inputData?.id}`, inputData)
                .then((res) => {
                    setisLoading(false)
                    setUpdate(!update);
                    setIsOpen(false);
                    setInputData({ police_station: "", division_id: "", district_id: "", area_id: "", area_name: "", district_name: "", division_name: '' });
                    setUpdateButton(false)
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: lan?.updateMessage,
                        showConfirmButton: false,
                        timer: 1500
                      })
                })
                .catch((err) => {
                    setisLoading(false)
                    console.log(err)
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        } else {
            http.post('police-stations', inputData)
                .then((res) => {
                    setisLoading(false)
                    setUpdate(!update);
                    setIsOpen(false);
                    setInputData({ police_station: "", division_id: "", district_id: "", area_id: "", area_name: "", district_name: "", division_name: '' });
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: lan?.saveMessage,
                        showConfirmButton: false,
                        timer: 1500
                      })
                })
                .catch((err) => {
                    setisLoading(false)
                    console.log(err)
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        }

    }
    const closeModal = () => {
        setIsOpen(false);
        setUpdateButton(false)
        setInputData({ police_station: "", division_id: "", district_id: "", area_id: "", area_name: "", district_name: "", division_name: '' });
    }
    const handleDivisionChange = (e, value) => {
        setInputData({ ...inputData, ['division_id']: value?.id });
        setDistrict(value?.district)
    }
    const handleDistrictChange = (e, value) => {
        console.log(area, 'area')
        setInputData({ ...inputData, ['district_id']: value?.id });
        setArea(value?.area)
    }
    return (
        <div className='page-content adjustment-type-table'>
            <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                <h6>{lan?.policeStation}</h6>
                <div>
                    <button style={{ marginTop: "1px" }} onClick={addData} className='btn btn-sm btn-primary float-end'>{lan?.add}</button>
                </div>
            </div>

            <MaterialTable
                columns={columns}
                data={data}
                isLoading={spinner}
                options={{
                    search: true,
                    showTitle: false,
                    searchFieldAlignment: "left",
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
            />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={policeStationsModule}
                contentLabel="Example Modal"
            >
                <div className="product_modal">
                    <div className="page-content">
                        <div className=" patients-head ">
                            <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name"> {updateButton === true ? lan?.edit : lan?.add}
                                <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={closeModal} className='float-end'><i className="fal fa-times"></i></span>
                            </h5>
                        </div>

                        <div className=" p-3">
                            <form onSubmit={submitData}>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> {lan?.division} <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            required
                                            disablePortal
                                            id="combo-box-demo"
                                            options={divisions}
                                            getOptionLabel={(option) => option?.name}
                                            sx={{ width: '100%' }}
                                            size='small'
                                            onChange={(event, value) => {
                                                handleDivisionChange(event, value)
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            renderInput={(params) => <TextField {...params} label={inputData?.division_name} required={inputData?.division_id?.length === 0} />}
                                        />

                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> {lan?.district} <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            required
                                            disablePortal
                                            id="combo-box-demo"
                                            options={district}
                                            getOptionLabel={(option) => option?.name}
                                            sx={{ width: '100%' }}
                                            size='small'
                                            onChange={(event, value) => {
                                                handleDistrictChange(event, value)
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            renderInput={(params) => <TextField {...params} label={inputData?.district_name} required={inputData?.district_id?.length === 0} />}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> {lan?.area} <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            required
                                            disablePortal
                                            id="combo-box-demo"
                                            options={area}
                                            getOptionLabel={(option) => option?.area}
                                            sx={{ width: '100%' }}
                                            size='small'
                                            onChange={(event, value) => {
                                                setInputData({ ...inputData, area_id: value?.id })
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.area}
                                            renderInput={(params) => <TextField {...params} label={inputData?.area_name} required={inputData?.area_id?.length === 0} />}
                                        />

                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label htmlFor="type" className="mb-1">
                                            {lan?.policeStation}
                                        </label>
                                    </div>
                                    <div className='col-9'>
                                        <input onChange={handleChange} value={inputData?.police_station} name="police_station" type="text" className="form-control form-control-sm my-2" required placeholder="Title" />
                                    </div>
                                </div>
                                {isLoading ?
                                    <button className="btn mt-2 btn-sm btn-info float-end text-uppercase" type="button"> {lan?.loading} ...</button>
                                    : updateButton === true ? (
                                        <button className="btn mt-2 btn-sm btn-success float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> {lan?.update}</button>
                                    ) : (
                                        <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> {lan?.save}</button>
                                    )}
                            </form>

                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}
