import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import cr from "../front_assets/images/currencies.png";
import location from "../front_assets/images/map.png";
import time from "../front_assets/images/timezone.png";
import lang from "../front_assets/images/languages.png";
import http from "../http";
import propertieContext from "../navbar/propertieContext";
import useLanguage from "../language/useLanguage";
import { settingLang } from "../language/settings/settingLang";
const languageStatic = [
  { id: 1, name: "English" },
  { id: 2, name: "Thai" },
  { id: 3, name: "Malay" },
  { id: 4, name: "Arabic" },
  { id: 5, name: "Bengali" },
];

const dateFormat = [
  { id: 1, name: "DD/MM/YYYY" },
  { id: 2, name: "MM/DD/YYYY" },
  { id: 3, name: "YYYY/MM/DD" },
  { id: 4, name: "YYYY/DD/MM" },
  { id: 5, name: "DD-MM-YYYY" },
  { id: 6, name: "MM-DD-YYYY" },
  { id: 7, name: "YYYY-MM-DD" },
  { id: 8, name: "YYYY-DD-MM" },
];
export default function Settings() {
  const [settings, setSettings] = useState({
    location: "",
    currency: "",
    timezone: "",
    language: "",
    date_format: "",
  });
  const [country, setCountry] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [timezone, setTimezone] = useState([]);
  const [language, _] = useState([...languageStatic]);
  const [loading, setLoading] = useState({
    country: false,
    currency: false,
    timezone: false,
  });
  const { setSetting } = useContext(propertieContext);
  useEffect(() => {
    http.get("settings").then(({ data }) => {
      setSettings(data.data);
    });
  }, []);
  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      setLoading({
        country: true,
        currency: true,
        timezone: true,
      });
      try {
        const { data } = await http.get("country");
        setCountry(data.data);
        setLoading({ ...loading, country: false });

        const { data: timezoneData } = await http.get("time-zone");
        setTimezone(timezoneData.data);
        console.log(timezoneData.data);
        setLoading({ ...loading, timezone: false });
        const { data: currencyData } = await http.get("currency");
        setCurrency(currencyData.data);
        setLoading({ ...loading, currency: false });
      } catch (error) {
        setLoading({
          country: false,
          currency: false,
          timezone: false,
        });
        console.log(error);
      }
    })();

    return () => {
      controller.abort();
    };
  }, []);
  const updateSetting = async () => {
    try {
      const updateData = {
        ...settings,
        currency_symbol:
          currency.find((cur) => cur.name === settings.currency)?.symbol || "",
      };
      const res = await http.post("settings", updateData);
      setSetting(res?.data?.data);
      Swal.fire("Success", "Settings updated successfully", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to update settings", "error");
    }
  };
  const lan = useLanguage(settingLang);

  return (
    <div className="page-content">
      <div className="custom-card patients-head ">
        <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-title">
          {lan?.settings}
        </h5>
      </div>
      <div className="custom-card mt-2 p-2">
        <div className="row">
          <div className="col-3">
            <div className=" custom-card p-2 m-2">
              <div className="text-center mb-3">
                <img
                  style={{ width: "80px" }}
                  src={location}
                  alt=""
                  className="mx-auto img-fluid"
                />
              </div>
              <h5 className="text-center mb-2">{lan?.country}</h5>
              <Select
                options={country}
                onChange={(country) =>
                  setSettings({ ...settings, location: country.name })
                }
                placeholder={"Select"}
                // isDisabled={true}
                isLoading={loading.country}
                getOptionLabel={(country) => `${country.name}`}
                getOptionValue={(country) => `${country.name}`}
                value={country.find(
                  (supplier) => supplier.name === settings?.location
                )}
              />
            </div>
          </div>
          <div className="col-3">
            <div className=" custom-card p-2 m-2">
              <div className="text-center mb-3">
                <img
                  style={{ width: "80px" }}
                  src={cr}
                  alt=""
                  className="mx-auto img-fluid"
                />
              </div>
              <h5 className="text-center mb-2">{lan?.currency}</h5>
              <Select
                options={currency}
                onChange={(currency) =>
                  setSettings({
                    ...settings,
                    currency: currency.name,
                  })
                }
                placeholder={"Select"}
                // isDisabled={true}
                isLoading={loading.currency}
                getOptionLabel={(currency) => `${currency.name}`}
                getOptionValue={(currency) => `${currency.name}`}
                value={currency.find(
                  (supplier) => supplier.name === settings?.currency
                )}
              />
            </div>
          </div>
          <div className="col-3">
            <div className=" custom-card p-2 m-2">
              <div className="text-center mb-3">
                <img
                  style={{ width: "80px" }}
                  src={time}
                  alt=""
                  className="mx-auto img-fluid"
                />
              </div>
              <h5 className="text-center mb-2">{lan?.timezone}</h5>
              <Select
                options={timezone}
                onChange={(timezone) =>
                  setSettings({
                    ...settings,
                    timezone: timezone.name,
                  })
                }
                placeholder={"Select"}
                isLoading={loading.timezone || false}
                getOptionLabel={(timezone) => `${timezone.name}`}
                getOptionValue={(timezone) => `${timezone.name}`}
                value={timezone.find(
                  (supplier) => supplier.name === settings?.timezone
                )}
              />
            </div>
          </div>
          <div className="col-3">
            <div className=" custom-card p-2 m-2">
              <div className="text-center mb-3">
                <img
                  style={{ width: "80px" }}
                  src={lang}
                  alt=""
                  className="mx-auto img-fluid"
                />
              </div>
              <h5 className="text-center mb-2">{lan?.language}</h5>
              <Select
                options={language}
                onChange={(language) =>
                  setSettings({ ...settings, language: language.name })
                }
                placeholder={"Select"}
                // isDisabled={true}
                getOptionLabel={(language) => `${language.name}`}
                getOptionValue={(language) => `${language.name}`}
                value={language.find(
                  (supplier) => supplier.name === settings?.language
                )}
              />
            </div>
          </div>
          <div className="col-3">
            <div className=" custom-card p-2 m-2">
              <div className="text-center mb-3">
                <img
                  style={{ width: "80px" }}
                  src={lang}
                  alt=""
                  className="mx-auto img-fluid"
                />
              </div>
              <h5 className="text-center mb-2">{lan?.dateFormat}</h5>
              <Select
                options={dateFormat}
                onChange={(date) =>
                  setSettings({ ...settings, date_format: date.name })
                }
                placeholder={"Select"}
                getOptionLabel={(dateFormat) => `${dateFormat.name}`}
                getOptionValue={(dateFormat) => `${dateFormat.name}`}
                value={dateFormat.find(
                  (date) => date.name === settings?.date_format
                )}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <button
              onClick={updateSetting}
              className="btn btn-primary btn-sm me-3"
            >
              {lan?.save}
            </button>
          </div>
        </div>
        <div className="mt-5 pt-5"></div>
      </div>
    </div>
  );
}
