import React, { useEffect, useState, useRef, useMemo } from "react";
import "../../../CSS/BuillingSetup.css";
import http from "../../../http";
import MaterialTable from "material-table";
import "../../../CSS/Table.css";
import Select from "react-select";
import Modal from "react-modal";
import allCustomStyles from "../../../CSS/ReactModalStyle";
import { IoIosArrowForward } from "react-icons/io";
import { useReactToPrint } from "react-to-print";
import PictureAsPdfSharpIcon from "@mui/icons-material/PictureAsPdfSharp";
import JoditEditor from "jodit-react";
import "./Billing.css";
import BillEntry from "./BillEntry";
import { FaRegEye } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { FaRegFilePdf } from "react-icons/fa";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import BillProcessModal from "./BillProcessModal";
import { FaPlus, FaDollarSign } from "react-icons/fa";
import PaymentModal from "./PaymentModal";
import { FaHistory } from "react-icons/fa";
import PaymentHistoryModal from "./PaymentHistoryModal";
import moment from "moment";
import Setting from "../../Setting/Setting";
import BillingStatement from "./BillingStatement";
import { BsPrinter } from "react-icons/bs";
import "../../../CSS/Table.css";
import { billingManagementLang } from "../../../language/billingManagement/billingManagementLang";
import useLanguage from "../../../language/useLanguage";
import { invoiceLang } from "../../../language/billingManagement/invoiceLang";
export default function BillingManagement({ placeholder }) {
  const lan = useLanguage(billingManagementLang);
  const invoiceLan = useLanguage(invoiceLang);
  const { colourStyles } = allCustomStyles;
  const { currencyFormat, dateFormat } = Setting;
  const [isModalOpen, setisModalOpen] = useState(false);

  // invoice code start
  const [bladeContent, setBladeContent] = useState();

  const componentRef = useRef();
  const handle = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @page {
      size: portrait;
    }
  `,
  });
  const componentRefMulti = useRef();
  const handleMulti = useReactToPrint({
    content: () => componentRefMulti.current,
    pageStyle: `
    @page {
      size: portrait;
    }
  `,
  });

  const handlePrint = (row) => {
    http.post("/invoice-pdf", {...row, lan:invoiceLan}).then((res) => {
      setBladeContent(res.data);
    });
    setTimeout(() => {
      handle();
    }, 1000);
  };
  const [selectedRow, setselectedRow] = useState([]);
  const [multiBladecontent, setmultiBladecontent] = useState();

  const handlePrintMultipale = () => {
    if (selectedRow?.length < 1) {
      return Swal.fire({
        position: "center",
        icon: "warning",
        title: lan?.pleaseSelectTheInvoice,
        showConfirmButton: true,
      });
    }

    setSpinner(true);
    const data = [];
    selectedRow
      ?.filter((v) => v?.invoice !== null)
      ?.map((row) => {
        http.post("/invoice-pdf", {...row, lan:invoiceLan}).then((res) => {
          data.push(res.data);
          console.log(res, "data");
          // setmultiBladecontent([...multiBladecontent, { data: res.data, invoice: row.invoice }]);
        });
      });

    const time =
      selectedRow?.filter((v) => v?.invoice !== null).length * 1000 + 1000;
    setTimeout(() => {
      setmultiBladecontent(data);
      setTimeout(() => {
        handleMulti();
        setSpinner(false);
      }, 2000);
    }, time);
  };

  // invoice code end
  const editor = useRef(null);
  const { BillingSmsModal } = allCustomStyles;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  const months = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sept" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];
  const years = [
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
  ];
  const [spinner, setSpinner] = useState(false);

  const getOrdinal = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = number % 10;
    const suffix =
      lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13
        ? suffixes[lastDigit]
        : suffixes[0];
    return number + suffix;
  };

  const [buildings, setbuildings] = useState([]);
  const [buildingInfo, setbuildingInfo] = useState([]);
  const [priviousMonth, setpriviousMonth] = useState();
  const [Floor, setFloor] = useState();
  const [Units, setUnits] = useState();

  const [monthAndYear, setmonthAndYear] = useState({
    month: dayjs().subtract(1, "month").format("MM"),
    year: dayjs().format("YYYY"),
  });


  useEffect(() => {
    let sub = false;
    if (!sub) {
      const dateV = `${dayjs(
        `${dayjs().format(
          `${`${monthAndYear?.year}-${monthAndYear?.month}-01`}`
        )}`
      ).format("MMMM DD, YYYY")} - ${dayjs(
        `${dayjs().format(
          `${`${monthAndYear?.year}-${monthAndYear?.month}-01`}`
        )}`
      )
        .add(1, "month")
        .subtract(1, "day")
        .format("MMMM DD, YYYY")}`;
      setpriviousMonth(dateV);
    }

    return () => {
      sub = true;
    };
  }, [monthAndYear]);

  const [projectWiseFloor, setprojectWiseFloor] = useState();

  useEffect(() => {
    let reRender = false;
    if (!reRender) {
      http
        .get("buidling-wise-floor-info")
        .then((res) => {

          setprojectWiseFloor(res.data.data);
        })
        .catch((err) => console.log(err));
    }
    return () => {
      reRender = true;
    };
  }, []);

  const dataFillterMonthandYearWise = (
    month = monthAndYear?.month,
    year = monthAndYear?.year
  ) => {
    setSpinner(true);
    http
      .get(`bill-entry/${month}/${year}`)
      .then((res) => {
        setdataForSearch(res.data.data);
        setData(res.data.data);
        setSpinner(false);
      })
      .catch((err) => setSpinner(false));
  };

  const [updateBillEntryData, setupdateBillEntryData] = useState();
  const [dataForSearch, setdataForSearch] = useState([]);

  const [searchInfo, setsearchInfo] = useState({});

  useEffect(() => {
    let reRender = false;
    setSpinner(true);
    if (!reRender) {
      dataFillterMonthandYearWise();
    }

    return () => {
      reRender = true;
    };
  }, [updateBillEntryData]);

  const [billEntryDataForEdit, setbillEntryDataForEdit] = useState();

  const [data, setData] = useState();
  const [permisionFroUpdate, setpermisionFroUpdate] = useState(false);

  const forUpdateData = (row, type = "update") => {
    setbillEntryDataForEdit(row);
    setisModalOpen(true);
    if (type === "update") {
      setpermisionFroUpdate(true);
    } else {
      setpermisionFroUpdate(false);
    }
  };
  const columns = [
    // {
    //     title: "SL",
    //     field: "sl",
    //     render: (row) => <>{row.tableData.id + 101}</>,

    //     cellStyle: {
    //         textAlign: "center",
    //     },
    // },
    {
      title: lan?.invoice,
      field: "invoice",
    },
    {
      title: lan?.date,
      field: ``,

      render: (row) => (
        <>{row?.created_at && moment(row?.created_at).format(dateFormat)}</>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: lan?.projectDetails,
      field: `project.property_name`,
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: lan?.building,
      field: `building.title`,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: lan?.floor,
      field: `floor`,

      render: (row) => <>{getOrdinal(row?.floor)}</>,
      cellStyle: {
        textAlign: "center",
        width: "10px !Important",
      },
    },
    {
      title: lan?.flat,
      field: `unit_name`,

      // render: (row) => <>{row?.unit_name}</>,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: lan?.owner,
      field: `owner.name`,

      // render: (row) => <>{row?.owner?.name}</>,
      cellStyle: {
        textAlign: "center",
        width: "500px",
      },
    },
    {
      title: lan?.tenant,
      field: `tenant.name`,

      // render: (row) => <>{row?.tenant?.name}</>,
      cellStyle: {
        textAlign: "center",
        width: "500px",
      },
    },

    {
      title: lan?.totalBill,
      field: "grand_total",

      render: (row) => (
        <>{row?.grand_total === null ? "0.00" : row?.grand_total}</>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: lan?.paymentReceived,
      field: "received_amount",

      render: (row) => (
        <>{row?.received_amount === null ? "0.00" : row?.received_amount}</>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: lan?.due,
      field: "due",

      render: (row) => <>{row?.due === null ? "0.00" : row?.due}</>,
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: lan?.billStatus,
      field: "status",
      render: (row) => (
        <div style={{ cursor: "pointer" }}>
          {row?.grand_total === null ? (
            <span
              onClick={() => {
                forUpdateData(row);
              }}
              className="badge"
              style={{ background: "#cb0000" }}
            >
              {lan?.incomplete}
            </span>
          ) : (
            <span
              onClick={() => {
                forUpdateData(row);
              }}
              className="badge bg-success"
            >
              {row?.status}
            </span>
          )}
        </div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: lan?.paymentStatus,
      field: "",
      render: (row) => (
        <div style={{ cursor: "pointer" }}>
          {Number(row?.received_amount) < 1 ? (
            <span
              onClick={() => {
                if (row?.status === "Locked") {
                  openPaymentModal(row);
                }
              }}
              className="badge"
              style={{ background: "#cb0000" }}
            >
              {lan?.pending}
            </span>
          ) : Number(row?.due) <= 0 ? (
            <span
              onClick={() => {
                openPaymentModal(row);
              }}
              className="badge bg-success"
            >
              {lan?.complete}
            </span>
          ) : (
            <span
              onClick={() => {
                openPaymentModal(row);
              }}
              className="badge"
              style={{ background: "blue" }}
            >
              {lan?.partialPayment}
            </span>
          )}
        </div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "",
      field: "",
      cellStyle: {
        textAlign: "center",
      },
      render: (row) => (
        <IoIosArrowForward
          onClick={() => openModal(row)}
          className="cursor-pointer"
        />
      ),
    },
    {
      title: lan?.action,
      field: "",
      cellStyle: {
        textAlign: "center",
      },
      render: (row) =>
        row?.grand_total !== null ? (
          <div className="d-flex">
            {row?.status !== "Locked" && (
              <button
                class="btn btn-outline-dark btn-sm me-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit data"
                onClick={() => {
                  forUpdateData(row);
                }}
              >
                <CiEdit />
              </button>
            )}
            {row?.status === "Locked" && (
              <>
                <button
                  class="btn btn-outline-dark btn-sm me-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Payment"
                  onClick={() => {
                    openPaymentModal(row);
                  }}
                >
                  <FaDollarSign />
                </button>

                <button
                  class="btn btn-outline-dark btn-sm me-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Payment"
                  onClick={() => {
                    setpaymentHistoryModal(true);
                    setbillEntryDataForEdit(row);
                  }}
                >
                  <FaHistory />
                </button>
              </>
            )}

            <button
              class="btn btn-outline-dark btn-sm me-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="View data"
              onClick={() => {
                forUpdateData(row, "view");
              }}
            >
              <FaRegEye />
            </button>
            <button
              class="btn btn-outline-dark btn-sm me-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Invoice"
              onClick={() => handlePrint(row)}
            >
              <FaRegFilePdf />
            </button>
            {/* <button type="button" class="btn btn-outline-dark btn-sm">
                        <FaRegFileExcel />
                    </button> */}
          </div>
        ) : (
          <button
            class="btn btn-outline-dark btn-sm me-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Add data"
            onClick={() => {
              forUpdateData(row);
            }}
          >
            <FaPlus className="icon_for_bill_managment" />
          </button>
        ),
    },
  ];

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: placeholder || "Start typing...",
    }),
    [placeholder]
  );
  const openModal = (row) => {
    setMessageData((prevData) => ({
      ...prevData,
      user_id: row?.owner?.id,
      user_name: row?.owner?.name,
      phone: row?.owner?.mobile_1,
      message: `Dear ${row?.owner?.name}, Your due bill amount is ${row?.grand_total}. Please, pay the bill.`,
      amount: row?.grand_total,
      billing_month: row?.billing_month,
      email: row?.owner?.email,
    }));
    setIsOpen(true);
  };

  const [messageData, setMessageData] = useState({
    user_id: "",
    user_name: "",
    phone: "",
    message: "",
    amount: "",
    email: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    http.post("/send-sms-tenant", messageData).then((res) => {
      try {
        if (res.data.status === 200) {
          closeModal();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "SMS Send Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.log(error);
      }
    });
  };
  const handleSubmitEmail = (event) => {
    event.preventDefault();
    http.post("/send-email-tenant", messageData).then((res) => {
      try {
        if (res.data.status === 200) {
          closeModal();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Email Send Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.log(error);
      }
    });
  };
  const [isbillProcessModalOpen, setisbillProcessModalOpen] = useState(false);

  const [ispaymentModal, setispaymentModal] = useState(false);

  const openPaymentModal = (row) => {
    setbillEntryDataForEdit(row);
    setispaymentModal(true);
  };

  const [paymentHistoryModal, setpaymentHistoryModal] = useState(false);

  const grandTotal = data?.reduce(
    (acc, detail) => acc + Number(detail?.grand_total),
    0
  );
  const totalRecive = data?.reduce(
    (acc, detail) => acc + Number(detail?.received_amount),
    0
  );
  const totalDue = data?.reduce((acc, detail) => acc + Number(detail?.due), 0);

  const statement = useRef();
  const billingSM = useReactToPrint({
    content: () => statement.current,
    pageStyle: `
    @page {
      size: landscape;
    }
  `,
  });

  const [selected, setselected] = useState({
    project: null,
    building: null,
    floor: null,
    unit: null
  })

  const clearField = () => {
    setselected({
      project: null,
      building: null,
      floor: null,
      unit: null
    })
  }

  return (
    <>
      <div className="page-content adjustment-type-table">
        <div className="custom-card p-1 d-flex justify-content-between mb-2 align-items-center">
          <h6>{lan?.billingManagement}</h6>
          <div>
            <button
              style={{ marginTop: "1px" }}
              onClick={() => {
                handlePrintMultipale();
              }}
              className="btn btn-sm btn-primary float-end ms-1"
            >
              <BsPrinter /> {lan?.invoice}
            </button>
            <button
              style={{ marginTop: "1px" }}
              onClick={() => {
                // setispaymentModal(true)
                billingSM();
              }}
              className="btn btn-sm btn-primary float-end ms-1"
            >
              <BsPrinter /> {lan?.print}
            </button>
            <button
              style={{ marginTop: "1px" }}
              onClick={() => {
                setispaymentModal(true);
              }}
              className="btn btn-sm btn-primary float-end ms-1"
            >
              {lan?.payment}
            </button>
            <button
              style={{ marginTop: "1px" }}
              onClick={() => {
                setisbillProcessModalOpen(true);
              }}
              className="btn btn-sm btn-primary float-end ms-1"
            >
              {lan?.billProcess}
            </button>
            <button
              style={{ marginTop: "1px" }}
              onClick={() => {
                setpermisionFroUpdate(true);
                setisModalOpen(true);
                // if (data[0]?.status === 'Locked') {
                //     setpermisionFroUpdate(false)
                // }
              }}
              className="btn btn-sm btn-primary float-end"
            >
              {lan?.billEntry}
            </button>
          </div>
        </div>

        <div className="custom-card p-1 d-flex justify-content-center mb-2">
          <div
            style={{ fontWeight: "500", fontSize: "13px", border: "none", padding: "8px 10px", borderRadius: "4px", background: "#035E7B", color: "white" }}
          >
            <p>{priviousMonth}</p>
          </div>
        </div>



        {paymentHistoryModal && (
          <PaymentHistoryModal
            paymentHistoryModal={paymentHistoryModal}
            setpaymentHistoryModal={setpaymentHistoryModal}
            bill_entries_id={billEntryDataForEdit?.id}
          />
        )}

        {isbillProcessModalOpen && (
          <BillProcessModal
            setupdateBillEntryData={setupdateBillEntryData}
            columns={columns}
            data={dataForSearch}
            spinner={spinner}
            priviousMonth={priviousMonth}
            isbillProcessModalOpen={isbillProcessModalOpen}
            setisbillProcessModalOpen={setisbillProcessModalOpen}
            setpermisionFroUpdate={setpermisionFroUpdate}
          />
        )}
        {isModalOpen && (
          <BillEntry
            projectWiseFloor={projectWiseFloor}
            buildingInfo={buildingInfo}
            isModalOpen={isModalOpen}
            setisModalOpen={setisModalOpen}
            setupdateBillEntryData={setupdateBillEntryData}
            billEntryDataForEdit={billEntryDataForEdit}
            setbillEntryDataForEdit={setbillEntryDataForEdit}
            permisionFroUpdate={permisionFroUpdate}
            setpermisionFroUpdate={setpermisionFroUpdate}
            data={data}
            monthAndYear={monthAndYear}
          />
        )}
        {ispaymentModal && (
          <PaymentModal
            projectWiseFloor={projectWiseFloor}
            buildingInfo={buildingInfo}
            ispaymentModal={ispaymentModal}
            setispaymentModal={setispaymentModal}
            setupdateBillEntryData={setupdateBillEntryData}
            billEntryDataForEdit={billEntryDataForEdit}
            setbillEntryDataForEdit={setbillEntryDataForEdit}
            permisionFroUpdate={permisionFroUpdate}
            setpermisionFroUpdate={setpermisionFroUpdate}
            setpaymentHistoryModal={setpaymentHistoryModal}
            paymentHistoryModal={paymentHistoryModal}
            monthAndYear={monthAndYear}
          />
        )}
        <div className="card">
          <div className="card-body">
            <div className="billing_table">
              <div className="col-lg-12">
                <div className="d-flex flex-column flex-md-row justify-content-lg-between justify-content-md-center align-items-center">

                  <div className="col-lg-8 d-flex">
                    <div className="col-lg-3">
                      <Select
                        styles={colourStyles}
                        options={projectWiseFloor}
                        value={selected?.project}
                        getOptionLabel={(option) => option?.property_name}
                        onChange={(v) => {
                          setselected({ ...selected, project: v })
                          setbuildings(v?.building);
                          setsearchInfo({
                            ...searchInfo,
                            project_details_id: v?.id,
                          });
                          const filter = dataForSearch.filter(
                            (i) => Number(i?.project.id) === Number(v?.id)
                          );
                          setData(filter);
                        }}
                        className="me-1"
                        placeholder={lan?.project}
                      />
                    </div>
                    <div className="col-lg-3">
                      <Select
                        styles={colourStyles}
                        options={buildings}
                        value={selected?.building}
                        getOptionLabel={(option) => option?.title}
                        onChange={(v) => {
                          setselected({ ...selected, building: v })
                          setsearchInfo({
                            ...searchInfo,
                            building_id: v?.building_id,
                          });

                          const groupedData = {};
                          v?.units?.forEach((unit) => {
                            const floor = unit.floor;

                            if (!groupedData[floor]) {
                              groupedData[floor] = [];
                            }
                            groupedData[floor].push(unit);
                          });

                          const result = Object.keys(groupedData).map(
                            (age) => ({
                              floor: age,
                              unit: groupedData[age],
                            })
                          );
                          setFloor(result);

                          const filter = dataForSearch.filter(
                            (i) =>
                              Number(i?.building.building_id) ===
                              Number(v?.building_id) &&
                              Number(i?.project.id) ===
                              Number(searchInfo?.project_details_id)
                          );
                          setData(filter);
                        }}
                        className="me-1"
                        placeholder={lan?.building}
                      />
                    </div>
                    <div className="col-lg-3">
                      <Select
                        styles={colourStyles}
                        options={Floor}
                        value={selected?.floor}
                        getOptionLabel={(option) =>
                          `${getOrdinal(option?.floor)} Floor`
                        }
                        onChange={(v) => {
                          setselected({ ...selected, floor: v })
                          setsearchInfo({ ...searchInfo, floor: v?.floor });
                          setUnits(v?.unit);
                          const filterByBuilding = dataForSearch.filter(
                            (i) =>
                              Number(i?.project.id) ===
                              Number(searchInfo?.project_details_id) &&
                              i?.building.building_id ===
                              searchInfo?.building_id
                          );
                          const filterByFloor = filterByBuilding.filter(
                            (i) => i?.floor === v?.floor
                          );

                          setData(filterByFloor);
                        }}
                        className="me-1"
                        placeholder={lan?.floor}
                      />
                    </div>
                    <div className="col-lg-3">
                      <Select
                        styles={colourStyles}
                        options={Units}
                        getOptionLabel={(option) => option.unit_name}
                        className="me-1"
                        placeholder={lan?.unit}
                        value={selected?.unit}

                        onChange={(v) => {
                          setselected({ ...selected, unit: v })
                          const filterByBuilding = dataForSearch.filter(
                            (i) =>
                              Number(i?.project.id) ===
                              Number(searchInfo?.project_details_id) &&
                              i?.building.building_id ===
                              searchInfo?.building_id
                          );
                          const filterByFloor = filterByBuilding.filter(
                            (i) => i?.floor === searchInfo?.floor
                          );
                          const filterByUnit = filterByFloor.filter(
                            (i) => i?.unit_name === v?.unit_name
                          );

                          setData(filterByUnit);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 d-flex justify-content-center align-items-center">
                    <div className="col-lg-6">
                      <Select
                        styles={colourStyles}
                        options={months}
                        value={months.filter(
                          (v) => v.value === monthAndYear?.month
                        )}
                        className="me-1"
                        onChange={(v) => {

                          dataFillterMonthandYearWise(
                            v?.value,
                            monthAndYear?.year
                          );
                          setmonthAndYear({ ...monthAndYear, month: v?.value });

                          clearField()
                        }}
                        placeholder={lan?.month}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Select
                        styles={colourStyles}
                        value={years.filter(
                          (v) => v.value === monthAndYear?.year
                        )}
                        onChange={(v) => {
                          dataFillterMonthandYearWise(
                            monthAndYear?.month,
                            v?.value
                          );
                          setmonthAndYear({ ...monthAndYear, year: v?.value });
                          clearField()
                        }}
                        options={years}
                        className="me-1"
                        placeholder="Year"
                      />
                    </div>
                  </div>

                  {/* <div className="col-lg-3 text-center"
                    style={{ fontWeight: "500", fontSize: "13px", border: "none", padding: "8px 0px", borderRadius: "4px", background: "#035E7B", color: "white" }}
                  >
                    <p>{priviousMonth}</p>
                  </div> */}
                </div>
              </div>
              {/* <div className="bill_entry_table"> */}
              {/* <div className="background"></div> */}
              <MaterialTable
                columns={columns}
                data={data}
                isLoading={spinner}
                options={{
                  search: false,
                  selection: true,
                  showTitle: false,
                  searchFieldAlignment: "left",
                  pageSize: 10,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  rowStyle: {
                    fontSize: 14,
                    fontWeight: 400,
                  },
                  // fixedColumns: {
                  //     right: 1
                  // },
                  // maxBodyHeight: '650px',
                }}
                onSelectionChange={(rows) => {
                  setselectedRow(rows);
                }}
                actions={[
                  {
                    tooltip: "Print Selected PDF",
                    icon: PictureAsPdfSharpIcon,
                    onClick: handlePrintMultipale,
                  },
                ]}
              />
              {/* </div> */}

              <div class="d-flex mt-2">
                <h6 class="mb-2 me-3">
                  {lan?.grandTotal} : {currencyFormat(grandTotal)}
                </h6>
                <h6 class="mb-2 me-3">
                  {lan?.totalReceived} : {currencyFormat(totalRecive)}
                </h6>
                <h6 class="me-3"> {lan?.totalDue} : {currencyFormat(totalDue)}</h6>
              </div>

              <div className="billing-statement-print" ref={statement}>
                <BillingStatement
                  data={data}
                  grandTotal={grandTotal}
                  totalRecive={totalRecive}
                  totalDue={totalDue}
                  monthAndYear={monthAndYear}
                />
              </div>
            </div>
          </div>
        </div>
      </div >
      <div
        ref={componentRef}
        className="content-to-print displayNone"
        dangerouslySetInnerHTML={{ __html: bladeContent }}
      />

      <div ref={componentRefMulti} className="multiple-content displayNone">
        {multiBladecontent?.map((item, i) => {
          return (
            <div
              key={i}
              className="content-to-print "
              dangerouslySetInnerHTML={{ __html: item }}
            />
          );
        })}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={BillingSmsModal}
        contentLabel="Example Modal"
      >
        <div className="product_modal">
          <div className="page-content">
            <div className=" patients-head ">
              <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">
                <span
                  style={{ cursor: "pointer", fontSize: "16px" }}
                  onClick={closeModal}
                  className="float-end"
                >
                  <i className="fal fa-times"></i>
                </span>
              </h5>
            </div>

            <div className="p-3 pt-0">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Email
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Sms
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <form onSubmit={handleSubmitEmail}>
                    <JoditEditor
                      ref={editor}
                      value={messageData?.message ? messageData?.message : ""}
                      config={config}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={(event) =>
                        setMessageData({ ...messageData, message: event })
                      }
                    />
                    <button
                      className="btn mt-2 btn-sm btn-primary float-end text-uppercase"
                      type="submit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-save mb-1"
                      >
                        <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                        <polyline points="17 21 17 13 7 13 7 21"></polyline>
                        <polyline points="7 3 7 8 15 8"></polyline>
                      </svg>
                      Send
                    </button>
                  </form>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabindex="0"
                >
                  <form onSubmit={handleSubmit}>
                    <JoditEditor
                      ref={editor}
                      value={messageData?.message ? messageData?.message : ""}
                      config={config}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={(event) =>
                        setMessageData({ ...messageData, message: event })
                      }
                    />
                    <button
                      className="btn mt-2 btn-sm btn-primary float-end text-uppercase"
                      type="submit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-save mb-1"
                      >
                        <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                        <polyline points="17 21 17 13 7 13 7 21"></polyline>
                        <polyline points="7 3 7 8 15 8"></polyline>
                      </svg>
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* <Invoice /> */}
    </>
  );
}
