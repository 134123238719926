const english = {
    movements: "Movements",
    sl : "Sl",
    add : "Add",
    visitor : "Visitor",
    owner: "Owner",
    mobile : "Mobile",
    email: "Email",
    dateAndTime : "Date & Time",
    status : "Status",
    image : "Image",
    pending : "Pending",
    accepted: "Accepted",
    rejected : "Rejected",
    exit: "Exit",
    entered: "Entered",
    visitorCardNumber : "Visitor Card Number",
    remarks: "Remarks",
    searchOwner: "Search Owner",
    searchVisitor: "Search Visitor",
    picture: "Picture",
    save : "Save",
    cancel : "Cancel",
    movementInformation: "Movement Information",
    saveMessage: "Data has been saved successfully",
    updateMessage: "Data has been updated successfully",
    deleteMessage: "Data has been deleted successfully",
    areYouSure: "Are you sure?",
    deleteWarning: "You won't be able to revert this!",
    yesDelete: "Yes, delete it!",
 }
const malay = {
    movements: "Pergerakan",
    sl: "SL",
    add: "Tambah",
    visitor: "Pelawat",
    owner: "Pemilik",
    mobile: "Mudah Alih",
    email: "E-mel",
    dateAndTime: "Tarikh & Masa",
    status: "Status",
    image: "Gambar",
    pending: "Tertunda",
    accepted: "Diterima",
    rejected: "Ditolak",
    exit: "Keluar",
    entered: "Masuk",
    visitorCardNumber: "Nombor Kad Pelawat",
    remarks: "Ulasan",
    searchOwner: "Cari Pemilik",
    searchVisitor: "Cari Pelawat",
    picture: "Gambar",
    save: "Simpan",
    cancel: "Batal",
    movementInformation: "Maklumat Pergerakan",
    saveMessage: "Data telah disimpan dengan jayanya",
    updateMessage: "Data telah dikemas kini dengan jayanya",
    deleteMessage: "Data telah dipadamkan dengan jayanya",
    areYouSure: "Adakah anda pasti?",
    deleteWarning: "Anda tidak akan dapat mengembalikannya!",
    yesDelete: "Ya, padamkan!"
  }
  const thai = {
    movements: "การเคลื่อนไหว",
    sl: "ลำดับ",
    add: "เพิ่ม",
    visitor: "ผู้เยี่ยมชม",
    owner: "เจ้าของ",
    mobile: "มือถือ",
    email: "อีเมล",
    dateAndTime: "วันที่ & เวลา",
    status: "สถานะ",
    image: "ภาพ",
    pending: "รอดำเนินการ",
    accepted: "ได้รับการยอมรับ",
    rejected: "ถูกปฏิเสธ",
    exit: "ออก",
    entered: "เข้า",
    visitorCardNumber: "หมายเลขบัตรผู้เยี่ยมชม",
    remarks: "หมายเหตุ",
    searchOwner: "ค้นหาเจ้าของ",
    searchVisitor: "ค้นหาผู้เยี่ยมชม",
    picture: "ภาพ",
    save: "บันทึก",
    cancel: "ยกเลิก",
    movementInformation: "ข้อมูลการเคลื่อนไหว",
    saveMessage: "ข้อมูลได้ถูกบันทึกเรียบร้อยแล้ว",
    updateMessage: "ข้อมูลได้ถูกอัปเดตเรียบร้อยแล้ว",
    deleteMessage: "ข้อมูลได้ถูกลบเรียบร้อยแล้ว",
    areYouSure: "คุณแน่ใจหรือไม่?",
    deleteWarning: "คุณจะไม่สามารถกู้คืนได้!",
    yesDelete: "ใช่, ลบเลย!"
  }
  const arabic = {
    movements: "الحركات",
    sl: "مسلسل",
    add: "إضافة",
    visitor: "الزائر",
    owner: "المالك",
    mobile: "جوال",
    email: "البريد الإلكتروني",
    dateAndTime: "التاريخ والوقت",
    status: "الحالة",
    image: "صورة",
    pending: "قيد الانتظار",
    accepted: "مقبول",
    rejected: "مرفوض",
    exit: "خروج",
    entered: "دخل",
    visitorCardNumber: "رقم بطاقة الزائر",
    remarks: "ملاحظات",
    searchOwner: "البحث عن مالك",
    searchVisitor: "البحث عن زائر",
    picture: "صورة",
    save: "حفظ",
    cancel: "إلغاء",
    movementInformation: "معلومات الحركة",
    saveMessage: "تم حفظ البيانات بنجاح",
    updateMessage: "تم تحديث البيانات بنجاح",
    deleteMessage: "تم حذف البيانات بنجاح",
    areYouSure: "هل أنت متأكد؟",
    deleteWarning: "لن تتمكن من استعادته!",
    yesDelete: "نعم، احذفه!"
  }
  const bengali = {
    movements: "চলাচল",
    sl: "ক্রম",
    add: "যোগ করুন",
    visitor: "পরিদর্শক",
    owner: "মালিক",
    mobile: "মোবাইল",
    email: "ইমেল",
    dateAndTime: "তারিখ ও সময়",
    status: "অবস্থা",
    image: "ছবি",
    pending: "মুলতুবি",
    accepted: "গৃহীত",
    rejected: "প্রত্যাখ্যাত",
    exit: "বাহির",
    entered: "প্রবেশ করেছে",
    visitorCardNumber: "পরিদর্শক কার্ড নম্বর",
    remarks: "মন্তব্য",
    searchOwner: "মালিক খুঁজুন",
    searchVisitor: "পরিদর্শক খুঁজুন",
    picture: "ছবি",
    save: "সংরক্ষণ",
    cancel: "বাতিল",
    movementInformation: "চলাচলের তথ্য",
    saveMessage: "তথ্য সফলভাবে সংরক্ষিত হয়েছে",
    updateMessage: "তথ্য সফলভাবে আপডেট হয়েছে",
    deleteMessage: "তথ্য সফলভাবে মুছে ফেলা হয়েছে",
    areYouSure: "আপনি কি নিশ্চিত?",
    deleteWarning: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yesDelete: "হ্যাঁ, মুছে ফেলুন!"
  }
        
export const movementManagementLang = { 
    english, 
    malay,
    thai, 
    arabic,
    bengali
}