const english = {
  settings: "Settings",
  language: "Language",
  timezone: "Time Zone",
  country: "Country",
  currency: "Currency",
  employees: "Employees",
  dateFormat: "Date Format",
  save: "Save",
  success: "Success",
  successText: "Settings updated successfully",
};
const thai = {
  settings: "การตั้งค่า",
  language: "ภาษา",
  timezone: "เขตเวลา",
  country: "ประเทศ",
  currency: "สกุลเงิน",
  employees: "พนักงาน",
  dateFormat: "รูปแบบวันที่",
  save: "บันทึก",
  success: "สำเร็จ",
  successText: "อัปเดตการตั้งค่าสำเร็จ",
};
const malay = {
  settings: "Tetapan",
  language: "Bahasa",
  timezone: "Zon Masa",
  country: "Negara",
  currency: "Mata Wang",
  employees: "Pekerja",
  dateFormat: "Format Tarikh",
  save: "Simpan",
  success: "Berjaya",
  successText: "Tetapan berjaya dikemaskini",
};
const arabic = {
  settings: "الإعدادات",
  language: "اللغة",
  timezone: "المنطقة الزمنية",
  country: "البلد",
  currency: "العملة",
  employees: "الموظفون",
  dateFormat: "تنسيق التاريخ",
  save: "حفظ",
  success: "نجاح",
  successText: "تم تحديث الإعدادات بنجاح",
};
const bengali = {
  settings: "সেটিংস",
  language: "ভাষা",
  timezone: "সময় অঞ্চল",
  country: "দেশ",
  currency: "মুদ্রা",
  employees: "কর্মচারী",
  dateFormat: "তারিখ বিন্যাস",
  save: "সংরক্ষণ",
  success: "সফলতা",
  successText: "সেটিংস সফলভাবে আপডেট হয়েছে",
};

export const settingLang = {
  english,
  malay,
  thai,
  arabic,
  bengali,
};
