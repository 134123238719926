const english = {
    feeSetup: "Fee Setup",
    add: "Add",
    sl: "SL",
    group: "Group",
    type: "Type",
    fee: "Fee",
    effectiveDate: "Effective Date",
    endDate: "End Date",
    status: "Status",
    user: "User",
    active: "Active",
    inactive: "Inactive",
    remark: "Remark",
    action: "Action",
    save: "Save",
    update: "Update",
    saveMessage: "Data has been saved successfully",
    updateMessage: "Data has been updated successfully",
    deleteMessage: "Data has been deleted successfully",
    yes: "Yes",
    no: "No",
    cancel : "Cancel",
    name: "Name",
    selectServiceGroup: "Select Service Group",
    serviceType: "Service Type",
    serviceUnit: "Service Unit",
    serviceFee: "Service Fee",
    validTill: "Valid Till",
    back: "Back",
    perUnit: "Per Unit",
    unitMeasurement: "Unit Measurement",
    remarks: "Remarks",
    selectUnitType: "Select Unit Type",
    areYouSure: "Are you sure?",
  deleteWarning: "You won't be able to revert this!",
  yesDelete: "Yes, delete it!",
  code : "Code",
}

const malay = {
    feeSetup: "Tetapan Yuran",
    add: "Tambah",
    sl: "SL",
    group: "Kumpulan",
    type: "Jenis",
    fee: "Yuran",
    effectiveDate: "Tarikh Berkuat Kuasa",
    endDate: "Tarikh Tamat",
    status: "Status",
    user: "Pengguna",
    active: "Aktif",
    inactive: "Tidak Aktif",
    remark: "Catatan",
    action: "Tindakan",
    save: "Simpan",
    update: "Kemas Kini",
    saveMessage: "Data telah berjaya disimpan",
    updateMessage: "Data telah berjaya dikemas kini",
    deleteMessage: "Data telah berjaya dipadamkan",
    yes: "Ya",
    no: "Tidak",
    cancel : "Batal",
    name: "Nama",
    selectServiceGroup: "Pilih Kumpulan Perkhidmatan",
    serviceType: "Jenis Perkhidmatan",
    serviceUnit: "Unit Perkhidmatan",
    serviceFee: "Yuran Perkhidmatan",
    validTill: "Sah Hingga",
    back: "Kembali",
    perUnit: "Per Unit",
    unitMeasurement: "Ukuran Unit",
    remarks: "Catatan",
    selectUnitType: "Pilih Jenis Unit",
    areYouSure: "Adakah anda pasti?",
deleteWarning: "Anda tidak akan dapat membatalkan ini!",
yesDelete: "Ya, hapuskan!",
deleted: "Dihapus",
code: "Kod",
  
}

const thai = {
    feeSetup: "ตั้งค่าค่าธรรมเนียม",
    add: "เพิ่ม",
    sl: "ลำดับ",
    group: "กลุ่ม",
    type: "ประเภท",
    fee: "ค่าธรรมเนียม",
    effectiveDate: "วันที่มีผล",
    endDate: "วันที่สิ้นสุด",
    status: "สถานะ",
    user: "ผู้ใช้",
    active: "ใช้งาน",
    inactive: "ไม่ใช้งาน",
    remark: "หมายเหตุ",
    action: "การกระทำ",
    save: "บันทึก",
    update: "อัปเดต",
    saveMessage: "บันทึกข้อมูลเรียบร้อยแล้ว",
    updateMessage: "อัปเดตข้อมูลเรียบร้อยแล้ว",
    deleteMessage: "ลบข้อมูลเรียบร้อยแล้ว",
    yes: "ใช่",
    no: "ไม่",
    cancel : "ยกเลิก",
    name: "ชื่อ",
    selectServiceGroup: "เลือกกลุ่มบริการ",
    serviceType: "ประเภทบริการ",
    serviceUnit: "หน่วยบริการ",
    serviceFee: "ค่าบริการ",
    validTill: "ใช้ได้จนถึง",
    back: "ย้อนกลับ",
    perUnit: "ต่อหน่วย",
    unitMeasurement: "หน่วยวัด",
    remarks: "หมายเหตุ",
    selectUnitType: "เลือกประเภทหน่วย",
    areYouSure: "คุณแน่ใจหรือไม่?",
deleteWarning: "คุณจะไม่สามารถย้อนกลับได้!",
yesDelete: "ใช่, ลบเลย!",
deleted: "ลบแล้ว",
code: "รหัส",
}

const arabic = {
    feeSetup: "إعداد الرسوم",
    add: "إضافة",
    sl: "التسلسل",
    group: "مجموعة",
    type: "نوع",
    fee: "رسوم",
    effectiveDate: "تاريخ السريان",
    endDate: "تاريخ الانتهاء",
    status: "الحالة",
    user: "المستخدم",
    active: "نشط",
    inactive: "غير نشط",
    remark: "ملاحظة",
    action: "الإجراء",
    save: "حفظ",
    update: "تحديث",
    saveMessage: "تم حفظ البيانات بنجاح",
    updateMessage: "تم تحديث البيانات بنجاح",
    deleteMessage: "تم حذف البيانات بنجاح",
    yes: "نعم",
    no: "لا",
    cancel : "إلغاء",
    name: "الاسم",
    selectServiceGroup: "اختر مجموعة الخدمة",
    serviceType: "نوع الخدمة",
    serviceUnit: "وحدة الخدمة",
    serviceFee: "رسوم الخدمة",
    validTill: "صالح حتى",
    back: "رجوع",
    perUnit: "لكل وحدة",
    unitMeasurement: "قياس الوحدة",
    remarks: "ملاحظات",
    selectUnitType: "اختر نوع الوحدة",
  areYouSure: "هل أنت متأكد؟",
  deleteWarning: "لن تتمكن من إعادة هذا!",
  yesDelete: "نعم، احذفه!",
  deleted: "محذوف",
  code: "رمز",
  
}

const bengali = {
    feeSetup: "ফি সেটআপ",
    add: "যোগ করুন",
    sl: "ক্রমিক",
    group: "গ্রুপ",
    type: "ধরন",
    fee: "ফি",
    effectiveDate: "কার্যকরী তারিখ",
    endDate: "শেষ তারিখ",
    status: "স্থিতি",
    user: "ব্যবহারকারী",
    active: "সক্রিয়",
    inactive: "নিষ্ক্রিয়",
    remark: "মন্তব্য",
    action: "কর্ম",
    save: "সংরক্ষণ",
    update: "আপডেট",
    saveMessage: "ডেটা সফলভাবে সংরক্ষণ করা হয়েছে",
    updateMessage: "ডেটা সফলভাবে আপডেট করা হয়েছে",
    deleteMessage: "ডেটা সফলভাবে মুছে ফেলা হয়েছে",
    yes: "হ্যাঁ",
    no: "না",
    cancel : "বাতিল",
    name: "নাম",
    selectServiceGroup: "সেবা গ্রুপ নির্বাচন করুন",
    serviceType: "সেবা ধরন",
    serviceUnit: "সেবা ইউনিট",
    serviceFee: "সেবা ফি",
    validTill: "বৈধ থাকবে",
    back: "ফিরে যান",
    perUnit: "প্রতি ইউনিট",
    unitMeasurement: "ইউনিট মাপ",
    remarks: "মন্তব্য",
    selectUnitType: "ইউনিটের ধরন নির্বাচন করুন",
    areYouSure: "আপনি কি নিশ্চিত?",
deleteWarning: "আপনি এটি ফিরে পেতে পারবেন না!",
yesDelete: "হ্যাঁ, মুছে ফেলুন!",
deleted: "মুছে ফেলা হয়েছে",
code: "কোড"
}

export const feeSetupLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}