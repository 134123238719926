const english = {
    financeManagement: "Finance Management",
    deposit: "Deposit",
    payment: "Payment",
    paymentHistory: "Payment History",
    depositHistory: "Deposit History",
    date: "Date",
    transactionId: "Transaction Id",
    name: "Name",
    paymentMethod: "Payment Method",
    amount: "Amount",
    invoice: "Invoice",
    slipNo: "Slip No",
    users: "Users",
    email: "Email",
    walletNo: "Wallet No",
    mobile: "Mobile",
    address: "Address",
    availableBalance: "Available Balance",
    depositRequest: "Deposit Request",
    requestText: "A request has been made for you to submit deposit",
    requiredDepositAmount: "Required Deposit Amount",
    remark: "Remark",
    depositFor: "Deposit For",
    depositNow: "Deposit Now",
    depositReceipt: "DEPOSIT RECEIPT",
    due: "Due",
    payNow: "Pay Now",
    remarkText: "If you have any question or need help, please contact us.",
    cash: "Cash",
    creditDebit: "Credit/Debit Card",
    digitalPayment: "Digital Payment",
    eWallet: "E-Wallet",
    paymentAddedSuccessFully: "Payment Added SuccessFully",
    paymentReceipt: "PAYMENT RECEIPT",
    original: "ORIGINAL",
    customerCopy: "CUSTOMER COPY",
    dueDate: "Due Date",
    billingDate: "Billing Date",
    dueAmount: "Due Amount",
    paymentDate: "Payment Date",
    time: "Time",
    bankCard: "Bank/Card",
    number: "Number",
    expiryDate: "Expiry Date",
    totalAmountReceived: "Total Amount Received",
    authorizedSignature: "Authorized Signature",
    totalAmount: "Total Amount",
    mrn: "MRN",
    addDeposit: "Add Deposit",
    printDepositHistory: "Print deposit history",
    searchUser: "Search User",
    to: "To",
    type: "Type",
    totalDeposit: "Total Deposit",
    total: "Total",
    paymentStatement: "Payment Statement",
    depositStatement: "Deposit Statement",
}
const malay = {
    financeManagement: "Pengurusan Kewangan",
    deposit: "Deposit",
    payment: "Pembayaran",
    paymentHistory: "Sejarah Pembayaran",
    depositHistory: "Sejarah Deposit",
    date: "Tarikh",
    transactionId: "ID Transaksi",
    name: "Nama",
    paymentMethod: "Kaedah Pembayaran",
    amount: "Jumlah",
    invoice: "Invois",
    slipNo: "No Slip",
    users: "Pengguna",
    email: "E-mel",
    walletNo: "No Dompet",
    mobile: "Mudah Alih",
    address: "Alamat",
    availableBalance: "Baki Tersedia",
    depositRequest: "Permintaan Deposit",
    requestText: "Permintaan telah dibuat untuk anda menyerahkan deposit",
    requiredDepositAmount: "Jumlah Deposit Diperlukan",
    remark: "Catatan",
    depositFor: "Deposit Untuk",
    depositNow: "Deposit Sekarang",
    depositReceipt: "RESIT DEPOSIT",
    due: "Baki",
    payNow: "Bayar Sekarang",
    remarkText: "Jika anda mempunyai sebarang soalan atau memerlukan bantuan, sila hubungi kami.",
    cash: "Tunai",
    creditDebit: "Kad Kredit/Debit",
    digitalPayment: "Pembayaran Digital",
    eWallet: "E-Dompet",
    paymentAddedSuccessFully: "Pembayaran Berjaya Ditambah",
    paymentReceipt: "RESIT PEMBAYARAN",
    original: "ASAL",
    customerCopy: "SALINAN PELANGGAN",
    dueDate: "Tarikh Baki",
    billingDate: "Tarikh Penagihan",
    dueAmount: "Jumlah Baki",
    paymentDate: "Tarikh Pembayaran",
    time: "Masa",
    bankCard: "Bank/Kad",
    number: "Nombor",
    expiryDate: "Tarikh Tamat",
    totalAmountReceived: "Jumlah Keseluruhan Diterima",
    authorizedSignature: "Tandatangan Dibenarkan",
    totalAmount: "Jumlah Keseluruhan",
    mrn: "MRN",
    addDeposit: "Tambah Deposit",
    printDepositHistory: "Cetak sejarah deposit",
    searchUser: "Cari Pengguna",
    to: "Kepada",
    type: "Jenis",
    totalDeposit: "Jumlah Deposit",
    total: "Jumlah",
    paymentStatement: "Sejarah Pembayaran",
    depositStatement: "Sejarah Deposit",
  }
  const thai = {
    financeManagement: "การจัดการการเงิน",
    deposit: "เงินฝาก",
    payment: "การชำระเงิน",
    paymentHistory: "ประวัติการชำระเงิน",
    depositHistory: "ประวัติการฝาก",
    date: "วันที่",
    transactionId: "รหัสธุรกรรม",
    name: "ชื่อ",
    paymentMethod: "วิธีการชำระเงิน",
    amount: "จำนวนเงิน",
    invoice: "ใบแจ้งหนี้",
    slipNo: "เลขที่ใบเสร็จ",
    users: "ผู้ใช้",
    email: "อีเมล",
    walletNo: "เลขกระเป๋าเงิน",
    mobile: "โทรศัพท์มือถือ",
    address: "ที่อยู่",
    availableBalance: "ยอดคงเหลือ",
    depositRequest: "คำขอฝากเงิน",
    requestText: "มีการร้องขอให้คุณส่งเงินฝาก",
    requiredDepositAmount: "จำนวนเงินฝากที่ต้องการ",
    remark: "หมายเหตุ",
    depositFor: "ฝากเงินสำหรับ",
    depositNow: "ฝากเงินตอนนี้",
    depositReceipt: "ใบเสร็จฝากเงิน",
    due: "ครบกำหนด",
    payNow: "ชำระเงินตอนนี้",
    remarkText: "หากคุณมีคำถามหรือต้องการความช่วยเหลือ กรุณาติดต่อเรา",
    cash: "เงินสด",
    creditDebit: "บัตรเครดิต/เดบิต",
    digitalPayment: "การชำระเงินดิจิทัล",
    eWallet: "กระเป๋าเงินอิเล็กทรอนิกส์",
    paymentAddedSuccessFully: "เพิ่มการชำระเงินเรียบร้อยแล้ว",
    paymentReceipt: "ใบเสร็จรับเงิน",
    original: "ต้นฉบับ",
    customerCopy: "สำเนาลูกค้า",
    dueDate: "วันที่ครบกำหนด",
    billingDate: "วันที่เรียกเก็บเงิน",
    dueAmount: "ยอดครบกำหนด",
    paymentDate: "วันที่ชำระเงิน",
    time: "เวลา",
    bankCard: "ธนาคาร/บัตร",
    number: "หมายเลข",
    expiryDate: "วันหมดอายุ",
    totalAmountReceived: "จำนวนเงินทั้งหมดที่ได้รับ",
    authorizedSignature: "ลายเซ็นที่ได้รับอนุญาต",
    totalAmount: "จำนวนเงินทั้งหมด",
    mrn: "MRN",
    addDeposit: "เพิ่มเงินฝาก",
    printDepositHistory: "พิมพ์ประวัติการฝาก",
    searchUser: "ค้นหาผู้ใช้",
    to: "ถึง",
    type: "ประเภท",
    totalDeposit: "ยอดฝากทั้งหมด",
    total: "รวม",
    paymentStatement: "ใบสำหรับการชำระเงิน",
    depositStatement: "ใบสำหรับการฝากเงิน",
  }
  const arabic = {
    financeManagement: "إدارة المالية",
    deposit: "إيداع",
    payment: "دفع",
    paymentHistory: "تاريخ الدفعات",
    depositHistory: "تاريخ الإيداع",
    date: "التاريخ",
    transactionId: "معرف المعاملة",
    name: "الاسم",
    paymentMethod: "طريقة الدفع",
    amount: "المبلغ",
    invoice: "فاتورة",
    slipNo: "رقم الإيصال",
    users: "المستخدمين",
    email: "البريد الإلكتروني",
    walletNo: "رقم المحفظة",
    mobile: "الهاتف المحمول",
    address: "العنوان",
    availableBalance: "الرصيد المتاح",
    depositRequest: "طلب إيداع",
    requestText: "تم تقديم طلب لك لتقديم الإيداع",
    requiredDepositAmount: "مبلغ الإيداع المطلوب",
    remark: "ملاحظة",
    depositFor: "إيداع لـ",
    depositNow: "إيداع الآن",
    depositReceipt: "إيصال الإيداع",
    due: "مستحق",
    payNow: "ادفع الآن",
    remarkText: "إذا كان لديك أي سؤال أو تحتاج إلى مساعدة ، يرجى الاتصال بنا.",
    cash: "نقد",
    creditDebit: "بطاقة الائتمان / الخصم",
    digitalPayment: "الدفع الرقمي",
    eWallet: "المحفظة الإلكترونية",
    paymentAddedSuccessFully: "تم إضافة الدفع بنجاح",
    paymentReceipt: "إيصال الدفع",
    original: "الأصل",
    customerCopy: "نسخة العميل",
    dueDate: "تاريخ الاستحقاق",
    billingDate: "تاريخ الفاتورة",
    dueAmount: "المبلغ المستحق",
    paymentDate: "تاريخ الدفع",
    time: "الوقت",
    bankCard: "البنك / البطاقة",
    number: "رقم",
    expiryDate: "تاريخ الانتهاء",
    totalAmountReceived: "إجمالي المبلغ المستلم",
    authorizedSignature: "التوقيع المعتمد",
    totalAmount: "المبلغ الإجمالي",
    mrn: "MRN",
    addDeposit: "إضافة إيداع",
    printDepositHistory: "طباعة تاريخ الإيداع",
    searchUser: "البحث عن مستخدم",
    to: "إلى",
    type: "الأنوع",
    totalDeposit: "الإيداع الإجمالي",
    total: "المجموع",
    paymentStatement: "كشف الدفعات",
    depositStatement: "كشف الإيداع",
  }
  const bengali = {
    financeManagement: "ফাইন্যান্স ম্যানেজমেন্ট",
    deposit: "অর্থ জমা",
    payment: "পেমেন্ট",
    paymentHistory: "পেমেন্টের ইতিহাস",
    depositHistory: "অর্থ জমার ইতিহাস",
    date: "তারিখ",
    transactionId: "লেনদেন আইডি",
    name: "নাম",
    paymentMethod: "পেমেন্ট পদ্ধতি",
    amount: "পরিমাণ",
    invoice: "চালান",
    slipNo: "স্লিপ নম্বর",
    users: "ব্যবহারকারী",
    email: "ইমেল",
    walletNo: "ওয়ালেট নম্বর",
    mobile: "মোবাইল",
    address: "ঠিকানা",
    availableBalance: "প্রাপ্ত ব্যালেন্স",
    depositRequest: "অর্থ জমার অনুরোধ",
    requestText: "আপনার পক্ষ থেকে অর্থ জমা দেওয়ার জন্য একটি অনুরোধ করা হয়েছে",
    requiredDepositAmount: "প্রয়োজনীয় অর্থ জমার পরিমাণ",
    remark: "মন্তব্য",
    depositFor: "অর্থ জমার জন্য",
    depositNow: "এখন অর্থ জমা করুন",
    depositReceipt: "অর্থ জমার রসিদ",
    due: "বকেয়া",
    payNow: "এখন পরিশোধ করুন",
    remarkText: "যদি আপনার কোনো প্রশ্ন থাকে বা সাহায্য প্রয়োজন হয়, আমাদের সাথে যোগাযোগ করুন।",
    cash: "নগদ",
    creditDebit: "ক্রেডিট/ডেবিট কার্ড",
    digitalPayment: "ডিজিটাল পেমেন্ট",
    eWallet: "ই-ওয়ালেট",
    paymentAddedSuccessFully: "পেমেন্ট সফলভাবে যুক্ত করা হয়েছে",
    paymentReceipt: "পেমেন্ট রসিদ",
    original: "মৌলিক",
    customerCopy: "গ্রাহক কপি",
    dueDate: "বকেয়ার তারিখ",
    billingDate: "বিলিং তারিখ",
    dueAmount: "বকেয়া পরিমাণ",
    paymentDate: "পেমেন্টের তারিখ",
    time: "সময়",
    bankCard: "ব্যাংক/কার্ড",
    number: "নম্বর",
    expiryDate: "মেয়াদ শেষের তারিখ",
    totalAmountReceived: "মোট প্রাপ্ত পরিমাণ",
    authorizedSignature: "অনুমোদিত স্বাক্ষর",
    totalAmount: "মোট পরিমাণ",
    mrn: "এমআরএন",
    addDeposit: "অর্থ জমা যোগ করুন",
    printDepositHistory: "অর্থ জমার ইতিহাস মুদ্রণ করুন",
    searchUser: "ব্যবহারকারী অনুসন্ধান করুন",
    to: "থেকে",
    type: "ধরন",
    totalDeposit: "মোট অর্থ জমা",
    total: "মোট",
    paymentStatement: "পেমেন্ট বিবৃতি",
    depositStatement: "অর্থ জমার বিবৃতি",
}
  
export const financeManagementLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}