const english = {
    employees : "Employees",
    sl : "SL",
    name : "Name",
    image : "Image",
    mobile : "Mobile",
    phone : "Phone",
    email : "Email",
    designation : "Designation",
    action: "Action",
    fullName: "Full Name",
  fatherName: "Father Name",
  phone1: "Phone 1",
  mobile1: "Mobile 1",
  address1: "Address 1",
  dateOfBirth: "Date of Birth",
  mothersName: "Mother's Name",
  phone2: "Phone 2",
  mobile2: "Mobile 2",
  address2: "Address 2",
  picture: "Picture",
  update: "Update",
  personalInfo: "Personal Info",
  myWallet: "My Wallet",
  familyMember: "Family Member",
  saveMessage: "Data has been saved successfully",
  updateMessage: "Data has been updated successfully",
  deleteMessage: "Data has been deleted successfully",
  areYouSure: "Are you sure?",
  deleteWarning: "You won't be able to revert this!",
  yesDelete: "Yes, delete it!",
}
const malay = {
    employees: "Pekerja",
    sl: "SL",
    name: "Nama",
    image: "Gambar",
    mobile: "Mudah Alih",
    phone: "Telefon",
    email: "E-mel",
    designation: "Jawatan",
    action: "Tindakan",
    fullName: "Nama Penuh",
    fatherName: "Nama Bapa",
    phone1: "Telefon 1",
    mobile1: "Mudah Alih 1",
    address1: "Alamat 1",
    dateOfBirth: "Tarikh Lahir",
    mothersName: "Nama Ibu",
    phone2: "Telefon 2",
    mobile2: "Mudah Alih 2",
    address2: "Alamat 2",
    picture: "Gambar",
    update: "Kemas Kini",
    personalInfo: "Maklumat Peribadi",
    myWallet: "Dompet Saya",
    familyMember: "Ahli Keluarga",
    saveMessage: "Data telah disimpan dengan jayanya",
    updateMessage: "Data telah dikemas kini dengan jayanya",
    deleteMessage: "Data telah dipadamkan dengan jayanya",
    areYouSure: "Adakah anda pasti?",
    deleteWarning: "Anda tidak akan dapat mengembalikannya!",
    yesDelete: "Ya, padamkan!"
  }
  const thai = {
    employees: "พนักงาน",
    sl: "ลำดับ",
    name: "ชื่อ",
    image: "ภาพ",
    mobile: "มือถือ",
    phone: "โทรศัพท์",
    email: "อีเมล",
    designation: "ตำแหน่ง",
    action: "การกระทำ",
    fullName: "ชื่อเต็ม",
    fatherName: "ชื่อบิดา",
    phone1: "โทรศัพท์ 1",
    mobile1: "มือถือ 1",
    address1: "ที่อยู่ 1",
    dateOfBirth: "วันเกิด",
    mothersName: "ชื่อมารดา",
    phone2: "โทรศัพท์ 2",
    mobile2: "มือถือ 2",
    address2: "ที่อยู่ 2",
    picture: "ภาพ",
    update: "อัปเดต",
    personalInfo: "ข้อมูลส่วนตัว",
    myWallet: "กระเป๋าเงินของฉัน",
    familyMember: "สมาชิกในครอบครัว",
    saveMessage: "ข้อมูลได้ถูกบันทึกเรียบร้อยแล้ว",
    updateMessage: "ข้อมูลได้ถูกอัปเดตเรียบร้อยแล้ว",
    deleteMessage: "ข้อมูลได้ถูกลบเรียบร้อยแล้ว",
    areYouSure: "คุณแน่ใจหรือไม่?",
    deleteWarning: "คุณจะไม่สามารถกู้คืนได้!",
    yesDelete: "ใช่, ลบเลย!"
  }
  const arabic = {
    employees: "الموظفون",
    sl: "مسلسل",
    name: "الاسم",
    image: "صورة",
    mobile: "جوال",
    phone: "هاتف",
    email: "البريد الإلكتروني",
    designation: "الوظيفة",
    action: "إجراء",
    fullName: "الاسم الكامل",
    fatherName: "اسم الأب",
    phone1: "هاتف 1",
    mobile1: "جوال 1",
    address1: "العنوان 1",
    dateOfBirth: "تاريخ الميلاد",
    mothersName: "اسم الأم",
    phone2: "هاتف 2",
    mobile2: "جوال 2",
    address2: "العنوان 2",
    picture: "صورة",
    update: "تحديث",
    personalInfo: "معلومات شخصية",
    myWallet: "محفظتي",
    familyMember: "عضو الأسرة",
    saveMessage: "تم حفظ البيانات بنجاح",
    updateMessage: "تم تحديث البيانات بنجاح",
    deleteMessage: "تم حذف البيانات بنجاح",
    areYouSure: "هل أنت متأكد؟",
    deleteWarning: "لن تتمكن من استعادته!",
    yesDelete: "نعم، احذفه!"
  }
  const bengali = {
    employees: "কর্মচারী",
    sl: "ক্রম",
    name: "নাম",
    image: "ছবি",
    mobile: "মোবাইল",
    phone: "ফোন",
    email: "ইমেল",
    designation: "উপাধি",
    action: "কর্ম",
    fullName: "পুরো নাম",
    fatherName: "বাবার নাম",
    phone1: "ফোন ১",
    mobile1: "মোবাইল ১",
    address1: "ঠিকানা ১",
    dateOfBirth: "জন্ম তারিখ",
    mothersName: "মায়ের নাম",
    phone2: "ফোন ২",
    mobile2: "মোবাইল ২",
    address2: "ঠিকানা ২",
    picture: "ছবি",
    update: "হালনাগাদ",
    personalInfo: "ব্যক্তিগত তথ্য",
    myWallet: "আমার মানিব্যাগ",
    familyMember: "পরিবারের সদস্য",
    saveMessage: "তথ্য সফলভাবে সংরক্ষিত হয়েছে",
    updateMessage: "তথ্য সফলভাবে আপডেট হয়েছে",
    deleteMessage: "তথ্য সফলভাবে মুছে ফেলা হয়েছে",
    areYouSure: "আপনি কি নিশ্চিত?",
    deleteWarning: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yesDelete: "হ্যাঁ, মুছে ফেলুন!"
  }
        
export const employeeManagementLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}
