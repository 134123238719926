import React, { useEffect, useRef, useState } from 'react'
import allCustomStyles from '../../../CSS/ReactModalStyle';
import Modal from "react-modal";
import http from '../../../http';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { FcPrint } from "react-icons/fc";
import { useReactToPrint } from 'react-to-print';
import Setting from '../../Setting/Setting';
import logo from './../../../front_assets/Logo_Image/logo.jpg'
import useLanguage from '../../../language/useLanguage';
import { paymentHistoryLang } from '../../../language/billingManagement/paymentHistoryLang';

export default function PaymentHistoryModal({
    paymentHistoryModal,
    setpaymentHistoryModal,
    bill_entries_id
}) {
    const { historyModal } = allCustomStyles;
    const { currencyFormat } = Setting;
const lan = useLanguage(paymentHistoryLang)

    const closeModal = () => {
        setpaymentHistoryModal(false)
        setdata()
    }

    const [data, setdata] = useState()
    const [loding, setloding] = useState(false)

    useEffect(() => {
        let sub = false;
        if (!sub) {
            setloding(true)
            http.get(`payment-history/${bill_entries_id}`)
                .then(res => {
                    setdata(res.data.data)
                    setloding(false)
                })
                .catch(err => {
                    setloding(false)
                    console.log(err)
                })
        }

        return () => {
            sub = true;
        }
    }, [bill_entries_id])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Modal
            isOpen={paymentHistoryModal}
            onRequestClose={paymentHistoryModal}
            style={historyModal}
            contentLabel="Patment Modal"
        >
            <div className="product_modal">
                <div className="page-content">
                    <div className=" patients-head ">
                        <h5 className="fw-normal  text-start card-name">
                            {lan?.paymentHistory}
                            <span
                                style={{ cursor: "pointer", fontSize: "16px" }}
                                onClick={closeModal}
                                className="float-end"
                            >
                                <i className="fal fa-times"></i>
                            </span>
                            <hr></hr>
                        </h5>
                    </div>

                    {
                        loding ? <CircularProgress style={{ marginLeft: "50%" }} /> :
                            <div ref={componentRef} className="history-body">
                                <div className="payment_receipt d-flex justify-content-between align-items-center">
                                    <h4 className="text-uppercase">
                                        {lan?.paymentStatement}
                                    </h4>
                                    <img src={logo} width="50" height="50" alt="logo" />
                                </div>
                                <div className="owner_info mb-2">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-3">{lan?.name}  </div>
                                                <div className="col-9">: {data?.floor?.tenant === null ? data?.floor?.owner?.name : data?.floor?.tenant?.name}</div>

                                            </div>
                                            <div className="row">
                                                <div className="col-3">{lan?.mobile}  </div>
                                                <div className="col-9">: {data?.floor?.tenant === null ? data?.floor?.owner?.mobile_1 : data?.floor?.tenant?.mobile_1}</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-3">{lan?.email}</div>
                                                <div className="col-9">: {data?.floor?.tenant === null ? data?.floor?.owner?.email : data?.floor?.tenant?.email}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">{lan?.address}</div>
                                                <div className="col-9">: {data?.floor?.tenant === null ? data?.floor?.owner?.address_line_1 : data?.floor?.tenant?.address_line_1}</div>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                                <div className="d-flex mb-2">
                                    <div className="col-3 me-1 owner_info">
                                        <p>{lan?.totalAmount} :</p>
                                        <p>
                                            {currencyFormat(data?.grand_total)}
                                        </p>
                                    </div>
                                    <div className="col-3 me-1 owner_info">
                                        <p>{lan?.receivedAmount} :</p>
                                        <p>
                                            {currencyFormat(data?.received_amount)}
                                        </p>
                                    </div>
                                    <div className="col-3 owner_info">
                                        <p>{lan?.dueAmount} :</p>
                                        <p>
                                            {currencyFormat(data?.due)}
                                        </p>
                                    </div>

                                </div>

                                <div className="owner_info d-flex justify-content-between mb-2">

                                    <div className="d-flex gap-2">
                                        <p>{lan?.invoice} :</p>
                                        <p>
                                            {data?.invoice}
                                        </p>
                                    </div>
                                    <div className="print_icon">
                                        <FcPrint style={{ fontSize: "20px", cursor: "pointer" }} onClick={handlePrint} />
                                    </div>

                                </div>

                                <div className="unit-manager-table invoice-item-table mb-3">
                                    <table className=''>
                                        <tr>
                                            <td>{lan?.mrn}</td>
                                            <td>{lan?.paymentDate}</td>
                                            <td>{lan?.time}</td>

                                            <td>{lan?.paymentMethod}</td>
                                            <td>{lan?.bankCard}</td>
                                            <td>{lan?.number}</td>
                                            <td>{lan?.expiredDate}</td>
                                            <td >{lan?.amount}</td>
                                        </tr>
                                        {
                                            data?.payment_history?.length > 0 ? <>
                                                {
                                                    data?.payment_history?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{item?.slip_number}</td>
                                                            <td>{moment(item?.created_at).format("DD/MM/YYYY")}</td>
                                                            <td>{moment(item?.created_at).format("h:mm a")}</td>
                                                            <td>{item?.payment_method}</td>
                                                            <td>{item?.name}</td>
                                                            <td>{item?.number}</td>
                                                            <td>{item?.expired_Date && moment(item?.expired_Date).format("DD/MM/YYYY")}</td>
                                                            <td align='right'>{item?.amount}</td>
                                                        </tr>

                                                    ))


                                                }
                                                <tr><td colSpan={7}> <b>{lan?.total}</b></td>
                                                    <td align='right'> <b>{currencyFormat(data?.received_amount)}</b></td>
                                                </tr>

                                            </>
                                                :
                                                <tr><td style={{ color: "red" }} colSpan={8}>{lan?.notFound}</td></tr>
                                        }



                                    </table>
                                    <div className=" d-flex justify-content-end mt-5 ">
                                        <p className='signature' style={{ borderTop: "1px dashed grey", paddingTop: "10px" }}>{lan?.authorizedSignature}</p>
                                    </div>
                                </div>
                            </div>
                    }



                </div>
            </div>
        </Modal>
    )
}
