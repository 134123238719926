
import MaterialTable from 'material-table'
import React, { Fragment } from 'react'
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import http from '../../../http';
import allCustomStyles from '../../../CSS/ReactModalStyle';
import { adminSetupLang } from '../../../language/adminSetup/adminSetupLang';
import useLanguage from '../../../language/useLanguage';


export default function HelpLine() {
    const lan = useLanguage(adminSetupLang);
    const { HelpLine } = allCustomStyles;
    const [updateButton, setUpdateButton] = useState(false);

    const addData = () => {
        setIsOpen(true);
    }
    const deleteRowData = (id) => {
        Swal.fire({
            title: lan?.areYouSure,
            text: lan?.deleteWarning,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: lan?.yesDelete

        })
            .then((result) => {
                if (result.isConfirmed) {
                    http.delete(`help-line/${id}`)
                        .then((res) => {
                            setUpdate(!update);
                            setIsOpen(false);
                            Swal.fire({
                                title: lan?.deleted,
                                text: lan?.deletedMessage,
                                icon: "success"
                            });
    
                        })
                }
            })
    }
    const editData = (id) => {
        http.get(`help-line/${id}`)
            .then((res) => {
                setInputData({ ...res.data });
                setIsOpen(true);
                setUpdateButton(true);
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const columns = [
        {
            title: lan?.sl,
            field: "",
            render: (row) => <div>{row.tableData.id + 1}</div>,
            width: "20 !important",
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.image,
            field: `image`,
            render: (row) => <div><img src={`${global.img_Url}/images/${row?.image}`} width="80" height='80' /></div>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.helpLineCategory,
            field: `helpline_category`,
            cellStyle: {
                textAlign: "center",
            },
        },


        {
            title: lan?.helplineNumber,
            field: `helpline_number`,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: lan?.action,
            field: "patient",
            render: (row) => <div>{
                row?.deletable == 1 ? (
                    <></>
                ) : (
                    <>
                        <button
                            onClick={() => editData(row.id)}
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-edit"></i>
                        </button>

                        <button
                            onClick={() => deleteRowData(row.id)
                            }
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-trash"></i>
                        </button >
                    </>
                )
            }</div >,
            cellStyle: {
                textAlign: "center",
            },
        },
    ];

    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {
        const controller = new AbortController();
        setSpinner(true);
        http
            .get(`help-line`)
            .then((res) => {
                setData(res.data)
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });


        return () => {
            controller.abort();
        };
    }, [update]);
    // add modal 
    const [modalIsOpen, setIsOpen] = useState(false);
    const [picturePreview, setPicturePreview] = useState(null);
    const [inputData, setInputData] = useState({
        id: '',
        helpline_number: "",
        helpline_category: "",
        image: "",
    })
    const handleChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }
    const handlePdfChange = (event) => {
        setPicturePreview()
        const file = event.target.files[0];

        if (file.size < 1000048) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPicturePreview(reader.result);
            };
            reader.readAsDataURL(file);
            const existing = { ...inputData };
            existing["image"] = file;
            setInputData(existing);
        } else {
            inputRef.current.value = '';
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                title: 'Oops...',
                text: 'File size exceeds the limit (1MB)',
                showConfirmButton: true,
            })
        }
    };
    
    const [error, setError] = useState({
        image: '',
    })
    const [isLoading, setisLoading] = useState(false)

    const submitData = (e) => {
        e.preventDefault()
        setisLoading(true)
        if (inputData.id) {

            const formData = new FormData();
            formData.append('helpline_number', inputData.helpline_number)
            formData.append('image', inputData.image)
            formData.append('helpline_category', inputData.helpline_category)

            http.post(`help-line/${inputData.id}`, formData)
                .then((res) => {
                    setisLoading(false)
                    if (!res.data.error) {
                        setUpdate(!update);
                        setIsOpen(false);
                        setUpdateButton(false)
                        setInputData({ id: '', image: "", helpline_number: "", helpline_category: '' });
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: lan?.saveMessage,
                            showConfirmButton: false,
                            timer: 1500
                          })
                    } else {
                        Swal.fire({
                            position: 'top-center',
                            icon: 'error',
                            title: 'Oops...',
                            text: res.data.error,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }

                })
                .catch((err) => {
                    setisLoading(false)
                    const pdfError = err?.response?.data?.errors?.image[0];
                    setError({ image: pdfError })
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        } else {
            const formData = new FormData();
            formData.append('helpline_number', inputData.helpline_number)
            formData.append('image', inputData.image)
            formData.append('helpline_category', inputData.helpline_category)

            http.post('help-line', formData)
                .then((res) => {
                    setisLoading(false)
                    if (!res.data.error) {
                        setUpdate(!update);
                        setIsOpen(false);
                        setInputData({ id: '', image: "", helpline_number: "", helpline_category: '' });
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: lan?.updateMessage,
                            showConfirmButton: false,
                            timer: 1500
                          })
                    } else {
                        Swal.fire({
                            position: 'top-center',
                            icon: 'error',
                            title: 'Oops...',
                            text: res.data.error,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }

                })
                .catch((err) => {
                    setisLoading(false)
                    const pdfError = err?.response?.data?.errors?.image[0];
                    setError({ image: pdfError })
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        }

    }
    const closeModal = () => {
        setUpdateButton(false)

        setIsOpen(false);
        setInputData({ id: '', image: "", number: "", helpline_category: '' });
    }
   
    return (
        <div className='page-content adjustment-type-table'>
            <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                <h6>{lan?.helpLine}</h6>
                <div>
                    <button style={{ marginTop: "1px" }} onClick={addData} className='btn btn-sm btn-primary float-end'>{lan?.add}</button>
                </div>
            </div>

            <MaterialTable
                columns={columns}
                data={data}
                isLoading={spinner}
                options={{
                    search: true,
                    showTitle: false,
                    searchFieldAlignment: "left",
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
            />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={HelpLine}
                contentLabel="Example Modal"
            >
                <div className="product_modal">
                    <div className="page-content">
                        <div className=" patients-head ">
                            <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">  {updateButton === true ? lan?.edit : lan?.add}
                                <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={closeModal} className='float-end'><i className="fal fa-times"></i></span>
                            </h5>
                        </div>

                        <div className=" p-3">
                            <form onSubmit={submitData}>

                                <select name="helpline_category" onChange={handleChange} className="form-control form-control-sm my-2" required value={inputData?.helpline_category ? inputData?.helpline_category : ''}>
                                    <option value="" selected disabled>{lan?.select}</option>
                                    <option value="Dashboard Help Line">Dashboard Help Line</option>
                                    <option value="HelpLine">Help Line</option>
                                </select>

                                <input onChange={handleChange} value={inputData.helpline_number} name="helpline_number" type="number" className="form-control form-control-sm my-2" required placeholder="Helpline number" />


                                {inputData.id ? <>
                                    <input onChange={handlePdfChange} ref={inputRef} name="image" type="file" className="form-control form-control-sm my-2" accept='image/*' />
                                    <p className='text-danger'>{error?.image}</p>
                                    {typeof inputData?.image === String ? (<>
                                        <img src={`${global.img_Url}/images/${inputData?.image}`} alt="" width="120" />
                                    </>) : (<>
                                        <img src={picturePreview} alt="" width="120" />
                                    </>)}
                                </> : <>
                                    <input onChange={handlePdfChange} ref={inputRef} name="image" type="file" className="form-control form-control-sm my-2" required accept='image/*' />
                                    <img src={picturePreview} alt="" width="120" />
                                    <p className='text-danger'>{error?.image}</p>
                                </>}

                                {isLoading ?
                                    <button className="btn mt-2 btn-sm btn-info float-end text-uppercase" type="button"> {lan?.loading} ...</button>
                                    :
                                    inputData.id ? <>
                                        <button className="btn mt-2 btn-sm btn-success float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> {lan?.update}</button>
                                    </> : <>
                                        <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> {lan?.save}</button>
                                    </>}



                            </form>

                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}
