const english = {
    billProcess: "Bill Process",
    billingCycle: "Billing Cycle",
    preview: "Preview",
    acknowledgement: "Acknowledgement",
    numberOfBillingRecordsAvailable: "Number of billing records available",
    sl: "Sl",
    building: "Building",
    flat: "Flat",
    owner: "Owner",
    tenant: "Tenant",
    total: "Total",
    status: "Status",
    locked: "Locked",
    draft: "Draft",
    incomplete: "Incomplete",
    process: "Process",
    please: "Please acknowledge this information",
    reviewTheInformation: "Review the information",
    totalNoOfBuilding: "Total no of building",
    totalNoOfBillCreated: "Total no of bill created",
    totalNoOfIncompleteBill: "Total no of incomplete bill",
    check1: "Verify the acknowledge of the invoice details",
    check2: "Did you confirm the utility and managed service on the invoice",
    check3: "Did you confirm the Billing Month , Billing date and Due Date service on the invoice",
    check4: "Did you confirm the User information on the invoice",
    check5: "I acknowledge that the above informations are correct and checked all the bills before proceedings.",
    letsGo: "Let's go",
    billProcessComplete: "Bill Process Complete",
    closeWindow: "Close Window",

}
const malay = {
    billProcess: "Proses Bil",
    billingCycle: "Kitaran Bil",
    preview: "Pratonton",
    acknowledgement: "Pengakuan",
    numberOfBillingRecordsAvailable: "Bilangan rekod bil yang tersedia",
    sl: "Sl",
    building: "Bangunan",
    flat: "Flat",
    owner: "Pemilik",
    tenant: "Penyewa",
    total: "Jumlah",
    status: "Status",
    locked: "Terkunci",
    draft: "Draf",
    incomplete: "Tidak Lengkap",
    process: "Proses",
    please: "Sila akui maklumat ini",
    reviewTheInformation: "Semak maklumat",
    totalNoOfBuilding: "Jumlah bilangan bangunan",
    totalNoOfBillCreated: "Jumlah bilangan bil yang dibuat",
    totalNoOfIncompleteBill: "Jumlah bilangan bil yang tidak lengkap",
    check1: "Sahkan pengakuan butiran invois",
    check2: "Adakah anda mengesahkan utiliti dan perkhidmatan yang diuruskan dalam invois",
    check3: "Adakah anda mengesahkan Bulan Bil, Tarikh Bil dan Tarikh Tamat Tempoh dalam invois",
    check4: "Adakah anda mengesahkan maklumat Pengguna dalam invois",
    check5: "Saya mengakui bahawa maklumat di atas adalah betul dan telah menyemak semua bil sebelum meneruskan.",
    letsGo: "Mari Pergi",
    billProcessComplete: "Proses Bil Lengkap",
    closeWindow: "Tutup Tingkap",
}
const thai = {
    billProcess: "กระบวนการใบเรียกเก็บเงิน",
    billingCycle: "รอบการเรียกเก็บเงิน",
    preview: "ดูตัวอย่าง",
    acknowledgement: "การรับทราบ",
    numberOfBillingRecordsAvailable: "จำนวนบันทึกใบเรียกเก็บเงินที่มีอยู่",
    sl: "ลำดับ",
    building: "อาคาร",
    flat: "แฟลต",
    owner: "เจ้าของ",
    tenant: "ผู้เช่า",
    total: "รวม",
    status: "สถานะ",
    locked: "ล็อค",
    draft: "ร่าง",
    incomplete: "ไม่สมบูรณ์",
    process: "ดำเนินการ",
    please: "กรุณายืนยันข้อมูลนี้",
    reviewTheInformation: "ตรวจสอบข้อมูล",
    totalNoOfBuilding: "จำนวนอาคารทั้งหมด",
    totalNoOfBillCreated: "จำนวนใบเรียกเก็บเงินทั้งหมดที่สร้างขึ้น",
    totalNoOfIncompleteBill: "จำนวนใบเรียกเก็บเงินที่ไม่สมบูรณ์ทั้งหมด",
    check1: "ตรวจสอบการรับทราบรายละเอียดใบเรียกเก็บเงิน",
    check2: "คุณยืนยันการใช้ประโยชน์และบริการที่จัดการในใบเรียกเก็บเงินหรือไม่",
    check3: "คุณยืนยันเดือนที่เรียกเก็บเงิน, วันที่เรียกเก็บเงิน และวันครบกำหนดในใบเรียกเก็บเงินหรือไม่",
    check4: "คุณยืนยันข้อมูลผู้ใช้ในใบเรียกเก็บเงินหรือไม่",
    check5: "ฉันยืนยันว่าข้อมูลข้างต้นถูกต้องและได้ตรวจสอบใบเรียกเก็บเงินทั้งหมดก่อนดำเนินการต่อ",
    letsGo: "ไปกันเถอะ",
    billProcessComplete: "กระบวนการใบเรียกเก็บเงินเสร็จสิ้น",
    closeWindow: "ปิดหน้าต่าง",
}
const arabic = {
    billProcess: "عملية الفاتورة",
    billingCycle: "دورة الفوترة",
    preview: "معاينة",
    acknowledgement: "إقرار",
    numberOfBillingRecordsAvailable: "عدد سجلات الفوترة المتاحة",
    sl: "س",
    building: "البناء",
    flat: "شقة",
    owner: "المالك",
    tenant: "المستأجر",
    total: "الإجمالي",
    status: "الحالة",
    locked: "مغلق",
    draft: "مسودة",
    incomplete: "غير مكتمل",
    process: "معالجة",
    please: "يرجى الإقرار بهذه المعلومات",
    reviewTheInformation: "راجع المعلومات",
    totalNoOfBuilding: "إجمالي عدد المباني",
    totalNoOfBillCreated: "إجمالي عدد الفواتير التي تم إنشاؤها",
    totalNoOfIncompleteBill: "إجمالي عدد الفواتير غير المكتملة",
    check1: "تحقق من الإقرار بتفاصيل الفاتورة",
    check2: "هل أكدت الخدمة والمرافق المُدارة في الفاتورة",
    check3: "هل أكدت شهر الفاتورة، وتاريخ الفاتورة، وتاريخ الاستحقاق في الفاتورة",
    check4: "هل أكدت معلومات المستخدم في الفاتورة",
    check5: "أقر بأن المعلومات أعلاه صحيحة وقد قمت بمراجعة جميع الفواتير قبل المتابعة.",
    letsGo: "لنذهب",
    billProcessComplete: "اكتمال عملية الفاتورة",
    closeWindow: "إغلاق النافذة",
}
const bengali = {
    billProcess: "বিল প্রক্রিয়া",
    billingCycle: "বিলিং সাইকেল",
    preview: "প্রিভিউ",
    acknowledgement: "স্বীকৃতি",
    numberOfBillingRecordsAvailable: "উপলব্ধ বিলিং রেকর্ডের সংখ্যা",
    sl: "ক্র",
    building: "ভবন",
    flat: "ফ্ল্যাট",
    owner: "মালিক",
    tenant: "ভাড়াটে",
    total: "মোট",
    status: "অবস্থা",
    locked: "লকড",
    draft: "খসড়া",
    incomplete: "অসম্পূর্ণ",
    process: "প্রক্রিয়া",
    please: "অনুগ্রহ করে এই তথ্যটি স্বীকার করুন",
    reviewTheInformation: "তথ্য পর্যালোচনা করুন",
    totalNoOfBuilding: "মোট ভবনের সংখ্যা",
    totalNoOfBillCreated: "মোট তৈরি হওয়া বিলের সংখ্যা",
    totalNoOfIncompleteBill: "মোট অসম্পূর্ণ বিলের সংখ্যা",
    check1: "চালান বিবরণ যাচাই করুন",
    check2: "আপনি কি চালানে ইউটিলিটি এবং পরিচালিত পরিষেবাগুলি নিশ্চিত করেছেন",
    check3: "আপনি কি চালানে বিলিং মাস, বিলিং তারিখ এবং নির্ধারিত তারিখ নিশ্চিত করেছেন",
    check4: "আপনি কি চালানে ব্যবহারকারীর তথ্য নিশ্চিত করেছেন",
    check5: "আমি স্বীকার করি যে উপরের তথ্য সঠিক এবং আমি সমস্ত বিল পর্যালোচনা করেছি আগানোর আগে।",
    letsGo: "চলুন যাই",
    billProcessComplete: "বিল প্রক্রিয়া সম্পূর্ণ",
    closeWindow: "উইন্ডো বন্ধ করুন",
}

export const billProcessLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}