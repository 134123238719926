import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import "../../../CSS/Invoice.css";
import http from "../../../http";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import allCustomStyles from "../../../CSS/ReactModalStyle";
import Setting from "../../Setting/Setting";
import moment from "moment";
import { Checkbox, LinearProgress } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useLanguage from "../../../language/useLanguage";
import { ownerTenantsLang } from "../../../language/ownerTenants/ownerTenantsLang";



export default function EditOwners() {
const lan = useLanguage(ownerTenantsLang);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;


  const { currencyFormat } = Setting;

  const [member, setMember] = useState();
  const [gender, setGender] = useState();
  const [spinner, setSpinner] = useState();
  const [time, setTime] = useState(30);
  const { id } = useParams();
  const [ownership, setOwnership] = useState([]);
  const { colourStyles } = allCustomStyles;
  const { dateFormat } = Setting;
  const [personalPicturePreview, setPersonalPicturePreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    name: "",
    address_line_1: "",
    profession_id: "",
    date_of_birth: "",
    father_name: "",
    mobile_1: "",
    ownership_type_id: "",
    email: "",
  });
  const [familyMember, setFamilyMember] = useState([
    {
      id: "",
      owner_id: "",
      u_id: "1",
      full_name: "",
      member_type_id: "",
      nationlity: "",
      address: "",
      email: "",
      emergency: "",
      blood_group: "",
      image: "",
      contact_no: "",
      gender_id: "",
    },
  ]);

  const handle_Images_File = (event, index) => {
    if (event?.target?.files[0]?.size < 1000048) {
      const { files } = event?.target;
      const existing = [...familyMember];
      existing[index]["image"] = files[0];
      setFamilyMember(existing);
    } else {
      event.target.value = "";
      alert("File size exceeds the limit (1MB)");
    }
  };

  const [property, setproperty] = useState([])
  const [propertyvalue, setpropertyvalue] = useState([])

  useEffect(() => {
    setSpinner(true);

    http.get(`owner-setup-data`)
      .then((res) => {
        setproperty(res.data.data.pD)
        setNationality(res.data.data.nA)
        setProfession(res.data.data.dG)
        setbloodGroup(res.data.data.bG)
        setOwnership(res?.data?.data.oS);
        setMember(res.data.data.mT);
        setGender(res.data.data.g);

        setSpinner(false);


      }).catch(err => {
        console.log("err", err)
        setSpinner(false);
      })

    http
      .get(`edit-family-member-data/${id}`)
      .then((res) => {
        if (res?.data?.length > 0) {
          setFamilyMember(res?.data);
          // setSpinner(false);
        }
      })
      .catch(err => {
        console.log("err", err)
        setSpinner(false);
      })

  }, []);
  const [nationlity, setNationality] = useState([]);
  const [bloodGroup, setbloodGroup] = useState([]);

  // const ownership = [
  //     { title: 'Property Owner' },
  //     { title: 'Developer' },
  //     { title: 'Tenant' },

  // ]
  const [profession, setProfession] = useState([]);
  // project info
  const [personalInfo, setPersonaInfo] = useState({
    name: "",
    email: "",
    phone_1: "",
    phone_2: "",
    mobile_1: "",
    mobile_2: "",
    profession_id: "",
    profession_name: "",
    ownership_type_id: "",
    ownership_type: "",
    father_name: "",
    mother_name: "",
    date_of_birth: "",
    address_line_1: "",
    address_line_2: "",
    picture: "",
    e_wallet: "",
  });

  const handlePersonalInfoChange = (event) => {
    const { name, value } = event?.target;
    setPersonaInfo({ ...personalInfo, [name]: value });
  };
  const navigate = useNavigate();
  useEffect(() => {
    setSpinner(true);
    http.get(`owners/${id}`).then((res) => {
      setPersonaInfo({
        ...personalInfo,
        id: res?.data?.id,
        name: res?.data?.name,
        email: res?.data?.email,
        phone_1: res?.data?.phone_1,
        phone_2: res?.data?.phone_2,
        mobile_1: res?.data?.mobile_1,
        mobile_2: res?.data?.mobile_2,
        picture: res?.data?.picture,
        profession_name: res?.data?.profession?.name,
        profession_id: res?.data?.profession_id,
        ownership_type_id: res?.data?.owner_ships_type?.id,
        ownership_type: res?.data?.owner_ships_type?.name,
        father_name: res?.data?.father_name,
        mother_name: res?.data?.mother_name,
        date_of_birth: res?.data?.date_of_birth,
        address_line_1: res?.data?.address_line_1,
        address_line_2: res?.data?.address_line_2,
        e_wallet: res?.data?.e_wallet,
      });

      const vp = res.data.project_details.map(v => {
        return v.project;
      })


      setpropertyvalue(vp);

      if (res?.data?.wallet_number) {
        setwalletObj({ ...walletObj, finalNumb: res?.data?.wallet_number, verify: true, activationDate: res.data.wallet_activation_date })
      }


      setSpinner(false);
    });
    http.get(`designations`).then((res) => {
      if (res.status === 200) {
        setProfession(res.data.data);
      }
    });
  }, [id]);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!personalInfo.name) {
      setErrorMessage({ name: "Name field is required" });
      return false;
    } else if (!personalInfo.ownership_type_id) {
      setErrorMessage({
        ownership_type_id: "Ownership name field is required",
      });
      return false;
    } else if (!personalInfo.father_name) {
      setErrorMessage({ father_name: "father name field is required" });
      return false;
    } else if (!personalInfo.date_of_birth) {
      setErrorMessage({ date_of_birth: "date of birth field is required" });
      return false;
    } else if (!personalInfo.profession_id) {
      setErrorMessage({ profession_id: "Profession field is required" });
      return false;
    } else if (!personalInfo.mobile_1) {
      setErrorMessage({ mobile_1: "Mobile 1 field is required" });
      return false;
    } else if (!personalInfo.address_line_1) {
      setErrorMessage({ address_line_1: "Address line 1 field is required" });
      return false;
    }

    const formData = new FormData();
    formData.append("name", personalInfo.name);
    formData.append("email", personalInfo.email);
    formData.append("phone_1", personalInfo.phone_1);
    formData.append("phone_2", personalInfo.phone_2);
    formData.append("mobile_1", personalInfo.mobile_1);
    formData.append("mobile_2", personalInfo.mobile_2);
    formData.append("profession_id", personalInfo.profession_id);
    formData.append("ownership_type_id", personalInfo.ownership_type_id);
    formData.append("ownership_type", personalInfo?.ownership_type);
    formData.append("father_name", personalInfo.father_name);
    formData.append("mother_name", personalInfo.mother_name);
    formData.append("date_of_birth", personalInfo.date_of_birth);
    formData.append("address_line_1", personalInfo.address_line_1);
    formData.append("address_line_2", personalInfo.address_line_2);
    formData.append("picture", personalInfo.picture);
    formData.append("property", JSON.stringify(propertyvalue));

    http
      .post(`update-owner/${id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: lan?.updateMessage,
            showConfirmButton: false,
            timer: 1500
          })
          document.getElementById("picture").value = "";
          setPersonaInfo({
            name: "",
            email: "",
            phone_1: "",
            phone_2: "",
            mobile_1: "",
            mobile_2: "",
            profession_id: "",
            ownership_type_id: "",
            father_name: "",
            mother_name: "",
            date_of_birth: "",
            address_line_1: "",
            address_line_2: "",
          });
          familyMember.map((item, i) => {
            const formDataFamily = new FormData();
            formDataFamily.append("owner_id", personalInfo?.id);
            formDataFamily.append("id", item?.id);
            formDataFamily.append("u_id", item?.u_id);
            formDataFamily.append("full_name", item?.full_name);
            formDataFamily.append("member_type_id", item?.member_type_id);
            formDataFamily.append("nationlity", item?.nationlity);
            formDataFamily.append("address", item?.address);
            formDataFamily.append("email", item?.email);
            formDataFamily.append("emergency", item?.emergency);
            formDataFamily.append("blood_group", item?.blood_group);
            formDataFamily.append("full_name", item?.full_name);
            formDataFamily.append("contact_no", item?.contact_no);
            formDataFamily.append("gender_id", item?.gender_id);
            formDataFamily.append("image", item?.image);
            http
              .post(`owners-family-members-data-update`, formDataFamily)
              .then((res) => { });
          });
          navigate("/owners");
        }
      })
      .catch((err) => {
        setErrorMessage({
          ...errorMessage,
          mobile_1: err?.response?.data?.errors?.mobile_1,
          name: err.response.data.errors.name,
          address_line_1: err?.response?.data?.errors?.address_line_1,
          profession_id: err?.response?.data?.errors?.profession_id,
          date_of_birth: err?.response?.data?.errors?.date_of_birth,
          father_name: err?.response?.data?.errors?.father_name,
          ownership_type_id: err?.response?.data?.errors?.ownership_type_id,
          email: err?.response?.data?.errors?.email,
        });
      });
  };

  const handleNextPage = (id) => {
    document.getElementById(id).click();
  };

  const handleFamilyDetailsChange = (event, index) => {
    const { name, value } = event?.target;
    const existing = [...familyMember];
    existing[index][name] = value;
    setFamilyMember(existing);
  };
  const handleMemberTypeChange = (event, index) => {
    const { id } = event;
    const existing = [...familyMember];
    existing[index]["member_type_id"] = id;
    setFamilyMember(existing);
  };

  const handleNationlityChange = (event, index) => {
    const { id } = event;
    const existing = [...familyMember];
    existing[index]["nationlity"] = id;
    setFamilyMember(existing);
  };
  const handleGenderChange = (event, index) => {
    const { id } = event;
    const existing = [...familyMember];
    existing[index]["gender_id"] = id;
    setFamilyMember(existing);
  };
  const handleBloodGroupChange = (event, index) => {
    const { id } = event;
    const existing = [...familyMember];
    existing[index]["blood_group"] = id;
    setFamilyMember(existing);
  };
  const handleAddFamilyDetails = () => {
    setFamilyMember([
      ...familyMember,
      {
        id: "",
        u_id: `${familyMember.length + 1}-${new Date().getTime()}`,
        full_name: "",
        member_type_id: "",
        nationlity: "",
        address: "",
        email: "",
        emergency: "",
        blood_group: "",
        image: "",
        contact_no: "",
        gender_id: "",
        owner_id: id,
      },
    ]);
  };
  const deleteFamily = (id) => {
    Swal.fire({
      title: lan?.areYouSure,
            text: lan?.deleteWarning,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: lan?.yesDelete
    }).then((result) => {
      if (result.isConfirmed) {
        http.get(`delete-family-member/${id}`).then((res) => {
          if (res.data.status === 200) {
            setFamilyMember(familyMember.filter((item) => item.id !== id));
          }
        });
        Swal.fire({
          title: lan?.deleted,
          text: lan?.deletedMessage,
          icon: "success"
      });
      }
    });
  };
  const handleRemoveFamilyDetails = (id) => {
    setFamilyMember(familyMember.filter((item) => item.u_id !== id));
  };
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (time > 0) {
        setTime((previous) => previous - 1);
      }
    }, 1000);
    if (time === 0) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [time]);
  const sendOTP = () => {
    http
      .post("send-otp", { wallet_number: walletObj?.num })
      .then((res) => {
        setwalletObj({ ...walletObj, sendOTP: true })
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Otp send successfully",
          showConfirmButton: false,
          timer: 1500,
        });

      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: err?.response?.data.message,
          showConfirmButton: true
        });
      });
  };

  const handleOtpVerification = () => {
    const otpVerify = {
      mobile: walletObj?.num,
      owner_id: personalInfo?.id,
      otp: walletObj?.otp,
    };
    http
      .post("otp-verify", otpVerify)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: res?.data?.message,
          showConfirmButton: false,
          timer: 1500,
        });
        setwalletObj({ ...walletObj, finalNumb: res.data.data.mobile_num, verify: true, activationDate: res.data.data.updated_at })
      })
      .catch((err) => {
        console.log("err", err)
        Swal.fire({
          position: "center",
          icon: "error",
          title: err?.response?.data.message,
          showConfirmButton: true,
        });
      });
  };

  const handle_IPersonalmages_File = (event) => {
    const file = event.target.files[0];

    if (file.size < 1000048) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPersonalPicturePreview(reader.result);
      };
      reader.readAsDataURL(file);
      const existing = { ...personalInfo };
      existing["picture"] = file;
      setPersonaInfo(existing);
    } else {
      event.target.value = "";
      Swal.fire({
        position: "top-center",
        icon: "error",
        title: "Deleted!",
        text: "File size exceeds the limit (1MB).",
        showConfirmButton: true,
      });
    }
  };

  const totalDeposit = personalInfo?.e_wallet?.history
    ?.filter((item) => item?.deposit_or_withdraw === "deposit")
    ?.reduce((acc, detail) => acc + Number(detail?.amount), 0);

  const totalPayment = personalInfo?.e_wallet?.history
    ?.filter((item) => item?.deposit_or_withdraw === "withdraw")
    ?.reduce((acc, detail) => acc + Number(detail?.amount), 0);


  const [walletObj, setwalletObj] = useState({
    num: "",
    finalNumb: "",
    otp: "",
    verify: false,
    error: "",
    activationDate: "",
    sendOTP: false
  })

  return (
    <div className="page-content bg-white">
      {spinner && <LinearProgress />}
      <div className="property-register ms-1">
        <div className="row">
          <h3 className="text-center my-3">{lan?.ownerInformation}</h3>
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                {lan?.personalInfo}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                {lan?.familyMember}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                {lan?.myWallet}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>
                          {lan?.fullName} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <input
                          disabled
                          required
                          type="text"
                          onChange={handlePersonalInfoChange}
                          value={personalInfo.name}
                          name="name"
                          className="form-control form-control-sm"
                        />
                        {errorMessage.name && (
                          <div className="text-danger">

                            {errorMessage.name}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>

                          {lan?.ownershipType} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={ownership}
                          getOptionLabel={(option) => option?.name}
                          sx={{ width: "100%" }}
                          size="small"
                          onChange={(event, value) => {
                            setPersonaInfo({
                              ...personalInfo,
                              ownership_type_id: value?.id,
                              ownership_type: value?.name,
                            });
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={personalInfo.ownership_type}
                            />
                          )}
                        />
                        {errorMessage.ownership_type_id && (
                          <div className="text-danger">

                            {errorMessage.ownership_type_id}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label> {lan?.selectProperty}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                      </div>
                      <div className="col-9">
                        {/* <Autocomplete
                          size="small"
                          multiple
                          id="combo-box-demo"
                          value={propertyvalue}
                          options={property}
                          getOptionLabel={(option) => option?.property_name}
                          sx={{ width: '100%' }}
                          onChange={(event, value) => {


                            setpropertyvalue(value)

                          }}
                          renderInput={(params) => <TextField {...params} label="Select Property" />}
                        /> */}

                        <Autocomplete
                          multiple
                          options={property}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option?.property_name}
                          onChange={(e, v) => {
                            console.log("property", v)
                            setpropertyvalue(v)
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option?.property_name}
                            </li>
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option?.property_name === value?.property_name
                          }
                          size="small"
                          value={propertyvalue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Buildings"
                              placeholder="Select Propertry"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>
                          {lan?.fatherName} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <input
                          required
                          type="text"
                          onChange={handlePersonalInfoChange}
                          value={personalInfo?.father_name}
                          name="father_name"
                          className="form-control form-control-sm"
                        />
                        {errorMessage.father_name && (
                          <div className="text-danger">

                            {errorMessage.father_name}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label> {lan?.phone1}</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="number"
                          onChange={handlePersonalInfoChange}
                          value={
                            personalInfo?.phone_1 == "null"
                              ? ""
                              : personalInfo?.phone_1
                          }
                          name="phone_1"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>

                          {lan?.mobile1} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <input
                          disabled
                          required
                          type="number"
                          onChange={handlePersonalInfoChange}
                          value={personalInfo?.mobile_1}
                          name="mobile_1"
                          className="form-control form-control-sm"
                        />
                        {errorMessage.mobile_1 && (
                          <div className="text-danger">

                            {errorMessage.mobile_1}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label> {lan?.email} </label>
                      </div>
                      <div className="col-9">
                        <input
                          disabled
                          type="email"
                          onChange={handlePersonalInfoChange}
                          value={personalInfo?.email}
                          name="email"
                          className="form-control form-control-sm"
                        />
                        {errorMessage.email && (
                          <div className="text-danger">

                            {errorMessage.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label htmlFor="address1">
                          {lan?.address1} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <textarea
                          required
                          name="address_line_1"
                          onChange={handlePersonalInfoChange}
                          value={personalInfo?.address_line_1}
                          className="form-control form-control-sm"
                          id=""
                          cols="30"
                          rows="3"
                        ></textarea>
                        {errorMessage.address_line_1 && (
                          <div className="text-danger">

                            {errorMessage.address_line_1}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-3">
                        <label className="">
                          {lan?.dateOfBirth} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <DatePicker
                          required
                          // format={dateFormat}
                          selected={
                            personalInfo?.date_of_birth
                              ? new Date(personalInfo?.date_of_birth)
                              : new Date()
                          }
                          dateFormat="dd/MM/yyyy"
                          className="form-control form-control-sm"
                          onChange={(date) =>
                            setPersonaInfo({
                              ...personalInfo,
                              date_of_birth: date,
                            })
                          }
                        />
                        {errorMessage.date_of_birth && (
                          <div className="text-danger">

                            {errorMessage.date_of_birth}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <label>

                          {lan?.profession} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <Autocomplete
                          disablePortal
                          required
                          id="combo-box-demo"
                          options={profession}
                          getOptionLabel={(option) => option.name}
                          sx={{ width: "100%" }}
                          size="small"
                          onChange={(event, value) => {
                            setPersonaInfo({
                              ...personalInfo,
                              profession_id: value.id,
                            });
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={personalInfo.profession_name}
                            />
                          )}
                        />
                        {errorMessage.profession_id && (
                          <div className="text-danger">

                            {errorMessage.profession_id}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>

                          {lan?.mothersName} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <input
                          required
                          type="text"
                          onChange={handlePersonalInfoChange}
                          value={
                            personalInfo.mother_name == "null"
                              ? ""
                              : personalInfo.mother_name
                          }
                          name="mother_name"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>{lan?.phone2}</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="text"
                          onChange={handlePersonalInfoChange}
                          value={
                            personalInfo.phone_2 == "null"
                              ? ""
                              : personalInfo.phone_2
                          }
                          name="phone_2"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label> {lan?.mobile2}</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="text"
                          onChange={handlePersonalInfoChange}
                          value={
                            personalInfo.mobile_2 == "null"
                              ? ""
                              : personalInfo.mobile_2
                          }
                          name="mobile_2"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <label htmlFor="address1">{lan?.address2}</label>
                      </div>
                      <div className="col-9">
                        <textarea
                          name="address_line_2"
                          onChange={handlePersonalInfoChange}
                          value={
                            personalInfo.address_line_2 == "null"
                              ? ""
                              : personalInfo.address_line_2
                          }
                          className="form-control form-control-sm"
                          id=""
                          cols="30"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label> {lan?.picture}</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="file"
                          id="picture"
                          onChange={(e) => handle_IPersonalmages_File(e)}
                          accept="image/*"
                          name="picture"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    {personalPicturePreview === null ? (
                      <>
                        <div className="row mb-2">
                          <div className="col-3"></div>
                          <div className="col-9">
                            {/* Ensure that personalInfo?.picture is properly rendered */}
                            <img
                              src={`${global.img_Url}/images/${personalInfo?.picture}`}
                              alt=""
                              width="120"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row mb-2">
                          <div className="col-3"></div>
                          <div className="col-9">
                            <img
                              src={personalPicturePreview}
                              alt="Live Preview"
                              style={{ maxWidth: "100%", height: "auto" }}
                              width="120"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        onClick={() => navigate("/owners")}
                        className="btn btn-sm btn-primary me-1 mt-2"
                      >
                        {lan?.back}
                      </button>

                      <button
                        type="button"
                        onClick={() => handleNextPage("pills-contact-tab")}
                        className="btn btn-sm btn-primary me-1 mt-2"
                      >
                        {lan?.next}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-success  mt-2"
                      >
                        {lan?.update}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <form onSubmit={handleSubmit}>
                {familyMember.map((item, i) => {
                  return (
                    <div key={item.u_id} className="row mb-2">
                      <div className="col-md-6">
                        <div className="row mb-2">
                          <div className="col-3">
                            <label>
                              {lan?.memberType}<span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <Select
                              required
                              className="basic-single"
                              styles={colourStyles}
                              onChange={(e) => handleMemberTypeChange(e, i)}
                              options={member}
                              value={member?.filter(
                                (e) => e.id == item.member_type_id
                              )}
                              getOptionLabel={(option) => option.member_type}
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-3">
                            <label>
                              {lan?.gender} <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <Select
                              required
                              className="basic-single"
                              styles={colourStyles}
                              onChange={(e) => handleGenderChange(e, i)}
                              options={gender}
                              value={gender?.filter(
                                (e) => e.id == item?.gender_id
                              )}
                              getOptionLabel={(option) => option?.gender_type}
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-3">
                            <label>
                              {lan?.contactNo} <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <input
                              required
                              type="number"
                              onChange={(e) => handleFamilyDetailsChange(e, i)}
                              value={
                                item?.contact_no == "null"
                                  ? ""
                                  : item?.contact_no
                              }
                              name="contact_no"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-3">
                            <label> {lan?.email} </label>
                          </div>
                          <div className="col-9">
                            <input
                              type="text"
                              value={item?.email == "null" ? "" : item?.email}
                              onChange={(e) => handleFamilyDetailsChange(e, i)}
                              name="email"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <label>
                              {lan?.bloodGroup} <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <Select
                              className="basic-single"
                              styles={colourStyles}
                              onChange={(e) => handleBloodGroupChange(e, i)}
                              options={bloodGroup}
                              value={bloodGroup.find(
                                (e) => e.id == item?.blood_group
                              )}
                              getOptionLabel={(option) => option.name}
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3 mb-1">
                            <label htmlFor="image"> {lan?.profileImage} </label>
                          </div>
                          <div className="col-9">
                            <input
                              type="file"
                              onChange={(e) => handle_Images_File(e, i)}
                              id="image"
                              name="image"
                              className="form-control form-control-sm mb-2"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3 mb-1"></div>
                          <div className="col-9">
                            {typeof item?.image === "string" ? (
                              <>
                                <img
                                  src={`${global.img_Url}/images/${item?.image}`}
                                  alt=""
                                  width="120"
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(item?.image)}
                                  alt="Live Preview"
                                  width="120"
                                />
                              </>
                            )}

                            {/* {imagePreviewUrl ? (<>
                                                            <img src={URL.createObjectURL(item?.image)} alt="Live Preview" width="120" />
                                                        </>) : (<>
                                                        </>)} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row mb-2">
                          <div className="col-3">
                            <label>

                              {lan?.name}<span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <input
                              required
                              type="text"
                              onChange={(e) => handleFamilyDetailsChange(e, i)}
                              value={
                                item?.full_name == "null" ? "" : item?.full_name
                              }
                              name="full_name"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-3">
                            <label>
                              {lan?.nationality} <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <Select
                              required
                              className="basic-single"
                              name="nationlity"
                              styles={colourStyles}
                              onChange={(e) => handleNationlityChange(e, i)}
                              options={nationlity}
                              value={nationlity.find(
                                (e) => e.id == item?.nationlity
                              )}
                              getOptionLabel={(option) => option.name}
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-3">
                            <label> {lan?.emergency}</label>
                          </div>
                          <div className="col-9">
                            <input
                              type="tel"
                              value={
                                item?.emergency == "null" ? "" : item?.emergency
                              }
                              onChange={(e) => handleFamilyDetailsChange(e, i)}
                              name="emergency"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-3">
                            <label> {lan?.address}</label>
                          </div>
                          <div className="col-9">
                            <textarea
                              type="text"
                              value={
                                item?.address == "null" ? "" : item?.address
                              }
                              onChange={(e) => handleFamilyDetailsChange(e, i)}
                              name="address"
                              className="form-control form-control-sm"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex justify-content-end">
                          {familyMember.length - 1 === i && (
                            <button
                              onClick={handleAddFamilyDetails}
                              className="btn btn-sm btn-primary me-3 mt-3"
                            >
                              {lan?.add}
                            </button>
                          )}
                          {familyMember.length !== 1 &&
                            (item.id ? (
                              <button
                                onClick={() => deleteFamily(item.id)}
                                type="button"
                                className="btn btn-sm btn-primary me-3 mt-3"
                              >
                                {lan?.remove}
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  handleRemoveFamilyDetails(item.u_id, i)
                                }
                                className="btn btn-sm btn-primary me-3 mt-3"
                              >
                                {lan?.remove}
                              </button>
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="col-12">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => navigate("/owners")}
                      className="btn btn-sm btn-primary me-1 mt-2"
                    >
                      {lan?.back}
                    </button>

                    <button
                      type="button"
                      onClick={() => handleNextPage("pills-home-tab")}
                      className="btn btn-sm btn-primary me-1 mt-2"
                    >
                      {lan?.previous}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleNextPage("pills-profile-tab")}
                      className="btn btn-sm btn-primary me-1 mt-2"
                    >
                      {lan?.next}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-sm btn-success mt-2"
                    >
                      {lan?.update}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="row mb-2">
                <div className="col-md-12">
                  <div className="row mb-2 text-align-left">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-3">
                          <label> {lan?.ownerName}</label>
                        </div>
                        <div className="col-9">: {personalInfo?.name}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      {walletObj?.verify ? (
                        <>
                          <div className="row mb-2">
                            <div className="col-3">
                              <label> {lan?.walletNo}</label>
                            </div>
                            <div className="col-9">
                              <p>
                                : {walletObj?.finalNumb && walletObj?.finalNumb}

                                <span className="ms-2">

                                  <svg
                                    fill="#0000FF"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="16"
                                    width="16"
                                    viewBox="0 0 512 512"

                                  >
                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                  </svg>
                                </span>
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row mb-2">
                            <div className="col-3">
                              <label> {lan?.walletNo}</label>
                            </div>
                            <div className="col-5">

                              <input
                                type="number"
                                onChange={(e) => setwalletObj({ ...walletObj, num: e.target.value })}
                                value={walletObj?.num}
                                name="wallet_no"
                                className="form-control form-control-sm"
                                placeholder="01XX-XXXXX-XX"
                                required
                                disabled={false}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    sendOTP()
                                  }
                                }}
                              />
                              {walletObj?.error && <p style={{ color: "red" }}> {walletObj?.error}</p>}
                              <div className="d-flex mt-1">
                                <p className="me-2"> {lan?.verifyYourWalletNo}.</p>
                                <span
                                  class="badge text-bg-secondary bg-success cursor-pointer"
                                  type="submit"
                                  onClick={
                                    sendOTP
                                  }
                                >

                                  {lan?.sendOtp}
                                </span>
                              </div>

                            </div>
                            <div className="col-md-4">
                              {!walletObj.verify && <button
                                disabled
                                className="btn btn-sm btn-primary me-3 px-3"
                                style={{ margin: "0" }}
                              >
                                {lan?.notVerified}
                              </button>}
                            </div>
                          </div>
                          {walletObj.sendOTP && (
                            <div className="row mb-2">
                              <div className="col-3">
                              </div>
                              <div className="col-5">
                                <input
                                  type="number"
                                  onChange={(e) => setwalletObj({ ...walletObj, otp: e.target.value })}
                                  value={walletObj?.otp}
                                  name="otp_num"
                                  className="form-control form-control-sm"
                                  required
                                  placeholder="Verfiy your OTP"
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      handleOtpVerification()
                                    }
                                  }}
                                />
                                <span className="text-success">
                                  {lan?.verificationSent}
                                </span>
                              </div>
                              <div className="col-md-3">
                                <button
                                  type="button"
                                  onClick={handleOtpVerification}
                                  className="btn btn-sm btn-primary me-3"
                                  style={{ margin: "0" }}
                                >
                                  {lan?.submit}
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="row mb-2">
                        <div className="col-3">
                          <label> {lan?.activationDate}</label>
                        </div>
                        <div className="col-9">
                          <p>
                            : {walletObj?.activationDate && moment(walletObj?.activationDate).format(dateFormat)}


                          </p>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex justify-content-end">
                          <button
                            onClick={() => handleNextPage("pills-contact-tab")}
                            className="btn btn-sm btn-primary me-3 mt-3"
                          >
                            {lan?.previous}
                          </button>
                          <button className="btn btn-sm btn-success me-3 mt-3">
                            {lan?.update}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="col-md-12">
                  <div className="mb-4">
                    <div className="row mt-3">
                      <h6 className="mb-3 text-center">{lan?.eWalletHistory}</h6>
                    </div>
                    <div className="unit-manager-table invoice-item-table mb-3">
                      <div
                        className="d-flex gap-3 mb-1 text-uppercase"
                        style={{ fontWeight: "500" }}
                      >
                        <p style={{ color: "#035E7B" }}>
                          {lan?.totalDeposit} :
                          {currencyFormat(totalDeposit ? totalDeposit : 0)}
                        </p>
                        <p style={{ color: "red" }}>
                          {lan?.totalWithdraw} :
                          {currencyFormat(totalPayment ? totalPayment : 0)}
                        </p>
                        <p style={{ color: "green" }}>
                          {lan?.totalBalance} :
                          {currencyFormat(
                            personalInfo?.e_wallet?.balance
                              ? personalInfo?.e_wallet?.balance
                              : 0
                          )}
                        </p>
                      </div>
                      <table className="">
                        <tr>
                          <td>{lan?.date}</td>
                          <td>{lan?.transactionNo}</td>
                          <td>{lan?.receivedBy}</td>
                          <td>{lan?.depositWithdraw}</td>
                          <td>{lan?.amount}</td>
                        </tr>
                        {personalInfo?.e_wallet?.history?.map((v1, i1) => {
                          return (
                            <tr>
                              <td>
                                {moment(v1?.created_at).format(dateFormat)}
                              </td>
                              <td>{v1?.trun_id}</td>
                              <td>{v1?.recived_by}</td>
                              <td>{v1?.deposit_or_withdraw}</td>
                              <td>{v1?.amount}</td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
