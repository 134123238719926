const english = {
    billingStatement: "Billing Statement",
    property: "Property",
    building: "Building",
    floor: "Floor",
    unit: "Unit",
    billingMonth: "Billing Month",
    invoice: "Invoice",
    date: "Date",
    flat: "Flat",
    owner: "Owner",
    tenant: "Tenant",
    mrnSlip: "MRN/Slip",
    billAmount: "Bill Amount",
    received: "Received",
    dueBalance: "Due Balance",
    totalBillAmount: "Total Bill Amount",
    totalReceivedAmount: "Total Received Amount",
    totalDueAmount: "Total Due Amount",
    refresh: "Refresh",
    print: "Print",
    total: "Total",
    pleaseSelectTheInvoice: "Please select the invoice",
    due: "Due",
    to: "To",
    recordsAreNotAvailable: "Records are not available",
}
const malay = {
    billingStatement: "Penyata Bil",
    property: "Harta",
    building: "Bangunan",
    floor: "Tingkat",
    unit: "Unit",
    billingMonth: "Bulan Penagihan",
    invoice: "Invois",
    date: "Tarikh",
    flat: "Pangsapuri",
    owner: "Pemilik",
    tenant: "Penyewa",
    mrnSlip: "MRN/Slip",
    billAmount: "Jumlah Bil",
    received: "Diterima",
    dueBalance: "Baki Tertunggak",
    totalBillAmount: "Jumlah Bil Keseluruhan",
    totalReceivedAmount: "Jumlah Diterima Keseluruhan",
    totalDueAmount: "Jumlah Tertunggak Keseluruhan",
    refresh: "Segar Semula",
    print: "Cetak",
    total: "Jumlah",
    pleaseSelectTheInvoice: "Sila pilih invois",
    due: "Tertunggak",
    to: "Kepada", 
    recordsAreNotAvailable: "Rekod tidak tersedia",
  }
  const thai = {
    billingStatement: "ใบแจ้งหนี้",
    property: "ทรัพย์สิน",
    building: "อาคาร",
    floor: "ชั้น",
    unit: "หน่วย",
    billingMonth: "เดือนที่เรียกเก็บเงิน",
    invoice: "ใบแจ้งหนี้",
    date: "วันที่",
    flat: "อพาร์ตเมนต์",
    owner: "เจ้าของ",
    tenant: "ผู้เช่า",
    mrnSlip: "MRN/ใบเสร็จ",
    billAmount: "จำนวนเงินที่เรียกเก็บ",
    received: "รับแล้ว",
    dueBalance: "ยอดคงเหลือที่ค้างชำระ",
    totalBillAmount: "ยอดรวมใบแจ้งหนี้",
    totalReceivedAmount: "ยอดรวมที่ได้รับ",
    totalDueAmount: "ยอดรวมที่ค้างชำระ",
    refresh: "รีเฟรช",
    print: "พิมพ์",
    total: "รวมทั้งหมด",
    pleaseSelectTheInvoice: "กรุณาเลือกใบแจ้งหนี้",
    due: "ค้างชำระ",
    to: "ถึง",
    recordsAreNotAvailable: "ไม่พบข้อมูล",
  }
  const arabic = {
    billingStatement: "بيان الفاتورة",
    property: "الملكية",
    building: "البناية",
    floor: "الطابق",
    unit: "الوحدة",
    billingMonth: "شهر الفوترة",
    invoice: "الفاتورة",
    date: "التاريخ",
    flat: "الشقة",
    owner: "المالك",
    tenant: "المستأجر",
    mrnSlip: "MRN/الإيصال",
    billAmount: "مبلغ الفاتورة",
    received: "تم الاستلام",
    dueBalance: "الرصيد المستحق",
    totalBillAmount: "إجمالي مبلغ الفاتورة",
    totalReceivedAmount: "إجمالي المبلغ المستلم",
    totalDueAmount: "إجمالي المبلغ المستحق",
    refresh: "تحديث",
    print: "طباعة",
    total: "الإجمالي",
    pleaseSelectTheInvoice: "الرجاء تحديد الفاتورة",
    due: "مستحق",
    to: "إلى",
    recordsAreNotAvailable: "السجلات غير متوفرة",
  }
  const bengali = {
    billingStatement: "বিল বিবৃতি",
    property: "সম্পত্তি",
    building: "ভবন",
    floor: "মেঝে",
    unit: "ইউনিট",
    billingMonth: "বিলিং মাস",
    invoice: "চালান",
    date: "তারিখ",
    flat: "ফ্ল্যাট",
    owner: "মালিক",
    tenant: "ভাড়াটে",
    mrnSlip: "MRN/রশিদ",
    billAmount: "বিলের পরিমাণ",
    received: "গ্রহণ করা হয়েছে",
    dueBalance: "বকেয়া ব্যালান্স",
    totalBillAmount: "মোট বিলের পরিমাণ",
    totalReceivedAmount: "মোট গ্রহণকৃত পরিমাণ",
    totalDueAmount: "মোট বকেয়া পরিমাণ",
    refresh: "রিফ্রেশ",
    print: "মুদ্রণ",
    total: "মোট",
    pleaseSelectTheInvoice: "অনুগ্রহ করে বিল নির্বাচন করুন",
    due: "বাকি",
    to: "থেকে",
    recordsAreNotAvailable: "কোন রেকর্ড পাওয়া যায় নি",
  }
        
export const billingStatementLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}