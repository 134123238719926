const english = {
  userRolesPermissionAndAccess: "User Roles, Permission and Access",
  moduleUser: "Module User",
  all: "All",
  owners: "Owners",
  tenant: "Tenant",
  employee: "Employee",
  role: "Role",
  allPermission: "All Permission",
  permissionText: "Select the role for each feature",
  save: "Save",
  cancel: "Cancel",
  adminSetup: "Admin Setup",
  adminPanel: "Admin Panel",
  dashboard: "Dashboard",
  manageProperties: "Manage Properties",
  manageOwnerTenant: "Manage Owner/Tenant",
  feeSetup: "Fee Setup",
  employeeManagement: "Employee Management",
  visitorManagement: "Visitor Management",
  movementManagement: "Movement Management",
  billing: "Billing",
  finance: "Finance",
  rolePermissions: "Role & Permissions",
  rentSetup: "Rent Setup",
  familyMemberType: "Family Member Type",
  genderType: "Gender Type",
  serviceGroup: "Service Group",
  fees: "Fees",
  serviceUnit: "Service Unit",
  serviceType: "Service Type",
  notice: "Notice",
  helpLine: "Help Line",
  buildingSetup: "Building Setup",
  ownershipType: "Ownership Type",
  propertyType: "Property Type",
  designation: "Designation",
  nationality: "Nationality",
  bloodGroup: "Blood Group",
  division: "Division",
  district: "District",
  area: "Area",
  policeStations: "Police Stations",
  unitMeasurement: "Unit Measurement",
  billingManagement: "Billing Management",
  billingStatement: "Billing Statement",
  sassBilling: "Sass Billing",
  properties: "Properties",
  ownersTenants: "Owners/Tenants",
  visitors: "Visitors",
  movements: "Movements",
  financeManagement: "Finance Management",
  balanceStatement: "Balance Statement",
  permissions: "Permissions",
  warning: "Warning",
  billingSetup: "Billing Setup",
};
const malay = {
  userRolesPermissionAndAccess: "Peranan Pengguna, Kebenaran dan Akses",
  moduleUser: "Modul Pengguna",
  all: "Semua",
  owners: "Pemilik",
  tenant: "Penyewa",
  employee: "Pekerja",
  role: "Peranan",
  allPermission: "Semua Kebenaran",
  permissionText: "Pilih peranan untuk setiap ciri",
  save: "Simpan",
  cancel: "Batal",
  adminSetup: "Tetapan Admin",
  adminPanel: "Panel Admin",
  dashboard: "Papan Pemuka",
  manageProperties: "Urus Hartanah",
  manageOwnerTenant: "Urus Pemilik/Penyewa",
  feeSetup: "Tetapan Yuran",
  employeeManagement: "Pengurusan Pekerja",
  visitorManagement: "Pengurusan Pelawat",
  movementManagement: "Pengurusan Pergerakan",
  billing: "Penagihan",
  finance: "Kewangan",
  rolePermissions: "Peranan & Kebenaran",
  rentSetup: "Tetapan Sewa",
  familyMemberType: "Jenis Ahli Keluarga",
  genderType: "Jenis Jantina",
  serviceGroup: "Kumpulan Perkhidmatan",
  fees: "Yuran",
  serviceUnit: "Unit Perkhidmatan",
  serviceType: "Jenis Perkhidmatan",
  notice: "Notis",
  helpLine: "Talian Bantuan",
  buildingSetup: "Tetapan Bangunan",
  ownershipType: "Jenis Pemilikan",
  propertyType: "Jenis Hartanah",
  designation: "Jawatan",
  nationality: "Kewarganegaraan",
  bloodGroup: "Kumpulan Darah",
  division: "Bahagian",
  district: "Daerah",
  area: "Kawasan",
  policeStations: "Balai Polis",
  unitMeasurement: "Unit Pengukuran",
  billingManagement: "Pengurusan Penagihan",
  billingStatement: "Penyata Penagihan",
  sassBilling: "Penagihan SaaS",
  properties: "Hartanah",
  ownersTenants: "Pemilik/Penyewa",
  visitors: "Pelawat",
  movements: "Pergerakan",
  financeManagement: "Pengurusan Kewangan",
  balanceStatement: "Penyata Baki",
  permissions: "Kebenaran",
  warning: "Amaran",
  billingSetup: "Tetapan Penagihan",
};
const thai = {
  userRolesPermissionAndAccess: "บทบาทผู้ใช้ การอนุญาตและการเข้าถึง",
  moduleUser: "โมดูลผู้ใช้",
  all: "ทั้งหมด",
  owners: "เจ้าของ",
  tenant: "ผู้เช่า",
  employee: "พนักงาน",
  role: "บทบาท",
  allPermission: "การอนุญาตทั้งหมด",
  permissionText: "เลือกบทบาทสำหรับแต่ละคุณสมบัติ",
  save: "บันทึก",
  cancel: "ยกเลิก",
  adminSetup: "ตั้งค่าแอดมิน",
  adminPanel: "แผงแอดมิน",
  dashboard: "แผงควบคุม",
  manageProperties: "จัดการทรัพย์สิน",
  manageOwnerTenant: "จัดการเจ้าของ/ผู้เช่า",
  feeSetup: "ตั้งค่าค่าธรรมเนียม",
  employeeManagement: "การจัดการพนักงาน",
  visitorManagement: "การจัดการผู้เยี่ยมชม",
  movementManagement: "การจัดการการเคลื่อนไหว",
  billing: "การเรียกเก็บเงิน",
  finance: "การเงิน",
  rolePermissions: "บทบาทและการอนุญาต",
  rentSetup: "ตั้งค่าเช่า",
  familyMemberType: "ประเภทสมาชิกในครอบครัว",
  genderType: "ประเภทเพศ",
  serviceGroup: "กลุ่มบริการ",
  fees: "ค่าธรรมเนียม",
  serviceUnit: "หน่วยบริการ",
  serviceType: "ประเภทบริการ",
  notice: "แจ้งเตือน",
  helpLine: "สายด่วน",
  buildingSetup: "ตั้งค่าอาคาร",
  ownershipType: "ประเภทการเป็นเจ้าของ",
  propertyType: "ประเภททรัพย์สิน",
  designation: "ตำแหน่ง",
  nationality: "สัญชาติ",
  bloodGroup: "กรุ๊ปเลือด",
  division: "หน่วยงาน",
  district: "เขต",
  area: "พื้นที่",
  policeStations: "สถานีตำรวจ",
  unitMeasurement: "หน่วยวัด",
  billingManagement: "การจัดการการเรียกเก็บเงิน",
  billingStatement: "ใบแจ้งหนี้",
  sassBilling: "การเรียกเก็บเงิน SaaS",
  properties: "ทรัพย์สิน",
  ownersTenants: "เจ้าของ/ผู้เช่า",
  visitors: "ผู้เยี่ยมชม",
  movements: "การเคลื่อนไหว",
  financeManagement: "การจัดการการเงิน",
  balanceStatement: "งบดุล",
  permissions: "การอนุญาต",
  warning: "คําเตือน",
  billingSetup: "ตั้งค่าการเรียกเก็บเงิน",
};
const arabic = {
  userRolesPermissionAndAccess: "أدوار المستخدمين، الأذونات والوصول",
  moduleUser: "وحدة المستخدم",
  all: "الكل",
  owners: "المالكون",
  tenant: "المستأجر",
  employee: "الموظف",
  role: "الدور",
  allPermission: "كل الأذونات",
  permissionText: "حدد الدور لكل ميزة",
  save: "حفظ",
  cancel: "إلغاء",
  adminSetup: "إعداد المسؤول",
  adminPanel: "لوحة الإدارة",
  dashboard: "لوحة التحكم",
  manageProperties: "إدارة الممتلكات",
  manageOwnerTenant: "إدارة المالك/المستأجر",
  feeSetup: "إعداد الرسوم",
  employeeManagement: "إدارة الموظفين",
  visitorManagement: "إدارة الزوار",
  movementManagement: "إدارة الحركات",
  billing: "الفواتير",
  finance: "المالية",
  rolePermissions: "الأدوار والأذونات",
  rentSetup: "إعداد الإيجار",
  familyMemberType: "نوع فرد الأسرة",
  genderType: "نوع الجنس",
  serviceGroup: "مجموعة الخدمة",
  fees: "الرسوم",
  serviceUnit: "وحدة الخدمة",
  serviceType: "نوع الخدمة",
  notice: "إشعار",
  helpLine: "خط المساعدة",
  buildingSetup: "إعداد المبنى",
  ownershipType: "نوع الملكية",
  propertyType: "نوع الملكية",
  designation: "التسمية",
  nationality: "الجنسية",
  bloodGroup: "فصيلة الدم",
  division: "القسم",
  district: "المنطقة",
  area: "المنطقة",
  policeStations: "محطات الشرطة",
  unitMeasurement: "وحدة القياس",
  billingManagement: "إدارة الفواتير",
  billingStatement: "كشف الفواتير",
  sassBilling: "فوترة SaaS",
  properties: "الممتلكات",
  ownersTenants: "المالكون/المستأجرون",
  visitors: "الزوار",
  movements: "الحركات",
  financeManagement: "إدارة المالية",
  balanceStatement: "بيان الرصيد",
  permissions: "الأذونات",
  warning: "تحذير",
  billingSetup: "إعداد الفواتير",
};
const bengali = {
  userRolesPermissionAndAccess: "ব্যবহারকারীর ভূমিকা, অনুমতি এবং প্রবেশ",
  moduleUser: "মডিউল ব্যবহারকারী",
  all: "সব",
  owners: "মালিক",
  tenant: "ভাড়াটিয়া",
  employee: "কর্মচারী",
  role: "ভূমিকা",
  allPermission: "সব অনুমতি",
  permissionText: "প্রতিটি বৈশিষ্ট্যের জন্য ভূমিকা নির্বাচন করুন",
  save: "সংরক্ষণ করুন",
  cancel: "বাতিল",
  adminSetup: "অ্যাডমিন সেটআপ",
  adminPanel: "অ্যাডমিন প্যানেল",
  dashboard: "ড্যাশবোর্ড",
  manageProperties: "সম্পত্তি পরিচালনা",
  manageOwnerTenant: "মালিক/ভাড়াটিয়া পরিচালনা",
  feeSetup: "ফি সেটআপ",
  employeeManagement: "কর্মচারী ব্যবস্থাপনা",
  visitorManagement: "পরিদর্শক পরিচালনা",
  movementManagement: "মুভমেন্ট ব্যবস্থাপনা",
  billing: "বিলিং",
  finance: "অর্থনীতি",
  rolePermissions: "ভূমিকা ও অনুমতি",
  rentSetup: "ভাড়া সেটআপ",
  familyMemberType: "পরিবারের সদস্যের ধরন",
  genderType: "লিঙ্গ ধরন",
  serviceGroup: "পরিসেবা গ্রুপ",
  fees: "ফি",
  serviceUnit: "পরিসেবা ইউনিট",
  serviceType: "পরিসেবা ধরন",
  notice: "নোটিশ",
  helpLine: "সহায়তা লাইন",
  buildingSetup: "বিল্ডিং সেটআপ",
  ownershipType: "মালিকানা ধরন",
  propertyType: "সম্পত্তির ধরন",
  designation: "পদবী",
  nationality: "জাতীয়তা",
  bloodGroup: "রক্তের গ্রুপ",
  division: "বিভাগ",
  district: "জেলা",
  area: "এলাকা",
  policeStations: "পুলিশ স্টেশন",
  unitMeasurement: "ইউনিট মাপ",
  billingManagement: "বিলিং ব্যবস্থাপনা",
  billingStatement: "বিলিং বিবৃতি",
  sassBilling: "SaaS বিলিং",
  properties: "সম্পত্তি",
  ownersTenants: "মালিক/ভাড়াটিয়া",
  visitors: "পরিদর্শক",
  movements: "মুভমেন্ট",
  financeManagement: "অর্থনীতি ব্যবস্থাপনা",
  balanceStatement: "ব্যালেন্স বিবৃতি",
  permissions: "অনুমতি",
  roles: "ভূমিকা",
  warning: "সতর্কতা",
  billingSetup: "বিলিং সেটআপ",
};

export const permissionLang = {
  english,
  malay,
  thai,
  arabic,
  bengali,
};
