import React, { useEffect, useState, useRef, } from "react";
import "../../../CSS/BuillingSetup.css";
import http from "../../../http";
import MaterialTable from "material-table";
import "../../../CSS/Table.css";
import { useReactToPrint } from "react-to-print";
import "../Billing/Billing.css";
import dayjs from "dayjs";
import { FaDollarSign } from "react-icons/fa";
import PaymentModal from "../Billing/PaymentModal";
import PaymentHistoryModal from "../Billing/PaymentHistoryModal";
import moment from "moment";
import Setting from "../../Setting/Setting";
import "../../../CSS/Table.css";
import DepositModal from "./DepositModal";
import { GiPayMoney } from "react-icons/gi";
import DatePicker from "react-datepicker";
import CircularProgress from "@mui/material/CircularProgress";
import HistoryPrint from "./HistoryPrint";
import useLanguage from "../../../language/useLanguage";
import { financeManagementLang } from "../../../language/financeManagement/financeManagementLang";

export default function FinanceManagment() {
  const lan = useLanguage(financeManagementLang)
  const { dateFormat } = Setting;
  const [projectWiseFloor, setprojectWiseFloor] = useState();
  const [priviousMonth, setpriviousMonth] = useState();

  const [monthAndYear, setmonthAndYear] = useState({
    month: dayjs().subtract(1, "month").format("MM"),
    year: dayjs().format("YYYY"),
  });

  useEffect(() => {
    let sub = false;
    if (!sub) {
      const dateV = `${dayjs(
        `${dayjs().format(
          `${`${monthAndYear?.year}-${monthAndYear?.month}-01`}`
        )}`
      ).format("MMMM DD, YYYY")} - ${dayjs(
        `${dayjs().format(
          `${`${monthAndYear?.year}-${monthAndYear?.month}-01`}`
        )}`
      )
        .add(1, "month")
        .subtract(1, "day")
        .format("MMMM DD, YYYY")}`;
      setpriviousMonth(dateV);
    }

    return () => {
      sub = true;
    };
  }, [monthAndYear]);

  useEffect(() => {
    let reRender = false;
    if (!reRender) {
      http
        .get("buidling-wise-floor-info")
        .then((res) => {
          setprojectWiseFloor(res.data.data)
        })
        .catch((err) => console.log(err));
    }
    return () => {
      reRender = true;
    };
  }, []);

  const [depositHistory, setdepositHistory] = useState()
  const [paymentHistroy, setpaymentHistroy] = useState()

  const [tableDataUpdate, settableDataUpdate] = useState()

  const [statmetnDate, setstatmetnDate] = useState({
    start: dayjs().subtract(1, "month")?.$d,
    end: dayjs().add(2, "month")?.$d,
  })

  const [isloding, setisloding] = useState(false)
  useEffect(() => {
    let reRender = false;
    if (!reRender) {
      setisloding(true)
      http
        .post("e-wallet-and-payment-history", statmetnDate)
        .then((res) => {
          const dataeW = res.data.data.eW.filter(d => d?.deposit_or_withdraw === "deposit").map((v) => {
            return {
              ...v,
              date: moment(v?.created_at).format(dateFormat),
            }
          })

          setdepositHistory(dataeW)

          const dataPH = res.data.data.pH.map((v) => {
            return {
              ...v,
              date: moment(v?.created_at).format(dateFormat),
              name: v?.bill_entry?.floor?.tenant === null ? v?.bill_entry?.floor?.owner?.name : v?.bill_entry?.floor?.tenant?.name
            }
          })

          setpaymentHistroy(dataPH)
          setisloding(false)
        })
        .catch((err) => setisloding(false));

    }
    return () => {
      reRender = true;
    };
  }, [tableDataUpdate, statmetnDate]);



  const [updateBillEntryData, setupdateBillEntryData] = useState();
  const [billEntryDataForEdit, setbillEntryDataForEdit] = useState();
  const [permisionFroUpdate, setpermisionFroUpdate] = useState(false);
  const [ispaymentModal, setispaymentModal] = useState(false);
  const [paymentHistoryModal, setpaymentHistoryModal] = useState(false);

  const totalDeposit = depositHistory?.reduce(
    (acc, detail) => acc + Number(detail?.amount),
    0
  );
  const totalpayment = paymentHistroy?.reduce(
    (acc, detail) => acc + Number(detail?.amount),
    0
  );

  const statementDepo = useRef();
  const depositPrint = useReactToPrint({
    content: () => statementDepo.current,
  });

  const statementPayment = useRef();
  const paymentPrint = useReactToPrint({
    content: () => statementPayment.current,
  });


  const [depositModalIsOpen, setdepositModalIsOpen] = useState(false);



  return (
    <>
      <div className="page-content adjustment-type-table">
        <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
          <h6>{lan?.financeManagement}</h6>
          <div>

            <button
              style={{ marginTop: "1px" }}
              onClick={() => {
                setispaymentModal(true);
              }}
              className="btn btn-sm btn-primary float-end ms-1"
            >
              <FaDollarSign /> {lan?.payment}
            </button>
            <button
              style={{ marginTop: "1px" }}
              onClick={() => {
                setdepositModalIsOpen(true);
              }}
              className="btn btn-sm btn-primary float-end ms-1"
            >
              <GiPayMoney /> {lan?.deposit}
            </button>
          </div>
        </div>

        {paymentHistoryModal && (
          <PaymentHistoryModal
            paymentHistoryModal={paymentHistoryModal}
            setpaymentHistoryModal={setpaymentHistoryModal}
            bill_entries_id={billEntryDataForEdit?.id}
          />
        )}
        {depositModalIsOpen && (
          <DepositModal
            depositModalIsOpen={depositModalIsOpen}
            setdepositModalIsOpen={setdepositModalIsOpen}
            settableDataUpdate={settableDataUpdate}
          />
        )}

        {ispaymentModal && (
          <PaymentModal
            projectWiseFloor={projectWiseFloor}
            ispaymentModal={ispaymentModal}
            setispaymentModal={setispaymentModal}
            setupdateBillEntryData={setupdateBillEntryData}
            billEntryDataForEdit={billEntryDataForEdit}
            setbillEntryDataForEdit={setbillEntryDataForEdit}
            permisionFroUpdate={permisionFroUpdate}
            setpermisionFroUpdate={setpermisionFroUpdate}
            setpaymentHistoryModal={setpaymentHistoryModal}
            paymentHistoryModal={paymentHistoryModal}
            monthAndYear={monthAndYear}
            settableDataUpdate={settableDataUpdate}
          />
        )}
        <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">

          <ul style={{ display: `${(depositModalIsOpen || ispaymentModal) ? "none" : "flex"}` }} className="nav bulling_tab mb-1" id="pills-tab" role="tablist">
            <li className="nav-item billing_list d-flex" role="presentation">
              <button class="nav-link builling_link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{lan?.depositHistory}</button>
              <span className="builling_item"></span>
              <span className="builling_item_one"></span>
            </li>
            <li className="nav-item billing_list" role="presentation">
              <span className="builling_item"></span>
              <button class={`nav-link builling_link`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">{lan?.paymentHistory}</button>
              <span className="builling_item"></span>
              <span className="builling_item_one"></span>
            </li>
          </ul>
          <div className="d-flex gap-2">
            <DatePicker
              selected={statmetnDate?.start}
              placeholderText="Start Date"
              onChange={(date) => {
                setstatmetnDate({ ...statmetnDate, start: date })
              }}
              selectsStart
              startDate={statmetnDate?.start}
              endDate={statmetnDate?.end}
              dateFormat="dd/MM/yyyy"
              className="custom_date_picker_styles"
            />
            <p className="mt-2"> {lan?.to} </p>
            <DatePicker
              selected={statmetnDate?.end}
              placeholderText="End Date"
              onChange={(date) => {
                setstatmetnDate({ ...statmetnDate, end: date })
              }}
              selectsEnd
              startDate={statmetnDate?.start}
              endDate={statmetnDate?.end}
              minDate={statmetnDate?.start}
              dateFormat="dd/MM/yyyy"
              className="custom_date_picker_styles"
            />


          </div>
        </div>
        <div className="card">
          <div className="">
            {
              isloding ? <div className="d-flex justify-content-center">
                <CircularProgress />
              </div> :
                <div className="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <MaterialTable
                      columns={[
                        { title: lan?.date, field: 'date' },
                        { title: lan?.transactionId, field: 'trun_id' },
                        { title: lan?.name, field: 'wallet.owner.name' },
                        { title: lan?.paymentMethod, field: 'payment_type' },
                        // { title: 'Type', field: 'deposit_or_withdraw' },
                        {
                          title: lan?.amount, field: 'amount', cellStyle: {
                            textAlign: "left"
                          },
                        },

                      ]}
                      data={depositHistory}
                      actions={[

                        {
                          icon: 'add',
                          tooltip: lan?.addDeposit,
                          isFreeAction: true,
                          onClick: (event) => setdepositModalIsOpen(true)
                        },
                        {
                          icon: 'save_alt',
                          tooltip: lan?.printDepositHistory,
                          isFreeAction: true,
                          onClick: (event) => depositPrint()
                        }
                      ]}
                      options={{
                        draggable: true,
                        searchFieldAlignment: "left",
                        showTitle: false,
                        headerStyle: {
                          backgroundColor: '#035E7B',
                          color: '#FFF'
                        }
                      }}
                    />
                  </div>
                  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <MaterialTable
                      columns={[
                        { title: lan?.date, field: 'date' },
                        { title: lan?.invoice, field: 'bill_entry.invoice' },
                        { title: lan?.slipNo, field: 'slip_number' },
                        { title: lan?.name, field: 'name' },
                        { title: lan?.paymentMethod, field: 'payment_method' },
                        {
                          title: lan?.amount, field: 'amount', cellStyle: {
                            textAlign: "left"
                          },
                        },

                      ]}
                      data={paymentHistroy}
                      actions={[

                        {
                          icon: 'add',
                          tooltip: lan?.addDeposit,
                          isFreeAction: true,
                          onClick: (event) => setispaymentModal(true)
                        },
                        {
                          icon: 'save_alt',
                          tooltip: lan?.printDepositHistory,
                          isFreeAction: true,
                          onClick: (event) => paymentPrint()
                        }
                      ]}
                      options={{
                        draggable: true,
                        searchFieldAlignment: "left",
                        showTitle: false,
                        headerStyle: {
                          backgroundColor: '#035E7B',
                          color: '#FFF'
                        }
                      }}
                    />
                  </div>

                </div>}
          </div>
        </div>
      </div>
      <div className="billing-statement-print" ref={statementDepo}>
        <HistoryPrint name={lan?.depositStatement} depositHistory={depositHistory} totalDeposit={totalDeposit} statmetnDate={statmetnDate} />
      </div>

      <div className="billing-statement-print" ref={statementPayment}>
        <HistoryPrint name={lan?.paymentStatement} paymentHistroy={paymentHistroy} totalpayment={totalpayment} statmetnDate={statmetnDate} />
      </div>
    </>
  );
}
