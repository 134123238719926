const english = {
    paymentHistory: "Payment History",
    paymentStatement: "Payment Statement",
    name: "Name",
    email: "Email",
    mobile: "Mobile",
    address: "Address",
    totalAmount: "Total Amount",
    receivedAmount: "Received Amount",
    dueAmount: "Due Amount",
    invoice: "Invoice",
    mrn: "MRN",
    paymentDate: "Payment Date",
    time: "Time",
    paymentMethod: "Payment Method",
    bankCard: "Bank Card",
    number: "Number",
    expiryDate: "Expiry Date",
    amount: "Amount",
    total: "Total",
    paymentReceipt: "PAYMENT RECEIPT",
    paymentAddedSuccessFully: "Payment Added SuccessFully",
    totalAmountOfReceived: "Total Amount Of Received",
    authorizedSignature: "Authorized Signature",
    original: "Original",
    customerCopy: "CUSTOMER COPY",
    notFound : "Records are not available",
}
const malay = {
    paymentHistory: "Sejarah Pembayaran",
    paymentStatement: "Penyata Pembayaran",
    name: "Nama",
    email: "E-mel",
    mobile: "Telefon Bimbit",
    address: "Alamat",
    totalAmount: "Jumlah Keseluruhan",
    receivedAmount: "Jumlah Diterima",
    dueAmount: "Jumlah Tertunggak",
    invoice: "Invois",
    mrn: "MRN",
    paymentDate: "Tarikh Pembayaran",
    time: "Masa",
    paymentMethod: "Kaedah Pembayaran",
    bankCard: "Kad Bank",
    number: "Nombor",
    expiryDate: "Tarikh Luput",
    amount: "Jumlah",
    total: "Jumlah Keseluruhan",
    paymentReceipt: "RESIT PEMBAYARAN",
    paymentAddedSuccessFully: "Pembayaran Ditambah Berjaya",
    totalAmountOfReceived: "Jumlah Keseluruhan Yang Diterima",
    authorizedSignature: "Tandatangan Dibenarkan",
    original: "Asli",
    customerCopy: "SALINAN PELANGGAN",
    notFound : "Rekod tidak tersedia",
}
const thai = {
    paymentHistory: "ประวัติการชำระเงิน",
    paymentStatement: "ใบแจ้งยอดการชำระเงิน",
    name: "ชื่อ",
    email: "อีเมล",
    mobile: "มือถือ",
    address: "ที่อยู่",
    totalAmount: "จำนวนเงินทั้งหมด",
    receivedAmount: "จำนวนเงินที่ได้รับ",
    dueAmount: "จำนวนเงินที่ค้างชำระ",
    invoice: "ใบแจ้งหนี้",
    mrn: "MRN",
    paymentDate: "วันที่ชำระเงิน",
    time: "เวลา",
    paymentMethod: "วิธีการชำระเงิน",
    bankCard: "บัตรธนาคาร",
    number: "หมายเลข",
    expiryDate: "วันหมดอายุ",
    amount: "จำนวน",
    total: "ทั้งหมด",
    paymentReceipt: "ใบเสร็จการชำระเงิน",
    paymentAddedSuccessFully: "เพิ่มการชำระเงินเรียบร้อยแล้ว",
    totalAmountOfReceived: "จำนวนเงินทั้งหมดที่ได้รับ",
    authorizedSignature: "ลายเซ็นที่ได้รับอนุญาต",
    original: "ต้นฉบับ",
    customerCopy: "ไม่พบข้อมูล",

}
const arabic = {
    paymentHistory: "سجل الدفع",
    paymentStatement: "بيان الدفع",
    name: "الاسم",
    email: "البريد الإلكتروني",
    mobile: "الهاتف المحمول",
    address: "العنوان",
    totalAmount: "المبلغ الإجمالي",
    receivedAmount: "المبلغ المستلم",
    dueAmount: "المبلغ المستحق",
    invoice: "فاتورة",
    mrn: "رقم الملف الطبي",
    paymentDate: "تاريخ الدفع",
    time: "الوقت",
    paymentMethod: "طريقة الدفع",
    bankCard: "بطاقة بنكية",
    number: "رقم",
    expiryDate: "تاريخ الانتهاء",
    amount: "المبلغ",
    total: "الإجمالي",
    paymentReceipt: "إيصال الدفع",
    paymentAddedSuccessFully: "تمت إضافة الدفع بنجاح",
    totalAmountOfReceived: "إجمالي المبلغ المستلم",
    authorizedSignature: "التوقيع المعتمد",
    original: "اصلي",
    customerCopy: "نسخة من العميل",
    notFound : "السجلات غير متوفرة",
}
const bengali = {
    paymentHistory: "পেমেন্টের ইতিহাস",
    paymentStatement: "পেমেন্ট বিবৃতি",
    name: "নাম",
    email: "ইমেইল",
    mobile: "মোবাইল",
    address: "ঠিকানা",
    totalAmount: "মোট পরিমাণ",
    receivedAmount: "গৃহীত পরিমাণ",
    dueAmount: "বাকি পরিমাণ",
    invoice: "চালান",
    mrn: "MRN",
    paymentDate: "পেমেন্টের তারিখ",
    time: "সময়",
    paymentMethod: "পেমেন্ট পদ্ধতি",
    bankCard: "ব্যাংক কার্ড",
    number: "নম্বর",
    expiryDate: "মেয়াদ শেষ হওয়ার তারিখ",
    amount: "পরিমাণ",
    total: "মোট",
    paymentReceipt: "পেমেন্ট রসিদ",
    paymentAddedSuccessFully: "পেমেন্ট সফলভাবে যোগ করা হয়েছে",
    totalAmountOfReceived: "গৃহীত মোট পরিমাণ",
    authorizedSignature: "অনুমোদিত স্বাক্ষর",
    original: "আসল",
    customerCopy: "গ্রাহক কপি",
    notFound : "রেকর্ড পাওয়া যায়নি",
}

export const paymentHistoryLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}