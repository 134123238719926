const english = {
    adminPanel: "Admin Panel",
    dashboard: "Dashboard",
    manageProperties: "Manage Properties",
    adminSetup: "Admin Setup",
    manageOwnerTenant: "Manage Owner/Tenant",
    feeSetup: "Fee Setup",
    employeeManagement: "Employee Management",
    visitorManagement: "Visitor Management",
    movementManagement: "Movement Management",
    billing: "Billing",
    finance: "Finance",
    rolePermissions: "Role & Permissions",
    rentSetup: "Rent Setup",
    familyMemberType: "Family Member Type",
    genderType: "Gender Type",
    serviceGroup: "Service Group",
    fees: "Fees",
    serviceUnit: "Service Unit",
    serviceType: "Service Type",
  notice: "Notice",
  helpLine: "Help Line",
  buildingSetup: "Building Setup",
  ownershipType: "Ownership Type",
  propertyType: "Property Type",
  designation: "Designation",
  nationality: "Nationality",
  bloodGroup: "Blood Group",
  division: "Division",
  district: "District",
  area: "Area",
  policeStations: "Police Stations",
  unitMeasurement: "Unit Measurement",
  billingManagement: "Billing Management",
  billingStatement: "Billing Statement",
  sassBilling: "Sass Billing",
  properties : "Properties",
  ownersTenants : "Owners/Tenants",
  visitors: "Visitors",
  movements: "Movements",
  financeManagement: "Finance Management",
  balanceStatement: "Balance Statement",
  role: "Role",
  permissions: "Permissions",
  timezone: "Timezone",
  country: "Country",
  currency: "Currency",
  employees: "Employees",
  settings: "Settings",
}
const malay = {
    adminPanel: "Panel Admin",
    dashboard: "Papan Pemuka",
    manageProperties: "Urus Harta",
    adminSetup: "Persediaan Admin",
    manageOwnerTenant: "Urus Pemilik/Penyewa",
    feeSetup: "Tetapan Yuran",
    employeeManagement: "Pengurusan Pekerja",
    visitorManagement: "Pengurusan Pelawat",
    movementManagement: "Pengurusan Pergerakan",
    billing: "Bil",
    finance: "Kewangan",
    rolePermissions: "Peranan & Kebenaran",
    rentSetup: "Tetapan Sewa",
    familyMemberType: "Jenis Ahli Keluarga",
    genderType: "Jenis Jantina",
    serviceGroup: "Kumpulan Perkhidmatan",
    fees: "Yuran",
    serviceUnit: "Unit Perkhidmatan",
    serviceType: "Jenis Perkhidmatan",
    notice: "Notis",
    helpLine: "Talian Bantuan",
    buildingSetup: "Persediaan Bangunan",
    ownershipType: "Jenis Pemilikan",
    propertyType: "Jenis Harta",
    designation: "Jawatan",
    nationality: "Warganegara",
    bloodGroup: "Kumpulan Darah",
    division: "Bahagian",
    district: "Daerah",
    area: "Kawasan",
    policeStations: "Balai Polis",
    unitMeasurement: "Ukuran Unit",
    billingManagement: "Pengurusan Bil",
    billingStatement: "Penyata Bil",
    sassBilling: "Sass Bil",
    properties: "Harta",
    ownersTenants: "Pemilik/Penyewa",
    visitors: "Pelawat",
    movements: "Pergerakan",
    financeManagement: "Pengurusan Kewangan",
    balanceStatement: "Penyata Baki",
    role: "Peranan",
    permissions: "Kebenaran",
    timezone: "Zon Waktu",
    country: "Negara",
    currency: "Mata Wang",
    employees: "Pekerja",
     settings: "Tetapan",
  }
  const thai = {
    adminPanel: "แผงผู้ดูแลระบบ",
    dashboard: "แดชบอร์ด",
    manageProperties: "จัดการทรัพย์สิน",
    adminSetup: "การตั้งค่าผู้ดูแลระบบ",
    manageOwnerTenant: "จัดการเจ้าของ/ผู้เช่า",
    feeSetup: "การตั้งค่าค่าธรรมเนียม",
    employeeManagement: "การจัดการพนักงาน",
    visitorManagement: "การจัดการผู้เยี่ยมชม",
    movementManagement: "การจัดการการเคลื่อนไหว",
    billing: "การเรียกเก็บเงิน",
    finance: "การเงิน",
    rolePermissions: "บทบาท & สิทธิ์",
    rentSetup: "การตั้งค่าเช่า",
    familyMemberType: "ประเภทสมาชิกครอบครัว",
    genderType: "ประเภทเพศ",
    serviceGroup: "กลุ่มบริการ",
    fees: "ค่าธรรมเนียม",
    serviceUnit: "หน่วยบริการ",
    serviceType: "ประเภทบริการ",
    notice: "ประกาศ",
    helpLine: "สายด่วน",
    buildingSetup: "การตั้งค่าอาคาร",
    ownershipType: "ประเภทกรรมสิทธิ์",
    propertyType: "ประเภททรัพย์สิน",
    designation: "การกำหนด",
    nationality: "สัญชาติ",
    bloodGroup: "กรุ๊ปเลือด",
    division: "แผนก",
    district: "อำเภอ",
    area: "พื้นที่",
    policeStations: "สถานีตำรวจ",
    unitMeasurement: "การวัดหน่วย",
    billingManagement: "การจัดการการเรียกเก็บเงิน",
    billingStatement: "ใบแจ้งหนี้",
    sassBilling: "การเรียกเก็บเงินแบบ Sass",
    properties: "ทรัพย์สิน",
    ownersTenants: "เจ้าของ/ผู้เช่า",
    visitors: "ผู้เยี่ยมชม",
    movements: "การเคลื่อนไหว",
    financeManagement: "การจัดการทางการเงิน",
    balanceStatement: "ใบแจ้งยอดคงเหลือ",
    role: "บทบาท",
    permissions: "สิทธิ์",
    timezone: "เขตเวลา",
    country: "ประเทศ",
    currency: "สกุลเงิน",
    employees: "พนักงาน",
    settings: "การตั้งค่า",
  }
  const arabic = {
    adminPanel: "لوحة الإدارة",
    dashboard: "لوحة القيادة",
    manageProperties: "إدارة الممتلكات",
    adminSetup: "إعدادات الإدارة",
    manageOwnerTenant: "إدارة المالك/المستأجر",
    feeSetup: "إعدادات الرسوم",
    employeeManagement: "إدارة الموظفين",
    visitorManagement: "إدارة الزوار",
    movementManagement: "إدارة الحركة",
    billing: "الفوترة",
    finance: "المالية",
    rolePermissions: "الأدوار والصلاحيات",
    rentSetup: "إعدادات الإيجار",
    familyMemberType: "نوع عضو الأسرة",
    genderType: "نوع الجنس",
    serviceGroup: "مجموعة الخدمات",
    fees: "الرسوم",
    serviceUnit: "وحدة الخدمة",
    serviceType: "نوع الخدمة",
    notice: "إشعار",
    helpLine: "خط المساعدة",
    buildingSetup: "إعدادات المبنى",
    ownershipType: "نوع الملكية",
    propertyType: "نوع العقار",
    designation: "التعيين",
    nationality: "الجنسية",
    bloodGroup: "فصيلة الدم",
    division: "القسم",
    district: "المنطقة",
    area: "المنطقة",
    policeStations: "مراكز الشرطة",
    unitMeasurement: "قياس الوحدات",
    billingManagement: "إدارة الفواتير",
    billingStatement: "كشف الفواتير",
    sassBilling: "الفوترة ساس",
    properties: "الممتلكات",
    ownersTenants: "المالكين/المستأجرين",
    visitors: "الزوار",
    movements: "الحركات",
    financeManagement: "إدارة المالية",
    balanceStatement: "كشف الرصيد",
    role: "الدور",
    permissions: "الصلاحيات",
    timezone: "المنطقة الزمنية",
    country: "البلد",
    currency: "العملة",
    employees: "الموظفون",
    settings: "الإعدادات",
  }
  const bengali = {
    adminPanel: "অ্যাডমিন প্যানেল",
    dashboard: "ড্যাশবোর্ড",
    manageProperties: "সম্পত্তি পরিচালনা",
    adminSetup: "অ্যাডমিন সেটআপ",
    manageOwnerTenant: "মালিক/ভাড়াটিয়া পরিচালনা",
    feeSetup: "ফি সেটআপ",
    employeeManagement: "কর্মচারী ব্যবস্থাপনা",
    visitorManagement: "পরিদর্শক ব্যবস্থাপনা",
    movementManagement: "চলাচল  ব্যবস্থাপনা",
    billing: "বিলিং",
    finance: "অর্থনীতি",
    rolePermissions: "ভূমিকা ও অনুমতি",
    rentSetup: "ভাড়া সেটআপ",
    familyMemberType: "পরিবারের সদস্যের ধরন",
    genderType: "লিঙ্গের ধরন",
    serviceGroup: "সেবা গ্রুপ",
    fees: "ফি",
    serviceUnit: "সেবা ইউনিট",
    serviceType: "সেবার ধরন",
    notice: "নোটিশ",
    helpLine: "হেল্প লাইন",
    buildingSetup: "বিল্ডিং সেটআপ",
    ownershipType: "মালিকানার ধরন",
    propertyType: "সম্পত্তির ধরন",
    designation: "উপাধি",
    nationality: "জাতীয়তা",
    bloodGroup: "রক্তের গ্রুপ",
    division: "বিভাগ",
    district: "জেলা",
    area: "এলাকা",
    policeStations: "পুলিশ স্টেশন",
    unitMeasurement: "ইউনিট পরিমাপ",
    billingManagement: "বিলিং ব্যবস্থাপনা",
    billingStatement: "বিলিং বিবৃতি",
    sassBilling: "সাস বিলিং",
    properties: "সম্পত্তি",
    ownersTenants: "মালিক/ভাড়াটিয়া",
    visitors: "পরিদর্শক",
    movements: "চলাচল ",
    financeManagement: "অর্থ ব্যবস্থাপনা",
    balanceStatement: "ব্যালেন্স বিবৃতি",
    role: "ভূমিকা",
    permissions: "অনুমতি",
    timezone: "সময় অঞ্চল",
    country: "দেশ",
    currency: "মুদ্রা",
    employees: "কর্মচারী",
    settings: "সেটিংস"
  }
        
export const sidebarLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
  };
  