const english = {
    billingSummary: "Billing Summary",
    all: "All",
    owner: "Owner",
    tenant: "Tenant",
    currentDue: "Current Due",
    totalBill: "Total Bill",
    totalPaid: "Total Paid",
    statement: "Statement",
    phone: "Phone",
    apartment: "Apartment",
    due: "Due",
    max: "Max",
    low: "Low",
    clear: "Clear",
    recordsAreNotAvailable: "Records are not available",
    property: "Property",
    building: "Building",
    floor: "Floor",
    unit: "Unit",
    billingMonth: "Billing Month",
    invoice: "Invoice",
    date: "Date",
    flat: "Flat",
    mrnSlip: "MRN/Slip",
    billAmount: "Bill Amount",
    received: "Received",
    dueBalance: "Due Balance",
    totalBillAmount: "Total Bill Amount",
    totalReceivedAmount: "Total Received Amount",
    totalDueAmount: "Total Due Amount",
    print: "Print",
    total: "Total",
    underConstruction: "Under Construction",
    personalStatement: "Personal Statement",
    ledgerStatement: "Ledger Statement",
    balanceReport: "Balance Report",
    profitLoss: "Profit/Loss",
    cost: "Cost of transportation is insurable",
    sms: "SMS",
    email: "Email",

}
const malay = {
    billingSummary: "Ringkasan Bil",
    all: "Semua",
    owner: "Pemilik",
    tenant: "Penyewa",
    currentDue: "Tertunggak Semasa",
    totalBill: "Jumlah Bil",
    totalPaid: "Jumlah Dibayar",
    statement: "Penyata",
    phone: "Telefon",
    apartment: "Pangsapuri",
    due: "Tertunggak",
    max: "Maks",
    low: "Rendah",
    clear: "Bersihkan",
    recordsAreNotAvailable: "Rekod tidak tersedia",
    property: "Harta",
    building: "Bangunan",
    floor: "Tingkat",
    unit: "Unit",
    billingMonth: "Bulan Penagihan",
    invoice: "Invois",
    date: "Tarikh",
    flat: "Pangsapuri",
    mrnSlip: "MRN/Slip",
    billAmount: "Jumlah Bil",
    received: "Diterima",
    dueBalance: "Baki Tertunggak",
    totalBillAmount: "Jumlah Bil Keseluruhan",
    totalReceivedAmount: "Jumlah Diterima Keseluruhan",
    totalDueAmount: "Jumlah Tertunggak Keseluruhan",
    print: "Cetak",
    total: "Jumlah",
    underConstruction: "Dalam Pembinaan",
    personalStatement: "Penyata Peribadi",
    ledgerStatement: "Penyata Buku Tunai",
    balanceReport: "Laporan Baki",
    profitLoss: "Keuntungan/Kerugian",
    cost: "Kos pengangkutan boleh diinsuranskan",
    sms: "SMS",
    email: "E-mel",
  }
  const thai = {
    billingSummary: "สรุปใบแจ้งหนี้",
    all: "ทั้งหมด",
    owner: "เจ้าของ",
    tenant: "ผู้เช่า",
    currentDue: "จำนวนที่ค้างชำระปัจจุบัน",
    totalBill: "ยอดรวมใบแจ้งหนี้",
    totalPaid: "ยอดรวมที่ชำระ",
    statement: "ใบแจ้งหนี้",
    phone: "โทรศัพท์",
    apartment: "อพาร์ตเมนต์",
    due: "ค้างชำระ",
    max: "สูงสุด",
    low: "ต่ำ",
    clear: "ล้าง",
    recordsAreNotAvailable: "ไม่มีบันทึก",
    property: "ทรัพย์สิน",
    building: "อาคาร",
    floor: "ชั้น",
    unit: "หน่วย",
    billingMonth: "เดือนที่เรียกเก็บเงิน",
    invoice: "ใบแจ้งหนี้",
    date: "วันที่",
    flat: "อพาร์ตเมนต์",
    mrnSlip: "MRN/ใบเสร็จ",
    billAmount: "จำนวนเงินที่เรียกเก็บ",
    received: "รับแล้ว",
    dueBalance: "ยอดคงเหลือที่ค้างชำระ",
    totalBillAmount: "ยอดรวมใบแจ้งหนี้",
    totalReceivedAmount: "ยอดรวมที่ได้รับ",
    totalDueAmount: "ยอดรวมที่ค้างชำระ",
    print: "พิมพ์",
    total: "รวมทั้งหมด",
    underConstruction: "อยู่ระหว่างการก่อสร้าง",
    personalStatement: "ใบแจ้งหนี้ส่วนบุคคล",
    ledgerStatement: "ใบแจ้งหนี้บัญชี",
    balanceReport: "รายงานยอดคงเหลือ",
    profitLoss: "กำไร/ขาดทุน",
    cost: "تكلفة النقل قابلة للتأمين",
    sms: "رسالة نصية",
    email: "البريد الإلكتروني",
  }
  const arabic = {
    billingSummary: "ملخص الفاتورة",
    all: "الجميع",
    owner: "المالك",
    tenant: "المستأجر",
    currentDue: "المستحق الحالي",
    totalBill: "إجمالي الفاتورة",
    totalPaid: "إجمالي المدفوع",
    statement: "البيان",
    phone: "الهاتف",
    apartment: "الشقة",
    due: "المستحق",
    max: "أقصى",
    low: "منخفض",
    clear: "مسح",
    recordsAreNotAvailable: "السجلات غير متاحة",
    property: "الملكية",
    building: "البناية",
    floor: "الطابق",
    unit: "الوحدة",
    billingMonth: "شهر الفوترة",
    invoice: "الفاتورة",
    date: "التاريخ",
    flat: "الشقة",
    mrnSlip: "MRN/الإيصال",
    billAmount: "مبلغ الفاتورة",
    received: "تم الاستلام",
    dueBalance: "الرصيد المستحق",
    totalBillAmount: "إجمالي مبلغ الفاتورة",
    totalReceivedAmount: "إجمالي المبلغ المستلم",
    totalDueAmount: "إجمالي المبلغ المستحق",
    print: "طباعة",
    total: "الإجمالي",
    underConstruction: "تحت الإنشاء",
    personalStatement: "البيان الشخصي",
    ledgerStatement: "بيان الحساب",
    balanceReport: "تقرير الرصيد",
    profitLoss: "الربح/الخسارة",
    cost: "تكلفة النقل قابلة للتأمين",
    sms: "رسالة نصية",
    email: "البريد الإلكتروني",
  }
  const bengali = {
    billingSummary: "বিলের সারাংশ",
    all: "সব",
    owner: "মালিক",
    tenant: "ভাড়াটিয়া",
    currentDue: "বর্তমান বকেয়া",
    totalBill: "মোট বিল",
    totalPaid: "মোট পরিশোধিত",
    statement: "বিবৃতি",
    phone: "ফোন",
    apartment: "ফ্ল্যাট",
    due: "বকেয়া",
    max: "সর্বোচ্চ",
    low: "নিম্ন",
    clear: "পরিষ্কার করুন",
    recordsAreNotAvailable: "রেকর্ড উপলব্ধ নয়",
    property: "সম্পত্তি",
    building: "ভবন",
    floor: "মেঝে",
    unit: "ইউনিট",
    billingMonth: "বিলিং মাস",
    invoice: "চালান",
    date: "তারিখ",
    flat: "ফ্ল্যাট",
    mrnSlip: "MRN/রশিদ",
    billAmount: "বিলের পরিমাণ",
    received: "গ্রহণ করা হয়েছে",
    dueBalance: "বকেয়া ব্যালান্স",
    totalBillAmount: "মোট বিলের পরিমাণ",
    totalReceivedAmount: "মোট গ্রহণকৃত পরিমাণ",
    totalDueAmount: "মোট বকেয়া পরিমাণ",
    print: "মুদ্রণ",
    total: "মোট",
    underConstruction: "নির্মাণাধীন",
    personalStatement: "ব্যক্তিগত বিবৃতি",
    ledgerStatement: "লেজার বিবৃতি",
    balanceReport: "ব্যালান্স রিপোর্ট",
    profitLoss: "লাভ/ক্ষতি",
    cost: "পরিবহনের খরচ বীমাযোগ্য",
    sms: "এসএমএস",
    email: "ইমেইল",
  }
        
export const billingSummaryLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}