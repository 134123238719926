const english = {
    fastEasy: "Fast & Easy",
    customerCopy: "Customer Copy",
    apartmentStatement: "Apartment Statement",
    payOnline: "Pay Online",
    help: "For help with billing question please call ",
    billingMonth: "Billing Month",
    grandTotal: "Grand Total",
    dueAmount: "Due Amount",
    paidAmount: "Paid Amount",
    pleasePay: "Please pay your bills by the due date.",
    accountNumber: "Account Number",
    accountName: "Account Name",
    statementDate: "Statement Date",
    dueDate: "Due Date",
    date: "Date",
    serviceDescription: "Service Description",
    unit: "Unit",
    perUnitFee: "Per Unit Fee",
    um: "UM",
    total: "Total",
    totalStatementBalance: "Total Statement Balance",
    minimumAmountOfDue: "Minimum Amount of Due",
    yourSecureOnlineHealthConnection: "Your Secure Online Health Connection",
    viewAndPay: "View and pay your bill online",
    goPaper: "Go paperless, sign up for eStatement",
    pleaseVisit: "Please visit",
    register: "to register",
    message: "Message",
    setup: "To set up a payment plan, please call us at",
    or: "or you can pay online at",
    amountDue: "Amount Due",
    detach : "Please detach and return top pertion with payment",
    draftCopy: "Draft Copy",

}
const malay = {
    fastEasy: "Cepat & Mudah",
    customerCopy: "Salinan Pelanggan",
    apartmentStatement: "Penyata Pangsapuri",
    payOnline: "Bayar Dalam Talian",
    help: "Untuk bantuan dengan pertanyaan bil, sila hubungi",
    billingMonth: "Bulan Penagihan",
    grandTotal: "Jumlah Keseluruhan",
    dueAmount: "Jumlah Tertunggak",
    paidAmount: "Jumlah Dibayar",
    pleasePay: "Sila bayar bil anda sebelum tarikh akhir.",
    accountNumber: "Nombor Akaun",
    accountName: "Nama Akaun",
    statementDate: "Tarikh Penyata",
    dueDate: "Tarikh Akhir",
    date: "Tarikh",
    serviceDescription: "Keterangan Perkhidmatan",
    unit: "Unit",
    perUnitFee: "Yuran Setiap Unit",
    um: "UM",
    total: "Jumlah",
    totalStatementBalance: "Baki Penyata Keseluruhan",
    minimumAmountOfDue: "Jumlah Minimum Tertunggak",
    yourSecureOnlineHealthConnection: "Sambungan Kesihatan Dalam Talian Anda yang Selamat",
    viewAndPay: "Lihat dan bayar bil anda dalam talian",
    goPaper: "Kurangkan penggunaan kertas, daftar untuk eStatement",
    pleaseVisit: "Sila lawati",
    register: "untuk mendaftar",
    message: "Mesej",
    setup: "Untuk menyediakan pelan pembayaran, sila hubungi kami di",
    or: "atau anda boleh bayar dalam talian di",
    amountDue: "Jumlah Tertunggak",
    detach : "Sila tarik dan kembalikan kejana dengan pembayaran",
    draftCopy: "Salinan Terkini",
  }
  const thai = {
    fastEasy: "รวดเร็ว & ง่าย",
    customerCopy: "สำเนาลูกค้า",
    apartmentStatement: "ใบแจ้งหนี้อพาร์ตเมนต์",
    payOnline: "ชำระเงินออนไลน์",
    help: "สำหรับความช่วยเหลือเกี่ยวกับคำถามเกี่ยวกับการเรียกเก็บเงินโปรดโทรหา",
    billingMonth: "เดือนที่เรียกเก็บเงิน",
    grandTotal: "ยอดรวมทั้งหมด",
    dueAmount: "จำนวนเงินที่ค้างชำระ",
    paidAmount: "จำนวนเงินที่ชำระ",
    pleasePay: "กรุณาชำระเงินภายในวันที่ครบกำหนด",
    accountNumber: "หมายเลขบัญชี",
    accountName: "ชื่อบัญชี",
    statementDate: "วันที่แสดงรายการ",
    dueDate: "วันที่ครบกำหนด",
    date: "วันที่",
    serviceDescription: "รายละเอียดบริการ",
    unit: "หน่วย",
    perUnitFee: "ค่าธรรมเนียมต่อหน่วย",
    um: "UM",
    total: "รวมทั้งหมด",
    totalStatementBalance: "ยอดคงเหลือรวมในใบแจ้งหนี้",
    minimumAmountOfDue: "จำนวนขั้นต่ำที่ค้างชำระ",
    yourSecureOnlineHealthConnection: "การเชื่อมต่อสุขภาพออนไลน์ที่ปลอดภัยของคุณ",
    viewAndPay: "ดูและชำระบิลของคุณออนไลน์",
    goPaper: "ไปที่ระบบไร้กระดาษ ลงทะเบียนสำหรับ eStatement",
    pleaseVisit: "กรุณาเยี่ยมชม",
    register: "เพื่อสมัคร",
    message: "ข้อความ",
    setup: "ในการตั้งค่าการชำระเงินโปรดโทรหาเราได้ที่",
    or: "หรือคุณสามารถชำระเงินออนไลน์ที่",
    amountDue: "จำนวนเงินที่ค้างชำระ",
    detach : "กรุณาถอดและส่งส่วนบนกลับมาพร้อมกับการชำระเงิน",
    draftCopy: "สำเนาล่าสุด",
  }
  const arabic = {
    fastEasy: "سريع وسهل",
    customerCopy: "نسخة العميل",
    apartmentStatement: "بيان الشقة",
    payOnline: "الدفع عبر الإنترنت",
    help: "للحصول على مساعدة بشأن استفسارات الفواتير، يرجى الاتصال بـ",
    billingMonth: "شهر الفوترة",
    grandTotal: "الإجمالي الكلي",
    dueAmount: "المبلغ المستحق",
    paidAmount: "المبلغ المدفوع",
    pleasePay: "يرجى دفع فواتيرك قبل تاريخ الاستحقاق.",
    accountNumber: "رقم الحساب",
    accountName: "اسم الحساب",
    statementDate: "تاريخ البيان",
    dueDate: "تاريخ الاستحقاق",
    date: "تاريخ",
    serviceDescription: "وصف الخدمة",
    unit: "وحدة",
    perUnitFee: "رسوم لكل وحدة",
    um: "UM",
    total: "إجمالي",
    totalStatementBalance: "إجمالي رصيد البيان",
    minimumAmountOfDue: "الحد الأدنى للمبلغ المستحق",
    yourSecureOnlineHealthConnection: "اتصال الصحة عبر الإنترنت الآمن الخاص بك",
    viewAndPay: "عرض ودفع فاتورتك عبر الإنترنت",
    goPaper: "اذهب بدون ورق، اشترك في eStatement",
    pleaseVisit: "يرجى زيارة",
    register: "للتسجيل",
    message: "رسالة",
    setup: "لتنظيم خطة الدفع، يرجى الاتصال بنا على",
    or: "أو يمكنك الدفع عبر الإنترنت على",
    amountDue: "المبلغ المستحق",
    detach:"يرجى فصل وإعادة الجزء العلوي مع الدفع.",
    draftCopy: "نسخة مسودة الحالية",
  }
  const bengali = {
    fastEasy: "দ্রুত & সহজ",
    customerCopy: "গ্রাহক কপি",
    apartmentStatement: "অ্যাপার্টমেন্ট বিবৃতি",
    payOnline: "অনলাইনে পরিশোধ করুন",
    help: "বিল সংক্রান্ত প্রশ্নের সাহায্যের জন্য দয়া করে কল করুন",
    billingMonth: "বিলিং মাস",
    grandTotal: "মোট পরিমাণ",
    dueAmount: "বকেয়া পরিমাণ",
    paidAmount: "পরিশোধিত পরিমাণ",
    pleasePay: "অনুগ্রহ করে আপনার বিল নির্ধারিত তারিখের মধ্যে পরিশোধ করুন।",
    accountNumber: "অ্যাকাউন্ট নম্বর",
    accountName: "অ্যাকাউন্টের নাম",
    statementDate: "বিবৃতি তারিখ",
    dueDate: "নির্দিষ্ট তারিখ",
    date: "তারিখ",
    serviceDescription: "সেবার বর্ণনা",
    unit: "ইউনিট",
    perUnitFee: "প্রতি ইউনিট ফি",
    um: "UM",
    total: "মোট",
    totalStatementBalance: "মোট বিবৃতি ব্যালেন্স",
    minimumAmountOfDue: "বকেয়া পরিমাণের ন্যূনতম পরিমাণ",
    yourSecureOnlineHealthConnection: "আপনার নিরাপদ অনলাইন স্বাস্থ্য সংযোগ",
    viewAndPay: "আপনার বিল অনলাইনে দেখুন এবং পরিশোধ করুন",
    goPaper: "কাগজবিহীন যান, eStatement-এর জন্য সাইন আপ করুন",
    pleaseVisit: "অনুগ্রহ করে ভিজিট করুন",
    register: "নিবন্ধনের জন্য",
    message: "বার্তা",
    setup: "পেমেন্ট পরিকল্পনা সেটআপ করতে, দয়া করে আমাদের কল করুন",
    or: "অথবা আপনি অনলাইনে পরিশোধ করতে পারেন",
    amountDue: "বকেয়া পরিমাণ",
    detach : "অনুগ্রহ করে উপরের অংশটি বিচ্ছিন্ন করে পেমেন্ট সহ ফেরত দিন।",
    draftCopy: "ড্রাফ্ট কপি",
  }
        
export const invoiceLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}