const english = {
    payment: "Payment",
    properties : "Properties",
    building : "Building",
    flatList : "Flat List",
    searchFlat : "Search Flat",
    name : "Name",
    address : "Address",
    mobile: "Mobile",
    billingMonth: "Billing Month",
    dueDate: "Due Date",
    billingDate: "Billing Date",
    paymentRequest: "Payment Request",
    requestText: "A request has been made for you to submit payment",
    invoice: "Invoice",
    requiredPaymentAmount: "Required Payment Amount",
    remark: "Remark",
    depositFor: "Deposit For",
    due: "Due",
    payNow: "Pay Now",
    remarkText: "If you have any question or need help, please contact us.",
    cash: "Cash",
    creditDebit: "Credit/Debit Card",
    digitalPayment: "Digital Payment",
    eWallet: "E-Wallet",
    availableBalance: "Available Balance",
    paymentAddedSuccessFully: "Payment Added SuccessFully",
    paymentReceipt: "PAYMENT RECEIPT",
    original: "ORIGINAL",
    customerCopy: "CUSTOMER COPY",
    email: "Email",
    totalAmount: "Total Amount",
    receivedAmount: "Received Amount",
    dueAmount: "Due Amount",
    mrn: "MRN",
    paymentDate: "Payment Date",
    time: "Time",
    paymentMethod: "Payment Method",
    bankCard: "Bank/Card",
    number: "Number",
    expiryDate: "Expiry Date",
    amount: "Amount",
    totalAmountReceived: "Total Amount Received",
    authorizedSignature: "Authorized Signature",
    pleaseSelectTheApartmentFirst: "Please select the apartment first",
    recordsAreNotAvailable: "Records are not available",
    billWarning: "This apartment is not eligible for billing. Please complete the billing process first",
    total: "Total",
    received: "Received",


}
const malay = {
    payment: "Pembayaran",
    properties: "Hartanah",
    building: "Bangunan",
    flatList: "Senarai Pangsapuri",
    searchFlat: "Cari Pangsapuri",
    name: "Nama",
    address: "Alamat",
    mobile: "Mudah Alih",
    billingMonth: "Bulan Penagihan",
    dueDate: "Tarikh Akhir",
    billingDate: "Tarikh Penagihan",
    paymentRequest: "Permintaan Pembayaran",
    requestText: "Permintaan telah dibuat untuk anda menghantar pembayaran",
    invoice: "Invois",
    requiredPaymentAmount: "Jumlah Pembayaran Diperlukan",
    remark: "Catatan",
    depositFor: "Deposit Untuk",
    due: "Tertunggak",
    payNow: "Bayar Sekarang",
    remarkText: "Jika anda mempunyai sebarang soalan atau memerlukan bantuan, sila hubungi kami.",
    cash: "Tunai",
    creditDebit: "Kad Kredit/Debit",
    digitalPayment: "Pembayaran Digital",
    eWallet: "Dompet Elektronik",
    availableBalance: "Baki Tersedia",
    paymentAddedSuccessFully: "Pembayaran Berjaya Ditambah",
    paymentReceipt: "RESIT PEMBAYARAN",
    original: "ASLI",
    customerCopy: "SALINAN PELANGGAN",
    email: "E-mel",
    totalAmount: "Jumlah Keseluruhan",
    receivedAmount: "Jumlah Diterima",
    dueAmount: "Jumlah Tertunggak",
    mrn: "MRN",
    paymentDate: "Tarikh Pembayaran",
    time: "Masa",
    paymentMethod: "Kaedah Pembayaran",
    bankCard: "Bank/Kad",
    number: "Nombor",
    expiryDate: "Tarikh Luput",
    amount: "Jumlah",
    totalAmountReceived: "Jumlah Keseluruhan Diterima",
    authorizedSignature: "Tandatangan yang Sah",
    pleaseSelectTheApartmentFirst: "Sila pilih pangsapuri terlebih dahulu",
    recordsAreNotAvailable: "Rekod tidak tersedia",
    billWarning: "Pangsapuri ini tidak layak untuk pengebilan. Sila selesaikan proses pengebilan terlebih dahulu.",
    total: "Jumlah",
    received: "Diterima",
  }
  const thai = {
    payment: "การชำระเงิน",
    properties: "อสังหาริมทรัพย์",
    building: "อาคาร",
    flatList: "รายชื่อแฟลต",
    searchFlat: "ค้นหาแฟลต",
    name: "ชื่อ",
    address: "ที่อยู่",
    mobile: "มือถือ",
    billingMonth: "เดือนที่เรียกเก็บเงิน",
    dueDate: "วันที่ครบกำหนด",
    billingDate: "วันที่เรียกเก็บเงิน",
    paymentRequest: "คำขอชำระเงิน",
    requestText: "มีการขอให้คุณส่งการชำระเงิน",
    invoice: "ใบแจ้งหนี้",
    requiredPaymentAmount: "จำนวนเงินที่ต้องชำระ",
    remark: "ข้อสังเกต",
    depositFor: "เงินฝากสำหรับ",
    due: "ค้างชำระ",
    payNow: "จ่ายตอนนี้",
    remarkText: "หากคุณมีคำถามหรือต้องการความช่วยเหลือ โปรดติดต่อเรา",
    cash: "เงินสด",
    creditDebit: "บัตรเครดิต/เดบิต",
    digitalPayment: "การชำระเงินดิจิทัล",
    eWallet: "กระเป๋าเงินอิเล็กทรอนิกส์",
    availableBalance: "ยอดเงินที่มีอยู่",
    paymentAddedSuccessFully: "เพิ่มการชำระเงินเรียบร้อยแล้ว",
    paymentReceipt: "ใบเสร็จการชำระเงิน",
    original: "ต้นฉบับ",
    customerCopy: "สำเนาลูกค้า",
    email: "อีเมล",
    totalAmount: "ยอดรวม",
    receivedAmount: "จำนวนเงินที่ได้รับ",
    dueAmount: "จำนวนเงินที่ค้างชำระ",
    mrn: "หมายเลขใบเสร็จ",
    paymentDate: "วันที่ชำระเงิน",
    time: "เวลา",
    paymentMethod: "วิธีการชำระเงิน",
    bankCard: "ธนาคาร/บัตร",
    number: "หมายเลข",
    expiryDate: "วันหมดอายุ",
    amount: "จำนวนเงิน",
    totalAmountReceived: "ยอดรวมที่ได้รับ",
    authorizedSignature: "ลายเซ็นที่ได้รับอนุญาต",
    pleaseSelectTheApartmentFirst: "โปรดเลือกอพาร์ตเมนต์ก่อน",
    recordsAreNotAvailable: "ไม่มีบันทึก",
    billWarning: "อพาร์ทเมนท์นี้ไม่มีสิทธิ์ในการเรียกเก็บเงิน โปรดดำเนินการกระบวนการเรียกเก็บเงินให้เสร็จสิ้นก่อน",
    total: "ยอดรวม",
    received: "ได้รับ",
  }
  const arabic = {
    payment: "دفع",
    properties: "العقارات",
    building: "مبنى",
    flatList: "قائمة الشقق",
    searchFlat: "بحث عن شقة",
    name: "الاسم",
    address: "العنوان",
    mobile: "جوال",
    billingMonth: "شهر الفوترة",
    dueDate: "تاريخ الاستحقاق",
    billingDate: "تاريخ الفاتورة",
    paymentRequest: "طلب دفع",
    requestText: "تم تقديم طلب لك لتقديم الدفع",
    invoice: "فاتورة",
    requiredPaymentAmount: "المبلغ المطلوب للدفع",
    remark: "ملاحظة",
    depositFor: "إيداع لـ",
    due: "مستحق",
    payNow: "ادفع الآن",
    remarkText: "إذا كان لديك أي سؤال أو تحتاج إلى مساعدة، يرجى الاتصال بنا.",
    cash: "نقد",
    creditDebit: "بطاقة ائتمان/خصم",
    digitalPayment: "دفع رقمي",
    eWallet: "محفظة إلكترونية",
    availableBalance: "الرصيد المتاح",
    paymentAddedSuccessFully: "تمت إضافة الدفع بنجاح",
    paymentReceipt: "إيصال الدفع",
    original: "الأصل",
    customerCopy: "نسخة العميل",
    email: "البريد الإلكتروني",
    totalAmount: "المبلغ الإجمالي",
    receivedAmount: "المبلغ المستلم",
    dueAmount: "المبلغ المستحق",
    mrn: "رقم الإيصال",
    paymentDate: "تاريخ الدفع",
    time: "الوقت",
    paymentMethod: "طريقة الدفع",
    bankCard: "البنك/البطاقة",
    number: "الرقم",
    expiryDate: "تاريخ انتهاء الصلاحية",
    amount: "المبلغ",
    totalAmountReceived: "إجمالي المبلغ المستلم",
    authorizedSignature: "التوقيع المعتمد",
    pleaseSelectTheApartmentFirst: "يرجى اختيار الشقة أولاً",
    recordsAreNotAvailable: "لا توجد سجلات",
    billWarning: "هذه الشقة غير مؤهلة للفوترة. يرجى إكمال عملية الفوترة أولاً.",
    total: "المجموع",
    received: "المستلم",
  }
  const bengali = {
    payment: "পেমেন্ট",
    properties: "সম্পত্তি",
    building: "ভবন",
    flatList: "ফ্ল্যাটের তালিকা",
    searchFlat: "ফ্ল্যাট অনুসন্ধান",
    name: "নাম",
    address: "ঠিকানা",
    mobile: "মোবাইল",
    billingMonth: "বিলিং মাস",
    dueDate: "নির্দিষ্ট তারিখ",
    billingDate: "বিলিং তারিখ",
    paymentRequest: "পেমেন্ট অনুরোধ",
    requestText: "আপনার পেমেন্ট জমা দেওয়ার জন্য একটি অনুরোধ করা হয়েছে",
    invoice: "ইনভয়েস",
    requiredPaymentAmount: "প্রয়োজনীয় পেমেন্ট পরিমাণ",
    remark: "মন্তব্য",
    depositFor: "জন্য আমানত",
    due: "বকেয়া",
    payNow: "এখন পরিশোধ করুন",
    remarkText: "যদি আপনার কোনো প্রশ্ন থাকে বা সাহায্যের প্রয়োজন হয়, আমাদের সাথে যোগাযোগ করুন।",
    cash: "নগদ",
    creditDebit: "ক্রেডিট/ডেবিট কার্ড",
    digitalPayment: "ডিজিটাল পেমেন্ট",
    eWallet: "ই-ওয়ালেট",
    availableBalance: "উপলব্ধ ব্যালেন্স",
    paymentAddedSuccessFully: "পেমেন্ট সফলভাবে যোগ করা হয়েছে",
    paymentReceipt: "পেমেন্ট রসিদ",
    original: "মূল",
    customerCopy: "গ্রাহকের কপি",
    email: "ইমেল",
    totalAmount: "মোট পরিমাণ",
    receivedAmount: "প্রাপ্ত পরিমাণ",
    dueAmount: "বকেয়া পরিমাণ",
    mrn: "রশিদের নম্বর",
    paymentDate: "পেমেন্ট তারিখ",
    time: "সময়",
    paymentMethod: "পেমেন্ট পদ্ধতি",
    bankCard: "ব্যাংক/কার্ড",
    number: "সংখ্যা",
    expiryDate: "মেয়াদ উত্তীর্ণ তারিখ",
    amount: "পরিমাণ",
    totalAmountReceived: "মোট প্রাপ্ত পরিমাণ",
    authorizedSignature: "অনুমোদিত স্বাক্ষর",
    pleaseSelectTheApartmentFirst: "দয়া করে প্রথমে অ্যাপার্টমেন্টটি নির্বাচন করুন",
    recordsAreNotAvailable: "রেকর্ডগুলি উপলব্ধ নেই",
    billWarning: "এই অ্যাপার্টমেন্টটি বিলিংয়ের জন্য উপযুক্ত নয়। প্রথমে বিলিং প্রক্রিয়া সম্পূর্ণ করুন।",
    total: "মোট",
    received: "প্রাপ্ত",
  }
        
export const paymentLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}