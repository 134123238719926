const english = {
    totalOwners: "Total Owners",
    totalTenants: "Total Tenants",
    totalBill : "Total Bill",
    totalCollection : "Total Collection",
    totalDue: "Total Due",
    billingChart: "Billing Chart",
    due: "Due",
    billCollected: "Bill Collected",
    organizationDetails: "Organization Details",
    tenants: "Tenants",
    owners: "Owners",
    seeAll: "See All",
    invoice: "Invoice",
    name: "Name",
    building: "Building",
    floor: "Floor",
    unit: "Unit",
    total: "Total",
    collected: "Collected",
    remarks: "Remarks",
    date: "Date",
    currentDue: "Current Due",
    
}
const malay = {
    totalOwners: "Jumlah Pemilik",
    totalTenants: "Jumlah Penyewa",
    totalBill: "Jumlah Bil",
    totalCollection: "Jumlah Kutipan",
    totalDue: "Jumlah Tertunggak",
    billingChart: "Carta Bil",
    due: "Tertunggak",
    billCollected: "Bil Dikumpulkan",
    organizationDetails: "Butiran Organisasi",
    tenants: "Penyewa",
    owners: "Pemilik",
    seeAll: "Lihat Semua",
    invoice: "Invois",
    name: "Nama",
    building: "Bangunan",
    floor: "Tingkat",
    unit: "Unit",
    total: "Jumlah",
    collected: "Dikumpulkan",
    remarks: "Ulasan",
    date: "Tarikh",
    currentDue: "Tertunggak Semasa",
  }
  const thai = {
    totalOwners: "เจ้าของทั้งหมด",
    totalTenants: "ผู้เช่าทั้งหมด",
    totalBill: "ยอดรวมบิล",
    totalCollection: "ยอดรวมการเก็บเงิน",
    totalDue: "ยอดรวมที่ค้างชำระ",
    billingChart: "แผนภูมิบิล",
    due: "ค้างชำระ",
    billCollected: "บิลที่เก็บได้",
    organizationDetails: "รายละเอียดองค์กร",
    tenants: "ผู้เช่า",
    owners: "เจ้าของ",
    seeAll: "ดูทั้งหมด",
    invoice: "ใบแจ้งหนี้",
    name: "ชื่อ",
    building: "อาคาร",
    floor: "ชั้น",
    unit: "หน่วย",
    total: "รวม",
    collected: "เก็บได้",
    remarks: "หมายเหตุ",
    date: "วันที่",
    currentDue: "ค้างชำระปัจจุบัน",
  }
  const arabic = {
    totalOwners: "إجمالي المالكين",
    totalTenants: "إجمالي المستأجرين",
    totalBill: "إجمالي الفاتورة",
    totalCollection: "إجمالي التحصيل",
    totalDue: "إجمالي المستحق",
    billingChart: "مخطط الفواتير",
    due: "مستحق",
    billCollected: "الفاتورة التي تم تحصيلها",
    organizationDetails: "تفاصيل المنظمة",
    tenants: "المستأجرين",
    owners: "المالكين",
    seeAll: "عرض الكل",
    invoice: "فاتورة",
    name: "الاسم",
    building: "البناء",
    floor: "الطابق",
    unit: "الوحدة",
    total: "الإجمالي",
    collected: "تم التحصيل",
    remarks: "ملاحظات",
    date: "التاريخ",
    currentDue: "المستحق الحالي",
  }
  const bengali = {
    totalOwners: "মোট মালিক",
    totalTenants: "মোট ভাড়াটিয়া",
    totalBill: "মোট বিল",
    totalCollection: "মোট সংগ্রহ",
    totalDue: "মোট বকেয়া",
    billingChart: "বিল চার্ট",
    due: "বকেয়া",
    billCollected: "সংগৃহীত বিল",
    organizationDetails: "প্রতিষ্ঠানের বিবরণ",
    tenants: "ভাড়াটিয়া",
    owners: "মালিক",
    seeAll: "সব দেখুন",
    invoice: "চালান",
    name: "নাম",
    building: "ভবন",
    floor: "তলা",
    unit: "ইউনিট",
    total: "মোট",
    collected: "সংগৃহীত",
    remarks: "মন্তব্য",
    date: "তারিখ",
    currentDue: "বর্তমান বকেয়া",
  }
        
export const dashboardLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}