
import MaterialTable from 'material-table'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import http from '../../../http';
import { Link } from 'react-router-dom';
import avatar from '../../../front_assets/images/avatar.png'
import { visitorManagementLang } from '../../../language/visitorManagement/visitorManagementLang';
import useLanguage from '../../../language/useLanguage';
export default function Visitors() {
    const lan = useLanguage(visitorManagementLang);
    const deleteRowData = (id) => {
        Swal.fire({
            title: lan?.areYouSure,
            text: lan?.deleteWarning,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: lan?.yesDelete
        })
            .then((result) => {
                if (result.isConfirmed) {
                    http.delete(`visitors/${id}`)
                        .then((res) => {
                            setUpdate(!update);
                            Swal.fire({
                                title: lan?.deleted,
                                text: lan?.deletedMessage,
                                icon: "success"
                            });
                        })
                }
            })
    }

    const columns = [
        {
            title: lan?.sl,
            field: "",
            render: (row) => <div>{row.tableData.id + 1}</div>,
            width: "20 !important",
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: lan?.name,
            field: `name`,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.mobile,
            field: `mobile`,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.email,
            field: `email`,
            render: (row) => <div>{row?.email == "null" ? '' : row.email}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: lan?.image,
            field: 'content',
            render: (row) => <div>
                {
                    row.picture ?
                        <img src={`${global.img_Url}/images/${row?.picture}`} width="50" height="50" alt='Profile' />
                        :
                        <img src={avatar} width="50" height="50" alt='Profile' />
                }

            </div>,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: lan?.action,
            field: "patient",
            render: (row) => (
                <div>
                    <Link to={`/edit-visitor/${row.id}`}>
                        <button
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-edit"></i>
                        </button>
                    </Link>
                    <button
                        onClick={() => deleteRowData(row.id)}
                        className="btn btn-sm action-btn"
                    >
                        <i className="far fa-trash"></i>
                    </button>
                </div>
            ),
            cellStyle: {
                textAlign: "center",
            },
        },
    ];
    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [spinner, setSpinner] = useState(false);
    useEffect(() => {
        const controller = new AbortController();
        setSpinner(true);
        http
            .get(`visitors`)
            .then((res) => {
                setData(res.data)
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err);
                setSpinner(false);
            });


        return () => {
            controller.abort();
        };
    }, [update]);



    return (
        <div className='page-content adjustment-type-table'>
            <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                <h6>{lan?.visitors}</h6>
                <div>
                    <Link to='/add-visitor'>

                        <button style={{ marginTop: "1px" }} className='btn btn-sm btn-primary float-end'>{lan?.add} </button>
                    </Link>
                </div>
            </div>

            <MaterialTable
                columns={columns}
                data={data}
                isLoading={spinner}
                options={{
                    search: true,
                    showTitle: false,
                    searchFieldAlignment: "left",
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
            />
        </div>
    )
}