import React from 'react'
import moment from 'moment';
import Setting from '../../Setting/Setting';
import PrintStatmentHeader from '../../Componets/PrintStatmentHeader';
import useLanguage from '../../../language/useLanguage';
import { financeManagementLang } from '../../../language/financeManagement/financeManagementLang';

export default function HistoryPrint({ name, depositHistory, totalDeposit, statmetnDate, paymentHistroy, totalpayment }) {
    const { currencyFormat, dateFormat } = Setting;
const lan = useLanguage(financeManagementLang);
    return (
        <div >

            <PrintStatmentHeader name={name} start={statmetnDate?.start} end={statmetnDate?.end} />
            {
                depositHistory &&
                <div className="unit-manager-table invoice-item-table ">
                    <table className='mt-1'>
                        <tr style={{ background: "grey", color: "white" }}>
                            <td>{lan?.date}</td>
                            <td>{lan?.name}</td>
                            <td>{lan?.transactionId}</td>
                            <td>{lan?.paymentMethod}</td>
                            <td>{lan?.type}</td>
                            <td>{lan?.amount}</td>
                        </tr>
                        {
                            depositHistory?.length > 0 &&
                            depositHistory?.map((v1, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{moment(v1?.created_at).format(dateFormat)}</td>
                                        <td>{v1?.wallet?.owner?.name}</td>
                                        <td>{v1?.trun_id}</td>
                                        <td>{v1?.payment_type}</td>
                                        <td>{v1?.deposit_or_withdraw}</td>
                                        <td align="right">{v1?.amount}</td>
                                    </tr>
                                )

                            })
                        }
                        <tr>
                            <td colSpan="5">{lan?.totalAmount}</td>
                            <td align="right">{currencyFormat(totalDeposit)}</td>
                        </tr>
                    </table>

                    <div class="d-flex justify-content-between mt-2">
                        <h6>
                            {lan?.totalDeposit} : {currencyFormat(totalDeposit)}
                        </h6>
                    </div>
                </div>
            }
            {
                paymentHistroy &&
                <div className="unit-manager-table invoice-item-table">
                    <table className='mt-1'>
                        <tr style={{ background: "grey", color: "white" }}>
                            <td>{lan?.date}</td>
                            <td>{lan?.invoice}</td>
                            <td>{lan?.name}</td>
                            <td>{lan?.slipNo}.</td>
                            <td>{lan?.paymentMethod}</td>
                            <td>{lan?.amount}</td>
                        </tr>

                        {
                            paymentHistroy?.length > 0 &&
                            paymentHistroy?.map((v1, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{moment(v1?.created_at).format(dateFormat)}</td>
                                        <td>{v1?.bill_entry?.invoice}</td>
                                        <td>{v1?.bill_entry?.floor?.tenant === null ? v1?.bill_entry?.floor?.owner?.name : v1?.bill_entry?.floor?.tenant?.name}</td>
                                        <td>{v1?.slip_number}</td>
                                        <td>{v1?.payment_method}</td>
                                        <td align="right">{v1?.amount}</td>
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td colSpan="5">{lan?.totalAmount}</td>
                            <td align="right">{currencyFormat(totalpayment)}</td>
                        </tr>
                    </table>
                    <div class="d-flex justify-content-between mt-2">
                        <h6>
                            {lan?.total} {lan?.payment} : {currencyFormat(totalpayment)}
                        </h6>
                    </div>
                </div>
            }


        </div>
    )
}
