import React, { useState, useEffect, createContext } from "react";
import http from "../http";
import { Routes, Route } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import SideBar from "../layouts/SideBar";
import NotFound from "./NotFound";
import Properties from "../pages/setup/Properties/Properties";
import AddProperties from "../pages/setup/Properties/AddProperties";
import Owners from "../pages/setup/Owners/Owners";
import AddOwners from "../pages/setup/Owners/AddOwners";
import EditOwners from "../pages/setup/Owners/EditOwners";
import Employees from "../pages/setup/Employee/Employees";
import EditEmployee from "../pages/setup/Employee/EditEmployee";
import AddEmployee from "../pages/setup/Employee/AddEmployee";
import Visitors from "../pages/setup/Visitor/Visitors";
import AddVisitor from "../pages/setup/Visitor/AddVisitor";
import EditVisitors from "../pages/setup/Visitor/EditVisitor";
import Movements from "../pages/setup/Movements/Movements";
import AddMovement from "../pages/setup/Movements/AddMovements";
import EditMovement from "../pages/setup/Movements/EditMovements";
import MemberSetup from "../pages/admin-setup/MemberSetup/MemberSetup";
import Gender from "../pages/admin-setup/Gender/Gender";
import ServiceGroup from "../pages/admin-setup/ServiceGroup/ServiceGroup";
import Fees from "../pages/admin-setup/Fees/Fees";
import Unit from "../pages/admin-setup/Unit/Unit";
import ServiceType from "../pages/admin-setup/ServiceType/ServiceType";
import Utility from "../pages/setup/FeeServiceSetup/AddServiceFeeSetup";
import Service from "../pages/setup/FeeServiceSetup/Service";
import ServiceSub from "../pages/admin-setup/ServiceSub/ServiceSub";
import Notice from "../pages/admin-setup/Notice/Notice";
import HelpLine from "../pages/admin-setup/HelpLine/HelpLine";
import FeeServiceSetup from "../pages/setup/FeeServiceSetup/FeeServiceSetup";
import AddServiceFeeSetup from "../pages/setup/FeeServiceSetup/AddServiceFeeSetup";
import EditServiceFeeSetup from "../pages/setup/FeeServiceSetup/EditiServiceFeeSetup";
import BuildingSetup from "../pages/admin-setup/BuildingSetup/BuildingSetup";
import ViewServiceFeeSetup from "../pages/setup/FeeServiceSetup/ViewServiceFeeSetup";
import Billing from "../pages/setup/Billing/Billing";
import BillingManagement from "../pages/setup/Billing/BillingManagement";
import SaasBilling from "../pages/setup/Billing/SaasBilling";
import Role from "../pages/setup/Role & Permissions/Role";
import Permissions from "../pages/setup/Role & Permissions/Permissions";
import OwnerShipType from "../pages/admin-setup/OwnerShipType/OwnerShipType";
import PropertyTypes from "../pages/admin-setup/PorpertyTypes/PropertyTypes";
import Designation from "../pages/admin-setup/Designation/Designation";
import Division from "../pages/admin-setup/Division/Division";
import District from "../pages/admin-setup/District/District";
import Area from "../pages/admin-setup/Area/Area";
import Nationality from "../pages/admin-setup/Nationality/Nationality";
import BloodGroup from "../pages/admin-setup/BloodGroup/BloodGroup";
import PoliceStations from "../pages/admin-setup/PoliceStations/PoliceStations";
import UinitMeasurement from "../pages/admin-setup/UinitMeasurement/UinitMeasurement";
import BillingStatmentHistory from "../pages/setup/Billing/BillingStatmentHistory";
import FinanceManagment from "../pages/setup/Finance/FinanceManagment";
import propertieContext from "./propertieContext";
import EWalletStatment from "../pages/setup/Finance/BalanceStatment";
import BalanceStatment from "../pages/setup/Finance/BalanceStatment";
import Dashboard from "../pages/Dashboard/Dashboard";
import RentSetup from "../pages/admin-setup/RentSetup/RentSetup";
import Country from "../pages/admin-setup/Country/Country";
import TimeZone from "../pages/admin-setup/TimeZone/TimeZone";
import Settings from "../pages/Settings";
import Currency from "../pages/admin-setup/Currency/Currency";

const Auth = ({ perStorageData }) => {
  const [isSuperAdmin, setisSuperAdmin] = useState(false);
  const [permissionData, setpermissionData] = useState();
  const [random, setRandom] = useState();
  const [organizationInfo, setorganizationInfo] = useState();
  useEffect(() => {
    if (perStorageData) {
      if (perStorageData?.user?.user_type === "Super_Admin") {
        setisSuperAdmin(true);
      } else {
        http
          .get(`user-role-permission/${perStorageData?.user?.id}`)
          .then((res) => {
            console.log(res);
            setpermissionData(res?.data?.permission);
            setRandom(Math.random());
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  }, [perStorageData]);
const [setting,setSetting] = useState({
  language: 'English',
})
  useEffect(() => {
    http
      .get(`settings`)
      .then((res) => {
        setSetting(res.data.data);
      })
      .catch((err) => console.log("err", err));

    const data =
      localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user"))
        : JSON.parse(sessionStorage.getItem("user"));
    setorganizationInfo(data);
  }, []);

  return (
    <>
    <propertieContext.Provider value={{ organizationInfo, setting, setSetting }}>
    <div className="main-wrapper">
      <SideBar permissionData={permissionData} isSuperAdmin={isSuperAdmin} />
      <div className="page-wrapper">
        <Navbar></Navbar>
        {/* <SideBar></SideBar> */}
        {/* <Routes>
                        <Route path="/" element={<Home />} />
         
         </Routes> */}

        
          <Routes>
            <Route path="/" element={<Dashboard />} />

            {/* <Dashboard /> */}

            <Route path="/rent-setup" element={<RentSetup />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/add-properties/:id" element={<AddProperties />} />
            <Route path="/owners" element={<Owners />} />
            <Route path="/add-owners" element={<AddOwners />} />
            <Route path="/edit-owners/:id" element={<EditOwners />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/edit-employee/:id" element={<EditEmployee />} />
            <Route path="/add-employee" element={<AddEmployee />} />
            <Route path="/visitors" element={<Visitors />} />
            <Route path="/add-visitor" element={<AddVisitor />} />
            <Route path="/edit-visitor/:id" element={<EditVisitors />} />
            <Route path="/movements" element={<Movements />} />
            <Route path="/add-movement" element={<AddMovement />} />
            <Route path="/edit-movement/:id" element={<EditMovement />} />
            <Route path="/member-setup" element={<MemberSetup />} />
            <Route path="/gender-setup" element={<Gender />} />
            <Route path="/unit-setup" element={<Unit />} />
            <Route path="/service-setup" element={<ServiceType />} />
            <Route path="/group-setup" element={<ServiceGroup />} />
            <Route path="/fees-setup" element={<Fees />} />
            <Route path="/service-fee-setup" element={<FeeServiceSetup />} />
            <Route
              path="/add-service-fee-setup"
              element={<AddServiceFeeSetup />}
            />
            <Route
              path="/edit-service-fee-setup/:id"
              element={<EditServiceFeeSetup />}
            />
            <Route
              path="/view-service-fee-setup/:id"
              element={<ViewServiceFeeSetup />}
            />
            <Route path="/service-sub-category" element={<ServiceSub />} />
            <Route path="/service" element={<Service />} />
            <Route path="/notice" element={<Notice />} />
            <Route path="/help-line" element={<HelpLine />} />
            <Route path="/building-setup" element={<BuildingSetup />} />
            <Route path="/time-zone-setup" element={<TimeZone />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/currency-setup" element={<Currency />} />
            <Route path="/country-setup" element={<Country />} />
            <Route path="/division" element={<Division />} />
            <Route path="/district" element={<District />} />

            <Route path="/billing" element={<Billing />} />

            <Route path="/billing-managment" element={<BillingManagement />} />
            <Route
              path="/billing-statment-history"
              element={<BillingStatmentHistory />}
            />

            {/* <Route path="/saas-billing" element={<SaasBilling />} /> */}

            <Route path="/finance-managment" element={<FinanceManagment />} />
            <Route path="/balance-statment" element={<BalanceStatment />} />

            <Route path="/role" element={<Role />} />
            <Route path="/owenr-ship-type" element={<OwnerShipType />} />
            <Route
              path="/user-role-permissions-access"
              element={<Permissions />}
            />
            <Route path="/property-type" element={<PropertyTypes />} />
            <Route path="/designations" element={<Designation />} />
            <Route path="/area" element={<Area />} />
            <Route path="/nationality" element={<Nationality />} />
            <Route path="/blood-group" element={<BloodGroup />} />
            <Route path="/police-stations" element={<PoliceStations />} />
            <Route path="/uinit-measurement" element={<UinitMeasurement />} />

            {/* <Route path='/change-password' element={<UpdatePassword />} /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        
      </div>
    </div>
    </propertieContext.Provider>
    </>
  );
};

export default Auth;
