const english = {
    balanceStatement: "Balance Statement",
    refresh: "Refresh",
    print: "Print",
    total: "Total",
    date: "Date",
    transactionId: "Transaction Id",
    name : "Name",
    deposit: "Deposit",
    withdraw: "Withdraw",
    currentBalance: "Current Balance",
    totalDepositAmount: "Total Deposit Amount",
    totalWithdrawAmount: "Total Withdraw Amount",
    totalBalance: "Total Balance",
    notFound: "Records are not available",
    to: "To",
}
const malay = {
    balanceStatement: "Penyata Baki",
    refresh: "Muat Semula",
    print: "Cetak",
    total: "Jumlah",
    date: "Tarikh",
    transactionId: "ID Transaksi",
    name : "Nama",
    deposit: "Deposit",
    withdraw: "Pengeluaran",
    currentBalance: "Baki Semasa",
    totalDepositAmount: "Jumlah Deposit Keseluruhan",
    totalWithdrawAmount: "Jumlah Pengeluaran Keseluruhan",
    totalBalance: "Jumlah Baki",
    notFound: "Rekod tidak tersedia",
    to: "Ke",
  }
  const thai = {
    balanceStatement: "รายการยอดคงเหลือ",
    refresh: "รีเฟรช",
    print: "พิมพ์",
    total: "ยอดรวม",
    date: "วันที่",
    transactionId: "รหัสธุรกรรม",
    name : "ชื่อ",
    deposit: "ฝากเงิน",
    withdraw: "ถอนเงิน",
    currentBalance: "ยอดคงเหลือปัจจุบัน",
    totalDepositAmount: "ยอดรวมเงินฝาก",
    totalWithdrawAmount: "ยอดรวมการถอน",
    totalBalance: "ยอดคงเหลือทั้งหมด",
    notFound: "ไม่พบข้อมูล",
    to: "ถึง",
  }
  const arabic = {
    balanceStatement: "كشف الرصيد",
    refresh: "تحديث",
    print: "طباعة",
    total: "المجموع",
    date: "التاريخ",
    transactionId: "معرف المعاملة",
    name : "الاسم",
    deposit: "إيداع",
    withdraw: "سحب",
    currentBalance: "الرصيد الحالي",
    totalDepositAmount: "إجمالي مبلغ الإيداع",
    totalWithdrawAmount: "إجمالي مبلغ السحب",
    totalBalance: "إجمالي الرصيد",
    notFound: "السجلات غير متوفرة",
    to: "إلى",
  }
  const bengali = {
    balanceStatement: "ব্যালেন্স স্টেটমেন্ট",
    refresh: "রিফ্রেশ",
    print: "প্রিন্ট",
    total: "মোট",
    date: "তারিখ",
    transactionId: "লেনদেন আইডি",
    name : "নাম",
    deposit: "জমা",
    withdraw: "প্রত্যাহার",
    currentBalance: "বর্তমান ব্যালেন্স",
    totalDepositAmount: "মোট জমার পরিমাণ",
    totalWithdrawAmount: "মোট প্রত্যাহারের পরিমাণ",
    totalBalance: "মোট ব্যালেন্স",
    notFound: "রেকর্ড পাওয়া যায়নি",
    to: "থেকে",
  }
        
export const balanceStatementLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}