const english = {
    billingManagement: "Billing Management",
    billEntry: "Bill Entry",
  billProcess: "Bill Process",
  payment: "Payment",
  print: "Print",
  invoice: "Invoice",
  projectDetails: "Project Details",
  building: "Building",
  floor: "Floor",
  unit: "Unit",
  date: "Date",
  flat: "Flat",
  owner: "Owner",
  tenant: "Tenant",
  totalBill: "Total Bill",
  due: "Due",
  bill: "Bill",
  paymentReceived: "Payment Received",
  billStatus: "Bill Status",
  paymentStatus: "Payment Status",
  action: "Action",
  pending: "Pending",
  complete: "Complete",
  partialPayment: "Partial Payment",
  incomplete : "Incomplete",
  locked: "Locked",
  draft: "Draft",
  grandTotal: "Grand Total",
  totalReceived: "Total Received",
  totalDue: "Total Due",
  send: "Send",
  email : "Email",
  sms: "SMS",
  pleaseSelectTheInvoice: "Please select the invoice",
  project: "Project",

}
const malay = {
    billingManagement: "Pengurusan Penagihan",
    billEntry: "Entri Bil",
    billProcess: "Proses Bil",
    payment: "Pembayaran",
    print: "Cetak",
    invoice: "Invois",
    projectDetails: "Butiran Projek",
    building: "Bangunan",
    floor: "Lantai",
    unit: "Unit",
    date: "Tarikh",
    flat: "Pangsapuri",
    owner: "Pemilik",
    tenant: "Penyewa",
    totalBill: "Jumlah Bil",
    due: "Tertunggak",
    bill: "Bil",
    paymentReceived: "Pembayaran Diterima",
    billStatus: "Status Bil",
    paymentStatus: "Status Pembayaran",
    action: "Tindakan",
    pending: "Tertunda",
    complete: "Lengkap",
    partialPayment: "Pembayaran Sebahagian",
    incomplete: "Tidak Lengkap",
    locked: "Terkunci",
    draft: "Draf",
    grandTotal: "Jumlah Keseluruhan",
    totalReceived: "Jumlah Diterima",
    totalDue: "Jumlah Tertunggak",
    send: "Hantar",
    email: "E-mel",
    sms: "SMS",
    pleaseSelectTheInvoice: "Sila pilih invois",
    project: "Projek",
  }
  const thai = {
    billingManagement: "การจัดการการเรียกเก็บเงิน",
    billEntry: "การป้อนบิล",
    billProcess: "กระบวนการบิล",
    payment: "การชำระเงิน",
    print: "พิมพ์",
    invoice: "ใบแจ้งหนี้",
    projectDetails: "รายละเอียดโครงการ",
    building: "อาคาร",
    floor: "ชั้น",
    unit: "หน่วย",
    date: "วันที่",
    flat: "แฟลต",
    owner: "เจ้าของ",
    tenant: "ผู้เช่า",
    totalBill: "บิลรวม",
    due: "ค้างชำระ",
    bill: "บิล",
    paymentReceived: "ได้รับชำระเงิน",
    billStatus: "สถานะบิล",
    paymentStatus: "สถานะการชำระเงิน",
    action: "การกระทำ",
    pending: "รอดำเนินการ",
    complete: "เสร็จสมบูรณ์",
    partialPayment: "การชำระเงินบางส่วน",
    incomplete: "ไม่สมบูรณ์",
    locked: "ล็อกแล้ว",
    draft: "ร่าง",
    grandTotal: "ยอดรวม",
    totalReceived: "ยอดรวมที่ได้รับ",
    totalDue: "ยอดรวมค้างชำระ",
    send: "ส่ง",
    email: "อีเมล",
    sms: "เอสเอ็มเอส",
    pleaseSelectTheInvoice: "กรุณาเลือกใบแจ้งหนี้",
    project: "โครงการ",
  }
  const arabic = {
    billingManagement: "إدارة الفواتير",
    billEntry: "إدخال الفاتورة",
    billProcess: "عملية الفاتورة",
    payment: "دفع",
    print: "طباعة",
    invoice: "فاتورة",
    projectDetails: "تفاصيل المشروع",
    building: "مبنى",
    floor: "طابق",
    unit: "وحدة",
    date: "تاريخ",
    flat: "شقة",
    owner: "مالك",
    tenant: "مستأجر",
    totalBill: "إجمالي الفاتورة",
    due: "مستحق",
    bill: "فاتورة",
    paymentReceived: "تم استلام الدفع",
    billStatus: "حالة الفاتورة",
    paymentStatus: "حالة الدفع",
    action: "إجراء",
    pending: "قيد الانتظار",
    complete: "مكتمل",
    partialPayment: "دفع جزئي",
    incomplete: "غير مكتمل",
    locked: "مغلق",
    draft: "مسودة",
    grandTotal: "المجموع الكلي",
    totalReceived: "إجمالي المستلم",
    totalDue: "إجمالي المستحق",
    send: "إرسال",
    email: "البريد الإلكتروني",
    sms: "رسالة نصية",
    pleaseSelectTheInvoice: "الرجاء تحديد الفاتورة",
    project: "المشروع",
  }
  const bengali = {
    billingManagement: "বিলিং ব্যবস্থাপনা",
    billEntry: "বিল এন্ট্রি",
    billProcess: "বিল প্রক্রিয়া",
    payment: "পেমেন্ট",
    print: "প্রিন্ট",
    invoice: "ইনভয়েস",
    projectDetails: "প্রকল্পের বিবরণ",
    building: "ভবন",
    floor: "তলা",
    unit: "ইউনিট",
    date: "তারিখ",
    flat: "ফ্ল্যাট",
    owner: "মালিক",
    tenant: "ভাড়াটিয়া",
    totalBill: "মোট বিল",
    due: "বকেয়া",
    bill: "বিল",
    paymentReceived: "পেমেন্ট প্রাপ্ত",
    billStatus: "বিলের অবস্থা",
    paymentStatus: "পেমেন্টের অবস্থা",
    action: "কর্ম",
    pending: "বিচারাধীন",
    complete: "সম্পূর্ণ",
    partialPayment: "আংশিক পেমেন্ট",
    incomplete: "অসম্পূর্ণ",
    locked: "লক করা হয়েছে",
    draft: "খসড়া",
    grandTotal: "সর্বমোট",
    totalReceived: "মোট প্রাপ্ত",
    totalDue: "মোট বকেয়া",
    send: "পাঠান",
    email: "ইমেল",
    sms: "এসএমএস",
    pleaseSelectTheInvoice: "অনুগ্রহ করে বিল নির্বাচন করুন",
    project: "প্রকল্প",
  }
        
export const billingManagementLang = {
    english,
    thai,
    malay,
    arabic,
    bengali
}