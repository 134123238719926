const english = {
    sl: "SL",
  logo: "Logo",
  company: "Company",
  property: "Property",
  address: "Address",
  building: "Building",
  floor: "Floor",
  action: "Action",
  add: "Add",
  projectInformation : "Project Information",
  projectInfo: "Project Info",
  properties: "Properties",
  propertyType: "Property Type",
  companyName: "Company Name",
  name: "Name",
  division: "Division",
  district: "District",
  area: "Area",
  policeStation: "Police Station",
  buildingNo: "Building No",
  buildings: "Buildings",
  about: "About",
  address1: "Address 1",
  address2: "Address 2",
  back: "Back",
  next: "Next",
  save: "Save",
  projectDetails: "Project Details",
  manager: "Manager",
  totalUnitFloor: "Total Unit/Floor",
  totalFloor: "Total Floor",
  apply: "Apply",
  unitSize: "Unit Size",
  ownershipType: "Ownership Type",
  owner: "Owner",
  tenant: "Tenant",
  billMapStatus: "Bill Map Status",
  select: "Select",
  previous: "Previous",
  contactDetails: "Contact Details",
  billingMapForAll:"Billing Map for All",
  um:"UM",
  rentMap:"Rent Map",
  billingMap : "Billing Map",
  ownerDetails: "Owner Details",
  tenantDetails:"Tenant Details",
  addUnit:"Add Unit",
  remove:"Remove",
  loading : "Loading",
  phone1:"Phone 1",
  phone2:"Phone 2",
  mobile1:"Mobile 1",
  mobile2:"Mobile 2",
  email:"Email",
  emergency:"Emergency",
  presentAddress: "Present Address",
  designation: "Designation",
  permanentAddress: "Permanent Address",
  unit: "Unit",
  remarks: "Remarks",
  saveMessage: "Data has been saved successfully",
  updateMessage: "Data has been updated successfully",
  deleteMessage: "Data has been deleted successfully",
  yes: "Yes",
  no: "No",
  cancel : "Cancel",
  areYouSure: "Are you sure?",
  deleteWarning: "You won't be able to revert this!",
  yesDelete: "Yes, delete it!",
}
const malay = {
  sl: "SL",
  logo: "Logo",
  company: "Syarikat",
  property: "Hartanah",
  address: "Alamat",
  building: "Bangunan",
  floor: "Tingkat",
  action: "Tindakan",
  add: "Tambah",
  projectInformation: "Maklumat Projek",
  projectInfo: "Maklumat Projek",
  properties: "Hartanah",
  propertyType: "Jenis Hartanah",
  companyName: "Nama Syarikat",
  name: "Nama",
  division: "Bahagian",
  district: "Daerah",
  area: "Kawasan",
  policeStation: "Balai Polis",
  buildingNo: "No. Bangunan",
  buildings: "Bangunan",
  about: "Tentang",
  address1: "Alamat 1",
  address2: "Alamat 2",
  back: "Kembali",
  next: "Seterusnya",
  save: "Simpan",
  projectDetails: "Butiran Projek",
  manager: "Pengurus",
  totalUnitFloor: "Jumlah Unit/Tingkat",
  totalFloor: "Jumlah Tingkat",
  apply: "Mohon",
  unitSize: "Saiz Unit",
  ownershipType: "Jenis Pemilikan",
  owner: "Pemilik",
  tenant: "Penyewa",
  billMapStatus: "Status Peta Bil",
  select: "Pilih",
  previous: "Sebelumnya",
  contactDetails: "Maklumat Hubungan",
  billingMapForAll: "Peta Bil untuk Semua",
  um: "UM",
  rentMap: "Peta Sewa",
  billingMap: "Peta Bil",
  ownerDetails: "Butiran Pemilik",
  tenantDetails: "Butiran Penyewa",
  addUnit: "Tambah Unit",
  remove: "Keluarkan",
  loading: "Memuatkan",
  phone1: "Telefon 1",
  phone2: "Telefon 2",
  mobile1: "Mudah Alih 1",
  mobile2: "Mudah Alih 2",
  email: "E-mel",
  emergency: "Kecemasan",
  presentAddress: "Alamat Sekarang",
  designation: "Jawatan",
  permanentAddress: "Alamat Tetap",
  unit: "Unit",
    remarks: "Catatan",
    saveMessage: "Data telah berjaya disimpan",
    updateMessage: "Data telah berjaya dikemas kini",
    deleteMessage: "Data telah berjaya dipadamkan",
    yes: "Ya",
    no: "Tidak",
    cancel: "Batal",
    areYouSure: "Adakah anda pasti?",
    deleteWarning: "Anda tidak akan dapat mengembalikan ini!",
    yesDelete: "Ya, padamkan!",
}


const thai = {
  sl: "ลำดับ",
  logo: "โลโก้",
  company: "บริษัท",
  property: "ทรัพย์สิน",
  address: "ที่อยู่",
  building: "อาคาร",
  floor: "ชั้น",
  action: "การกระทำ",
  add: "เพิ่ม",
  projectInformation: "ข้อมูลโครงการ",
  projectInfo: "ข้อมูลโครงการ",
  properties: "ทรัพย์สิน",
  propertyType: "ประเภททรัพย์สิน",
  companyName: "ชื่อบริษัท",
  name: "ชื่อ",
  division: "แผนก",
  district: "อำเภอ",
  area: "พื้นที่",
  policeStation: "สถานีตำรวจ",
  buildingNo: "เลขที่อาคาร",
  buildings: "อาคาร",
  about: "เกี่ยวกับ",
  address1: "ที่อยู่ 1",
  address2: "ที่อยู่ 2",
  back: "ย้อนกลับ",
  next: "ถัดไป",
  save: "บันทึก",
  projectDetails: "รายละเอียดโครงการ",
  manager: "ผู้จัดการ",
  totalUnitFloor: "จำนวนหน่วย/ชั้น",
  totalFloor: "จำนวนชั้น",
  apply: "สมัคร",
  unitSize: "ขนาดหน่วย",
  ownershipType: "ประเภทการเป็นเจ้าของ",
  owner: "เจ้าของ",
  tenant: "ผู้เช่า",
  billMapStatus: "สถานะแผนที่บิล",
  select: "เลือก",
  previous: "ก่อนหน้า",
  contactDetails: "รายละเอียดการติดต่อ",
  billingMapForAll: "แผนที่บิลสำหรับทุกคน",
  um: "UM",
  rentMap: "แผนที่ค่าเช่า",
  billingMap: "แผนที่บิล",
  ownerDetails: "รายละเอียดเจ้าของ",
  tenantDetails: "รายละเอียดผู้เช่า",
  addUnit: "เพิ่มหน่วย",
  remove: "ลบ",
  loading: "กำลังโหลด",
  phone1: "โทรศัพท์ 1",
  phone2: "โทรศัพท์ 2",
  mobile1: "มือถือ 1",
  mobile2: "มือถือ 2",
  email: "อีเมล",
  emergency: "ฉุกเฉิน",
  presentAddress: "ที่อยู่ปัจจุบัน",
  designation: "ตำแหน่ง",
  permanentAddress: "ที่อยู่อาศัยถาวร",
  unit: "หน่วย",
    remarks: "หมายเหตุ",
    saveMessage: "บันทึกข้อมูลเรียบร้อยแล้ว",
    updateMessage: "อัปเดตข้อมูลเรียบร้อยแล้ว",
    deleteMessage: "ลบข้อมูลเรียบร้อยแล้ว",
    yes: "ใช่",
    no: "ไม่",
    cancel: "ยกเลิก",
    areYouSure: "คุณแน่ใจหรือ?",
    deleteWarning: "คุณจะไม่สามารถย้อนกลับได้!",
    yesDelete: "ใช่, ลบมัน!",
}


const arabic = {
  sl: "التسلسل",
  logo: "الشعار",
  company: "الشركة",
  property: "الملكية",
  address: "العنوان",
  building: "المبنى",
  floor: "الطابق",
  action: "الإجراء",
  add: "إضافة",
  projectInformation: "معلومات المشروع",
  projectInfo: "معلومات المشروع",
  properties: "العقارات",
  propertyType: "نوع الملكية",
  companyName: "اسم الشركة",
  name: "الاسم",
  division: "القسم",
  district: "المنطقة",
  area: "المنطقة",
  policeStation: "مركز الشرطة",
  buildingNo: "رقم المبنى",
  buildings: "المباني",
  about: "حول",
  address1: "العنوان 1",
  address2: "العنوان 2",
  back: "رجوع",
  next: "التالي",
  save: "حفظ",
  projectDetails: "تفاصيل المشروع",
  manager: "المدير",
  totalUnitFloor: "إجمالي الوحدات/الطابق",
  totalFloor: "إجمالي الطوابق",
  apply: "تطبيق",
  unitSize: "حجم الوحدة",
  ownershipType: "نوع الملكية",
  owner: "المالك",
  tenant: "المستأجر",
  billMapStatus: "حالة خريطة الفواتير",
  select: "اختر",
  previous: "السابق",
  contactDetails: "تفاصيل الاتصال",
  billingMapForAll: "خريطة الفواتير للجميع",
  um: "UM",
  rentMap: "خريطة الإيجار",
  billingMap: "خريطة الفواتير",
  ownerDetails: "تفاصيل المالك",
  tenantDetails: "تفاصيل المستأجر",
  addUnit: "إضافة وحدة",
  remove: "إزالة",
  loading: "جار التحميل",
  phone1: "الهاتف 1",
  phone2: "الهاتف 2",
  mobile1: "الجوال 1",
  mobile2: "الجوال 2",
  email: "البريد الإلكتروني",
  emergency: "حالة طوارئ",
  presentAddress: "العنوان الحالي",
  designation: "التعيين",
  permanentAddress: "العنوان الدائم",
  unit: "وحدة",
    remarks: "ملاحظات",
    saveMessage: "تم حفظ البيانات بنجاح",
    updateMessage: "تم تحديث البيانات بنجاح",
    deleteMessage: "تم حذف البيانات بنجاح",
    yes: "نعم",
    no: "لا",
    cancel: "إلغاء",
    areYouSure: "هل أنت متأكد؟",
    deleteWarning: "لن تتمكن من التراجع عن هذا!",
    yesDelete: "نعم، احذفه!",
}

const bengali = {
  sl: "ক্রমিক",
  logo: "লোগো",
  company: "কোম্পানি",
  property: "সম্পত্তি",
  address: "ঠিকানা",
  building: "ভবন",
  floor: "তলা",
  action: "ক্রিয়া",
  add: "যোগ করুন",
  projectInformation: "প্রকল্পের তথ্য",
  projectInfo: "প্রকল্পের তথ্য",
  properties: "সম্পত্তিসমূহ",
  propertyType: "সম্পত্তির ধরণ",
  companyName: "কোম্পানির নাম",
  name: "নাম",
  division: "বিভাগ",
  district: "জেলা",
  area: "এলাকা",
  policeStation: "থানা",
  buildingNo: "ভবন নম্বর",
  buildings: "ভবনসমূহ",
  about: "সম্পর্কে",
  address1: "ঠিকানা ১",
  address2: "ঠিকানা ২",
  back: "পেছনে",
  next: "পরবর্তী",
  save: "সংরক্ষণ করুন",
  projectDetails: "প্রকল্পের বিবরণ",
  manager: "ব্যবস্থাপক",
  totalUnitFloor: "মোট ইউনিট/তলা",
  totalFloor: "মোট তলা",
  apply: "আবেদন করুন",
  unitSize: "ইউনিটের আকার",
  ownershipType: "মালিকানার ধরণ",
  owner: "মালিক",
  tenant: "ভাড়াটিয়া",
  billMapStatus: "বিল মানচিত্রের অবস্থা",
  select: "নির্বাচন করুন",
  previous: "পূর্ববর্তী",
  contactDetails: "যোগাযোগের বিবরণ",
  billingMapForAll: "সব জন্য বিল মানচিত্র",
  um: "ইউএম",
  rentMap: "ভাড়া মানচিত্র",
  billingMap: "বিল মানচিত্র",
  ownerDetails: "মালিকের বিবরণ",
  tenantDetails: "ভাড়াটিয়ার বিবরণ",
  addUnit: "ইউনিট যোগ করুন",
  remove: "অপসারণ",
  loading: "লোড হচ্ছে",
  phone1: "ফোন ১",
  phone2: "ফোন ২",
  mobile1: "মোবাইল ১",
  mobile2: "মোবাইল ২",
  email: "ইমেল",
  emergency: "জরুরি",
  presentAddress: "বর্তমান ঠিকানা",
  designation: "উপাধি",
  permanentAddress: "স্থায়ী ঠিকানা",
  unit: "ইউনিট",
    remarks: "মন্তব্য",
    saveMessage: "ডেটা সফলভাবে সংরক্ষণ করা হয়েছে",
    updateMessage: "ডেটা সফলভাবে আপডেট করা হয়েছে",
    deleteMessage: "ডেটা সফলভাবে মুছে ফেলা হয়েছে",
    yes: "হ্যাঁ",
    no: "না",
    cancel: "বাতিল",
    areYouSure: "আপনি কি নিশ্চিত?",
    deleteWarning: "আপনি এটি ফিরিয়ে আনতে পারবেন না!",
    yesDelete: "হ্যাঁ, এটি মুছে ফেলুন!",
}


export const propertiesLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}