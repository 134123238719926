const english = {
    visitors : "Visitors",
    name : "Name",
    email : "Email",
    mobile: "Mobile",
    image: "Image",
    action: "Action",
    fullName: "Full Name",
    picture: "Picture",
    address : "Address",
    save : "Save",
    cancel : "Cancel",
    update : "Update",
    visitorAddedSuccessfully : "Visitor added successfully",
    visitorUpdatedSuccessfully : "Visitor updated successfully",
    visitorDeletedSuccessfully : "Visitor deleted successfully",
    areYouSure : "Are you sure?",
    youWontBeAbleToRevertThis : "You won't be able to revert this!",
    yes : "Yes, delete it!",
    success : "Success",
    error : "Error",
    add : "Add",
    visitorInformation : "Visitor Information",
    saveMessage: "Data has been saved successfully",
  updateMessage: "Data has been updated successfully",
  deleteMessage: "Data has been deleted successfully",
  deleteWarning: "You won't be able to revert this!",
  yesDelete: "Yes, delete it!",

}
const malay = {
    visitors: "Pelawat",
    name: "Nama",
    email: "E-mel",
    mobile: "Mudah Alih",
    image: "Gambar",
    action: "Tindakan",
    fullName: "Nama Penuh",
    picture: "Gambar",
    address: "Alamat",
    save: "Simpan",
    cancel: "Batal",
    update: "Kemas Kini",
    visitorAddedSuccessfully: "Pelawat berjaya ditambah",
    visitorUpdatedSuccessfully: "Pelawat berjaya dikemas kini",
    visitorDeletedSuccessfully: "Pelawat berjaya dipadamkan",
    areYouSure: "Adakah anda pasti?",
    youWontBeAbleToRevertThis: "Anda tidak akan dapat mengembalikan ini!",
    yes: "Ya, padamkannya!",
    success: "Berjaya",
    error: "Ralat",
    add: "Tambah",
    visitorInformation: "Maklumat Pelawat",
    saveMessage: "Data telah disimpan dengan jayanya",
    updateMessage: "Data telah dikemas kini dengan jayanya",
    deleteMessage: "Data telah dipadamkan dengan jayanya",
    deleteWarning: "Anda tidak akan dapat mengembalikannya!",
    yesDelete: "Ya, padamkan!"
  }
  
  const thai = {
    visitors: "ผู้เยี่ยมชม",
    name: "ชื่อ",
    email: "อีเมล",
    mobile: "มือถือ",
    image: "ภาพ",
    action: "การกระทำ",
    fullName: "ชื่อเต็ม",
    picture: "ภาพ",
    address: "ที่อยู่",
    save: "บันทึก",
    cancel: "ยกเลิก",
    update: "อัปเดต",
    visitorAddedSuccessfully: "เพิ่มผู้เยี่ยมชมสำเร็จ",
    visitorUpdatedSuccessfully: "อัปเดตผู้เยี่ยมชมสำเร็จ",
    visitorDeletedSuccessfully: "ลบผู้เยี่ยมชมสำเร็จ",
    areYouSure: "คุณแน่ใจหรือ?",
    youWontBeAbleToRevertThis: "คุณจะไม่สามารถย้อนกลับได้!",
    yes: "ใช่, ลบมัน!",
    success: "สำเร็จ",
    error: "ข้อผิดพลาด",
    add: "เพิ่ม",
    visitorInformation: "ข้อมูลผู้เยี่ยมชม",
    saveMessage: "ข้อมูลได้ถูกบันทึกเรียบร้อยแล้ว",
    updateMessage: "ข้อมูลได้ถูกอัปเดตเรียบร้อยแล้ว",
    deleteMessage: "ข้อมูลได้ถูกลบเรียบร้อยแล้ว",
    deleteWarning: "คุณจะไม่สามารถกู้คืนได้!",
    yesDelete: "ใช่, ลบเลย!"
  }
  
  const arabic = {
    visitors: "الزوار",
    name: "الاسم",
    email: "البريد الإلكتروني",
    mobile: "جوال",
    image: "صورة",
    action: "إجراء",
    fullName: "الاسم الكامل",
    picture: "صورة",
    address: "عنوان",
    save: "حفظ",
    cancel: "إلغاء",
    update: "تحديث",
    visitorAddedSuccessfully: "تمت إضافة الزائر بنجاح",
    visitorUpdatedSuccessfully: "تم تحديث الزائر بنجاح",
    visitorDeletedSuccessfully: "تم حذف الزائر بنجاح",
    areYouSure: "هل أنت متأكد؟",
    youWontBeAbleToRevertThis: "لن تتمكن من التراجع عن هذا!",
    yes: "نعم، احذفه!",
    success: "نجاح",
    error: "خطأ",
    add: "إضافة",
    visitorInformation: "معلومات الزائر",
    saveMessage: "تم حفظ البيانات بنجاح",
    updateMessage: "تم تحديث البيانات بنجاح",
    deleteMessage: "تم حذف البيانات بنجاح",
    deleteWarning: "لن تتمكن من استعادته!",
    yesDelete: "نعم، احذفه!"

  }
  
  const bengali = {
    visitors: "পরিদর্শক",
    name: "নাম",
    email: "ইমেল",
    mobile: "মোবাইল",
    image: "ছবি",
    action: "কর্ম",
    fullName: "পুরো নাম",
    picture: "ছবি",
    address: "ঠিকানা",
    save: "সংরক্ষণ",
    cancel: "বাতিল",
    update: "হালনাগাদ",
    visitorAddedSuccessfully: "পরিদর্শক সফলভাবে যুক্ত হয়েছে",
    visitorUpdatedSuccessfully: "পরিদর্শক সফলভাবে হালনাগাদ হয়েছে",
    visitorDeletedSuccessfully: "পরিদর্শক সফলভাবে মুছে ফেলা হয়েছে",
    areYouSure: "তুমি কি নিশ্চিত?",
    youWontBeAbleToRevertThis: "আপনি এটি ফিরিয়ে আনতে পারবেন না!",
    yes: "হ্যাঁ, এটি মুছুন!",
    success: "সফলতা",
    error: "ত্রুটি",
    add: "যোগ করুন",
    visitorInformation: "পরিদর্শকের তথ্য",
    saveMessage: "তথ্য সফলভাবে সংরক্ষিত হয়েছে",
    updateMessage: "তথ্য সফলভাবে আপডেট হয়েছে",
    deleteMessage: "তথ্য সফলভাবে মুছে ফেলা হয়েছে",
    deleteWarning: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yesDelete: "হ্যাঁ, মুছে ফেলুন!"
  }
  
        
export const visitorManagementLang = {
    english,
    arabic,
    bengali,
    malay,
    thai
}