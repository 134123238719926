import React from 'react'
import { useState, useRef } from 'react';
import '../../../CSS/Invoice.css'
import http from '../../../http';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { visitorManagementLang } from '../../../language/visitorManagement/visitorManagementLang';
import useLanguage from '../../../language/useLanguage';
export default function AddVisitor() {
    const lan = useLanguage(visitorManagementLang);
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [picturePreview, setPicturePreview] = useState(null);
    // project info 
    const [personalInfo, setPersonaInfo] = useState({
        name: '',
        email: '',
        mobile: '',
        picture: '',
        address: '',
    })
    const handlePersonalInfoChange = (event) => {
        const { name, value } = event.target;
        setPersonaInfo({ ...personalInfo, [name]: value });
    }
    const [error, setError] = useState({
        picture: '',
        mobile: '',
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', personalInfo.name)
        formData.append('email', personalInfo.email)
        formData.append('mobile', personalInfo.mobile)
        formData.append('picture', personalInfo.picture)
        formData.append('address', personalInfo.address)

        http.post(`visitors`, formData)
            .then((res) => {
                console.log(res, 'res')
                if (res?.data?.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: lan?.saveMessage,
                        showConfirmButton: false,
                        timer: 1500
                      })
                    document.getElementById('picture').value = ''
                    setPersonaInfo({
                        name: '',
                        email: '',
                        mobile: '',
                        picture: '',
                        address: ''
                    })
                    navigate('/visitors')
                } else if (res?.data?.status === 400) {
                    console.log('', res)
                    setError({
                        ...error,
                        picture: res?.data?.errors?.picture,
                        mobile: res?.data?.errors?.mobile
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Something went wrong',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
    const handle_Images_File = (event) => {
        const file = event.target.files[0];

        if (file.size < 1000048) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPicturePreview(reader.result);
            };
            reader.readAsDataURL(file);
            const existing = { ...personalInfo };
            existing["picture"] = file;
            setPersonaInfo(existing);
        } else {
            inputRef.current.value = '';
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'File size exceeds the limit (1MB)',
                showConfirmButton: true,
            })
        }
    };
    console.log(picturePreview, 'picturePreview')
    return (
        <div className="page-content bg-white">
            <div className="property-register ms-1">
                <form onSubmit={handleSubmit}>
                    <h3 className="text-center my-3">{lan?.visitorInformation}</h3>
                    <div className=" p-2">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>{lan?.fullName} <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <input required type="text" onChange={handlePersonalInfoChange} value={personalInfo.name} name='name' className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> {lan?.email}</label>
                                    </div>
                                    <div className="col-9">
                                        <input type="email" onChange={handlePersonalInfoChange} value={personalInfo?.email} name='email' className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> {lan?.address}</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea type="file" id='address' onChange={(e) => setPersonaInfo({ ...personalInfo, address: e.target.value })} name='address' className="form-control form-control-sm"></textarea>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> {lan?.mobile} <span className="text-danger">*</span>   </label>
                                    </div>
                                    <div className="col-9">
                                        <input required type="tel" onChange={handlePersonalInfoChange} value={personalInfo.mobile} name='mobile' className="form-control form-control-sm" />
                                        {error?.mobile && <div className='text-danger'>{error?.mobile}</div>}
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> {lan?.picture}</label>
                                    </div>
                                    <div className="col-9">
                                        <input type="file" ref={inputRef} id='picture' onChange={(e) => handle_Images_File(e)} accept='image/*' name='picture' className="form-control form-control-sm" />
                                        {error?.picture && <div className='text-danger'>{error?.picture}</div>}
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">

                                    </div>
                                    <div className="col-9">
                                        {picturePreview === null ?
                                            (<></>) :
                                            (< img src={picturePreview} alt="Live Preview" style={{ maxWidth: '100%', height: 'auto' }} width="120" />)
                                        }
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex justify-content-end">
                                        <Link to="/visitors">
                                            <button type='button' className="btn btn-sm btn-primary me-3 mt-3">{lan?.cancel}</button>
                                        </Link>
                                        <button type='submit' className="btn btn-sm btn-primary me-3 mt-3">{lan?.save}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
