const english = {
    ownerTenants: "Owner/Tenants",
    sl: "SL",
    name: "Name",
    image: "Image",
    mobile : "Mobile",
    email: "Email",
    ownerShipType: "OwnerShip Type",
    walletNumber: "Wallet Number",
    eWalletBalance: "E-Wallet Balance",
    projects: "Projects",
    action: "Action",
    fullName: "Full Name",
    ownershipType: "Ownership Type",
    selectProperty: "Select Property",
    fatherName: "Father Name",
    phone1: "Phone 1",
    mobile1: "Mobile 1",
    address1: "Address 1",
    dateOfBirth: "Date of Birth",
    profession: "Profession",
    mothersName: "Mother's Name",
    phone2: "Phone 2",
    mobile2: "Mobile 2",
    address2: "Address 2",
    picture: "Picture",
    personalInfo: "Personal Info",
    familyMember: "Family Member",
    myWallet: "My Wallet",
    back : "Back",
    next : "Next",
    save : "Save",
    add: "Add",
    previous : "Previous",
    update: "Update",
    memberType: "Member Type",
    gender: "Gender",
    contactNo: "Contact No",
    bloodGroup: "Blood Group",
    profileImage : "Profile Image",
    nationality: "Nationality",
    emergency: "Emergency",
    address: "Address",
    ownerName: "Owner Name",
    walletNo: "Wallet No",
    activationDate: "Activation Date",
    totalDeposit: "Total Deposit",
    totalWithdraw: "Total Withdraw",
    currentBalance: "Current Balance",
    bdt: "BDT",
    date: "Date",
    transactionNo: "Transaction No",
    receivedBy: "Received By",
    depositWithdraw: "Deposit/Withdraw",
    amount: "Amount",
    eWalletHistory: "E-Wallet History",
    ownerInformation: "Owner Information",
    verifyYourWalletNo: "Verify Your Wallet No",
    sendOtp: "Send OTP",
    notVerified: "Not Verified",
    verified: "Verified",
    verificationSent: "Verification has been sent to your registered mobile",
}
const malay = {
    ownerTenants: "Pemilik/Penyewa",
    sl: "SL",
    name: "Nama",
    image: "Gambar",
    mobile: "Telefon Bimbit",
    email: "E-mel",
    ownerShipType: "Jenis Pemilikan",
    walletNumber: "Nombor Dompet",
    eWalletBalance: "Baki E-Dompet",
    projects: "Projek",
    action: "Tindakan",
    fullName: "Nama Penuh",
    ownershipType: "Jenis Pemilikan",
    selectProperty: "Pilih Harta",
    fatherName: "Nama Bapa",
    phone1: "Telefon 1",
    mobile1: "Telefon Bimbit 1",
    address1: "Alamat 1",
    dateOfBirth: "Tarikh Lahir",
    profession: "Profesion",
    mothersName: "Nama Ibu",
    phone2: "Telefon 2",
    mobile2: "Telefon Bimbit 2",
    address2: "Alamat 2",
    picture: "Gambar",
    personalInfo: "Maklumat Peribadi",
    familyMember: "Ahli Keluarga",
    myWallet: "Dompet Saya",
    back: "Kembali",
    next: "Seterusnya",
    save: "Simpan",
    add: "Tambah",
    previous: "Sebelum",
    update: "Kemas Kini",
    memberType: "Jenis Ahli",
    gender: "Jantina",
    contactNo: "No. Kontak",
    bloodGroup: "Kumpulan Darah",
    profileImage: "Gambar Profil",
    nationality: "Kewarganegaraan",
    emergency: "Kecemasan",
    address: "Alamat",
    ownerName: "Nama Pemilik",
    walletNo: "Nombor Dompet",
    activationDate: "Tarikh Pengaktifan",
    totalDeposit: "Jumlah Deposit",
    totalWithdraw: "Jumlah Pengeluaran",
    currentBalance: "Baki Semasa",
    bdt: "BDT",
    date: "Tarikh",
    transactionNo: "Nombor Transaksi",
    receivedBy: "Diterima Oleh",
    depositWithdraw: "Deposit/Pengeluaran",
    amount: "Jumlah",
    eWalletHistory: "Sejarah E-Dompet",
    ownerInformation: "Maklumat Pemilik",
    verifyYourWalletNo: "Sahkan Nombor Dompet Anda",
    sendOtp: "Hantar OTP",
    notVerified: "Belum Disahkan",
    verified: "Disahkan",
    verificationSent: "Pengesahan telah dihantar ke telefon bimbit berdaftar anda",
}

const thai = {
    ownerTenants: "เจ้าของ/ผู้เช่า",
    sl: "ลำดับ",
    name: "ชื่อ",
    image: "ภาพ",
    mobile: "มือถือ",
    email: "อีเมล",
    ownerShipType: "ประเภทการเป็นเจ้าของ",
    walletNumber: "หมายเลขกระเป๋าเงิน",
    eWalletBalance: "ยอดเงินในกระเป๋าเงิน",
    projects: "โครงการ",
    action: "การกระทำ",
    fullName: "ชื่อเต็ม",
    ownershipType: "ประเภทการเป็นเจ้าของ",
    selectProperty: "เลือกทรัพย์สิน",
    fatherName: "ชื่อบิดา",
    phone1: "โทรศัพท์ 1",
    mobile1: "มือถือ 1",
    address1: "ที่อยู่ 1",
    dateOfBirth: "วันเกิด",
    profession: "อาชีพ",
    mothersName: "ชื่อมารดา",
    phone2: "โทรศัพท์ 2",
    mobile2: "มือถือ 2",
    address2: "ที่อยู่ 2",
    picture: "ภาพ",
    personalInfo: "ข้อมูลส่วนตัว",
    familyMember: "สมาชิกครอบครัว",
    myWallet: "กระเป๋าเงินของฉัน",
    back: "กลับ",
    next: "ถัดไป",
    save: "บันทึก",
    add: "เพิ่ม",
    previous: "ก่อนหน้า",
    update: "อัปเดต",
    memberType: "ประเภทสมาชิก",
    gender: "เพศ",
    contactNo: "หมายเลขติดต่อ",
    bloodGroup: "กรุ๊ปเลือด",
    profileImage: "ภาพโปรไฟล์",
    nationality: "สัญชาติ",
    emergency: "ฉุกเฉิน",
    address: "ที่อยู่",
    ownerName: "ชื่อเจ้าของ",
    walletNo: "หมายเลขกระเป๋าเงิน",
    activationDate: "วันที่เปิดใช้งาน",
    totalDeposit: "ยอดเงินฝากทั้งหมด",
    totalWithdraw: "ยอดถอนทั้งหมด",
    currentBalance: "ยอดเงินปัจจุบัน",
    bdt: "บาท",
    date: "วันที่",
    transactionNo: "หมายเลขธุรกรรม",
    receivedBy: "ได้รับโดย",
    depositWithdraw: "ฝาก/ถอน",
    amount: "จำนวนเงิน",
    eWalletHistory: "ประวัติกระเป๋าเงิน",
    ownerInformation: "ข้อมูลเจ้าของ",
    verifyYourWalletNo: "ยืนยันหมายเลขกระเป๋าเงินของคุณ",
    sendOtp: "ส่ง OTP",
    notVerified: "ยังไม่ได้ยืนยัน",
    verified: "ยืนยันแล้ว",
    verificationSent: "การยืนยันถูกส่งไปยังโทรศัพท์มือถือที่ลงทะเบียนของคุณ",
}

const arabic = {
    ownerTenants: "المالك/المستأجرين",
    sl: "SL",
    name: "الاسم",
    image: "صورة",
    mobile : "الهاتف المحمول",
    email: "البريد الإلكتروني",
    ownerShipType: "نوع الملكية",
    walletNumber: "رقم المحفظة",
    eWalletBalance: "رصيد المحفظة الإلكترونية",
    projects: "المشاريع",
    action: "إجراء",
    fullName: "الاسم الكامل",
    ownershipType: "نوع الملكية",
    selectProperty: "اختر العقار",
    fatherName: "اسم الأب",
    phone1: "الهاتف 1",
    mobile1: "الهاتف المحمول 1",
    address1: "العنوان 1",
    dateOfBirth: "تاريخ الميلاد",
    profession: "المهنة",
    mothersName: "اسم الأم",
    phone2: "الهاتف 2",
    mobile2: "الهاتف المحمول 2",
    address2: "العنوان 2",
    picture: "صورة",
    personalInfo: "معلومات شخصية",
    familyMember: "عضو الأسرة",
    myWallet: "محفظتي",
    back : "العودة",
    next : "التالي",
    save : "حفظ",
    add: "إضافة",
    previous : "السابق",
    update: "تحديث",
    memberType: "نوع العضو",
    gender: "الجنس",
    contactNo: "رقم الاتصال",
    bloodGroup: "فصيلة الدم",
    profileImage : "صورة الملف الشخصي",
    nationality: "الجنسية",
    emergency: "حالة الطوارئ",
    address: "العنوان",
    ownerName: "اسم المالك",
    walletNo: "رقم المحفظة",
    activationDate: "تاريخ التفعيل",
    totalDeposit: "إجمالي الإيداع",
    totalWithdraw: "إجمالي السحب",
    currentBalance: "الرصيد الحالي",
    bdt: "بدت",
    date: "التاريخ",
    transactionNo: "رقم المعاملة",
    receivedBy: "استلمت من قبل",
    depositWithdraw: "إيداع/سحب",
    amount: "المبلغ",
    eWalletHistory: "تاريخ المحفظة الإلكترونية",
    ownerInformation: "معلومات المالك",
    verifyYourWalletNo: "تحقق من رقم المحفظة الخاص بك",
    sendOtp: "أرسل OTP",
    notVerified: "غير مُحقق",
    verified: "مُحقق",
    verificationSent: "تم إرسال التحقق إلى هاتفك المحمول المسجل",
}

const bengali = {
    ownerTenants: "মালিক/ভাড়াটিয়া",
    sl: "ক্রমিক",
    name: "নাম",
    image: "ছবি",
    mobile : "মোবাইল",
    email: "ইমেইল",
    ownerShipType: "মালিকানার ধরন",
    walletNumber: "ওয়ালেট নম্বর",
    eWalletBalance: "ই-ওয়ালেট ব্যালেন্স",
    projects: "প্রকল্প",
    action: "কর্ম",
    fullName: "পুরো নাম",
    ownershipType: "মালিকানার ধরন",
    selectProperty: "সম্পত্তি নির্বাচন করুন",
    fatherName: "বাবার নাম",
    phone1: "ফোন ১",
    mobile1: "মোবাইল ১",
    address1: "ঠিকানা ১",
    dateOfBirth: "জন্ম তারিখ",
    profession: "পেশা",
    mothersName: "মায়ের নাম",
    phone2: "ফোন ২",
    mobile2: "মোবাইল ২",
    address2: "ঠিকানা ২",
    picture: "ছবি",
    personalInfo: "ব্যক্তিগত তথ্য",
    familyMember: "পরিবারের সদস্য",
    myWallet: "আমার ওয়ালেট",
    back : "পিছনে",
    next : "পরবর্তী",
    save : "সংরক্ষণ",
    add: "যোগ করুন",
    previous : "পূর্ববর্তী",
    update: "হালনাগাদ",
    memberType: "সদস্যের ধরন",
    gender: "লিঙ্গ",
    contactNo: "যোগাযোগ নম্বর",
    bloodGroup: "রক্তের গ্রুপ",
    profileImage : "প্রোফাইল ছবি",
    nationality: "জাতীয়তা",
    emergency: "জরুরি",
    address: "ঠিকানা",
    ownerName: "মালিকের নাম",
    walletNo: "ওয়ালেট নম্বর",
    activationDate: "সক্রিয়করণ তারিখ",
    totalDeposit: "মোট জমা",
    totalWithdraw: "মোট উত্তোলন",
    currentBalance: "বর্তমান ব্যালেন্স",
    bdt: "বিডিটি",
    date: "তারিখ",
    transactionNo: "লেনদেন নম্বর",
    receivedBy: "গ্রহণ করেছেন",
    depositWithdraw: "জমা/উত্তোলন",
    amount: "পরিমাণ",
    eWalletHistory: "ই-ওয়ালেট ইতিহাস",
    ownerInformation: "মালিকের তথ্য",
    verifyYourWalletNo: "আপনার ওয়ালেট নম্বর যাচাই করুন",
    sendOtp: "OTP পাঠান",
    notVerified: "যাচাই করা হয়নি",
    verified: "যাচাই করা হয়েছে",
    verificationSent: "যাচাইকরণ আপনার নিবন্ধিত মোবাইলে পাঠানো হয়েছে",
}

export const ownerTenantsLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}