import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import '../../../CSS/Invoice.css'
import http from '../../../http';
import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { movementManagementLang } from '../../../language/movementManagement/movementManagementLang';
import useLanguage from '../../../language/useLanguage';
export default function AddMovement() {
    const lan = useLanguage(movementManagementLang);
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [picturePreview, setPicturePreview] = useState(null);

    // project info 
    const [personalInfo, setPersonaInfo] = useState({
        visitor_id: '',
        owner_id: '',
        security_guard_id: '',
        picture: '',
        status: '',
        entry_date_time: '',
        exit_date_time: '',
        owner_device_token: '',
        security_device_token: '',
        visitor_card_number: '',
        remarks: '',
    })
    const handlePersonalInfoChange = (event) => {
        const { name, value } = event.target;
        setPersonaInfo({ ...personalInfo, [name]: value });
    }

    const [error, setError] = useState({
        visitor_id: '',
        owner_id: '',
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('visitor_id', personalInfo.visitor_id)
        formData.append('owner_id', personalInfo.owner_id)
        formData.append('security_guard_id', personalInfo.security_guard_id)
        formData.append('picture', personalInfo.picture)
        formData.append('status', personalInfo.status)
        formData.append('entry_date_time', personalInfo.entry_date_time)
        formData.append('exit_date_time', personalInfo.exit_date_time)
        formData.append('owner_device_token', personalInfo.owner_device_token)
        formData.append('security_device_token', personalInfo.security_device_token)
        formData.append('visitor_card_number', personalInfo.visitor_card_number)
        formData.append('remarks', personalInfo.remarks)

        http.post(`movements`, formData)
            .then((res) => {
                if (res.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: lan?.saveMessage,
                        showConfirmButton: false,
                        timer: 1500
                      })
                    document.getElementById('picture').value = ''
                    setPersonaInfo({
                        visitor_id: '',
                        owner_id: '',
                        security_guard_id: '',
                        picture: '',
                        status: '',
                        entry_date_time: '',
                        exit_date_time: '',
                        owner_device_token: '',
                        security_device_token: '',
                        visitor_card_number: '',
                        remarks: '',
                    })
                    navigate('/movements')
                }
            })
            .catch((err) => {
                console.log(err.response.data.errors.visitor_id, 'err')
                setError({
                    ...error,
                    visitor_id: err.response.data.errors.visitor_id,
                    owner_id: err.response.data.errors.owner_id,
                })

            })
    }
    const [data, setData] = useState([])
    const [visitor, setVisitor] = useState([])
    useEffect(() => {
        http.get(`users/owner`)
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
        http.get(`visitors`)
            .then((res) => {
                setVisitor(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    console.log(data)
    const ownerSelect = (item) => {
        setPersonaInfo({ ...personalInfo, owner_id: item.id })
    }
    const formatResult = (item) => {
        return (
            <>
                <div
                    onClick={() => {
                        ownerSelect(item);
                    }}
                    className={`row d-flex align-items-center search-format  `}
                >
                    <div className='col-6'>
                        <p>
                            {item.name}
                        </p>

                    </div>
                    <div className='col-6'>
                        <p className='ms-2'>{item?.phone}</p>
                    </div>
                    {/* <div className='col-4'>
                        <img src={`${global.img_Url}/images/${item?.picture}`} alt="" />
                    </div> */}
                </div>
            </>
        );
    };
    const formatResultVisitor = (item) => {
        return (
            <>
                <div
                    onClick={() => {
                        setPersonaInfo({ ...personalInfo, visitor_id: item.id });
                    }}
                    className={`row d-flex align-items-center search-format  `}
                >
                    <div className='col-6'>
                        <p>
                            {item.name}
                        </p>

                    </div>
                    <div className='col-6'>
                        <p className='ms-2'>{item?.mobile}</p>
                    </div>
                    {/* <div className='col-4'>
                        <img src={`${global.img_Url}/images/${item?.picture}`} alt="" />
                    </div> */}
                </div>
            </>
        );
    };
    console.log(personalInfo, 'personalInfo')
    const handle_Images_File = (event) => {
        const file = event.target.files[0];

        if (file.size < 1000048) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPicturePreview(reader.result);
            };
            reader.readAsDataURL(file);
            const existing = { ...personalInfo };
            existing["picture"] = file;
            setPersonaInfo(existing);
        } else {
            inputRef.current.value = '';
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'File size exceeds the limit (1MB)',
                showConfirmButton: true,
            })
        }
    };
    return (
        <div className="page-content bg-white">
            <div className="property-register ms-1">
                <form onSubmit={handleSubmit}>
                    <h3 className="text-center my-3">{lan?.movementInformation}</h3>
                    <div className="custom-card p-2">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>{lan?.owner} <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <ReactSearchAutocomplete
                                            required
                                            showIcon={false}
                                            placeholder={'Search Owner'}
                                            items={data}
                                            // resultStringKeyName=
                                            onClear={() => setPersonaInfo({ ...personalInfo, owner_id: '' })}
                                            maxResults={5}
                                            onSelect={(item) => ownerSelect(item)}
                                            autoFocus
                                            fuseOptions={{ keys: ['phone', 'name'] }} // Search in the description text as well
                                            styling={{
                                                borderRadius: '5px !important',
                                                zIndex: 0,
                                                minHeight: '36px',
                                                position: 'static',
                                                height: '36px',
                                                fontSize: '13px',
                                            }}
                                            formatResult={formatResult}
                                        />
                                        {error?.owner_id && <div className='text-danger'>{error?.owner_id}</div>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> {lan?.visitorCardNumber} <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <input required type="text" onChange={handlePersonalInfoChange} value={personalInfo?.visitor_card_number} name='visitor_card_number' className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label htmlFor="address1">{lan?.remarks}</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea name="remarks" onChange={handlePersonalInfoChange} value={personalInfo.remarks} className="form-control form-control-sm" id="" cols="30" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>{lan?.visitor} <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <ReactSearchAutocomplete
                                            showIcon={false}
                                            placeholder={lan?.searchVisitor}
                                            items={visitor}
                                            // resultStringKeyName=
                                            onClear={() => setPersonaInfo({ ...personalInfo, visitor_id: '' })}
                                            maxResults={5}
                                            onSelect={(item) => setPersonaInfo({ ...personalInfo, visitor_id: item.id })}
                                            autoFocus
                                            fuseOptions={{ keys: ['mobile', 'name'] }} // Search in the description text as well
                                            styling={{
                                                borderRadius: '5px !important',
                                                zIndex: 0,
                                                minHeight: '36px',
                                                position: 'static',
                                                height: '36px',
                                                fontSize: '13px',
                                            }}
                                            formatResult={formatResultVisitor}
                                        />
                                        {error?.visitor_id && <div className='text-danger'>{error?.visitor_id}</div>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> {lan?.picture}</label>
                                    </div>
                                    <div className="col-9">
                                        <input type="file" ref={inputRef} id='picture' onChange={(e) => handle_Images_File(e)} accept='image/*' name='picture' className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">

                                    </div>
                                    <div className="col-9">
                                        {picturePreview === null ?
                                            (<></>) :
                                            (< img src={picturePreview} alt="Live Preview" style={{ maxWidth: '100%', height: 'auto' }} width="120" />)
                                        }
                                    </div>
                                </div>


                            </div>
                            <div className="col-12">
                                <div className="d-flex justify-content-end">
                                    <Link to="/movements">
                                        <button type='button' className="btn btn-sm btn-primary me-3 mt-3">{lan?.cancel}</button>
                                    </Link>
                                    <button type='submit' className="btn btn-sm btn-primary me-3 mt-3">{lan?.save}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
