
import MaterialTable from 'material-table'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import http from '../../../http';
import allCustomStyles from '../../../CSS/ReactModalStyle';
import { adminSetupLang } from '../../../language/adminSetup/adminSetupLang';
import useLanguage from '../../../language/useLanguage';

export default function Division() {
    const lan = useLanguage(adminSetupLang);
    const [updateButton, setUpdateButton] = useState(false);
    const { serviceModal } = allCustomStyles;
    const addData = () => {
        setIsOpen(true);
    }
    const deleteRowData = (id) => {
        Swal.fire({
            title: lan?.areYouSure,
            text: lan?.deleteWarning,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: lan?.yesDelete
        })
            .then((result) => {
                if (result.isConfirmed) {
                    http.delete(`division/${id}`)
                        .then((res) => {
                            console.log(res, 'Error')
                            if (res?.data?.status == 500) {
                                Swal.fire({
                                    position: 'top-center',
                                    icon: 'error',
                                    title: 'Error !',
                                    text: res?.data?.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            } else {
                                setUpdate(!update);
                                setIsOpen(false);
                                Swal.fire({
                                    title: lan?.deleted,
                                    text: lan?.deletedMessage,
                                    icon: "success"
                                });
                            }

                        })
                }
            })
    }
    const editData = (id) => {
        http.get(`division/${id}/edit`)
            .then((res) => {
                setInputData({ ...res?.data?.data });
                setIsOpen(true);
                setUpdateButton(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const columns = [
        {
            title: lan?.sl,
            field: "",
            render: (row) => <div>{row?.tableData?.id + 1}</div>,
            width: "20 !important",
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: lan?.name,
            field: `name`,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: lan?.action,
            field: "patient",
            render: (row) => <div>{
                row?.deletable == 1 ? (
                    <></>
                ) : (
                    <>
                        <button
                            onClick={() => editData(row?.id)}
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-edit"></i>
                        </button>

                        <button
                            onClick={() => deleteRowData(row?.id)
                            }
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-trash"></i>
                        </button >
                    </>
                )
            }</div >,
            cellStyle: {
                textAlign: "center",
            },
        },
    ];

    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [spinner, setSpinner] = useState(false);
    useEffect(() => {
        const controller = new AbortController();
        setSpinner(true);
        http
            .get(`division`)
            .then((res) => {
                setData(res?.data?.data)
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });


        return () => {
            controller.abort();
        };
    }, [update]);
    // add modal 

    const [modalIsOpen, setIsOpen] = useState(false);
    const [inputData, setInputData] = useState({
        name: "",
    })

    const handleChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }

    const [isLoading, setisLoading] = useState(false)

    const submitData = (e) => {
        e.preventDefault()
        setisLoading(true)

        if (inputData.id) {
            http.put(`division/${inputData.id}`, inputData)
                .then((res) => {
                    setisLoading(false)
                    setUpdate(!update);
                    setIsOpen(false);
                    setInputData({ name: "", });
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: lan?.updateMessage,
                        showConfirmButton: false,
                        timer: 1500
                      })
                    setUpdateButton(false)
                })
                .catch((err) => {
                    setisLoading(false)
                    console.log(err)
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        } else {
            http.post('division', inputData)
                .then((res) => {
                    setisLoading(false)
                    setUpdate(!update);
                    setIsOpen(false);
                    setInputData({ name: "" });
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: lan?.saveMessage,
                        showConfirmButton: false,
                        timer: 1500
                      })
                })
                .catch((err) => {
                    setisLoading(false)
                    console.log(err)
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        }

    }
    const closeModal = () => {
        setIsOpen(false);
        setUpdateButton(false)
        setInputData({ name: "", });
    }
    return (
        <div className='page-content adjustment-type-table'>
            <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                <h6>{lan?.division}</h6>
                <div>
                    <button style={{ marginTop: "1px" }} onClick={addData} className='btn btn-sm btn-primary float-end'>  {updateButton === true ? lan?.edit : lan?.add}</button>
                </div>
            </div>

            <MaterialTable
                columns={columns}
                data={data}
                isLoading={spinner}
                options={{
                    search: true,
                    showTitle: false,
                    searchFieldAlignment: "left",
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
            />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={serviceModal}
                contentLabel="Example Modal"
            >
                <div className="product_modal">
                    <div className="page-content">
                        <div className=" patients-head ">
                            <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">  {updateButton === true ? lan?.edit : lan?.add}
                                <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={closeModal} className='float-end'><i className="fal fa-times"></i></span>
                            </h5>
                        </div>

                        <div className=" p-3">
                            <form onSubmit={submitData}>
                                <label htmlFor="type" className="mb-1">
                                    {lan?.name}
                                </label>
                                <input onChange={handleChange} value={inputData?.name} name="name" type="text" className="form-control form-control-sm my-2" required placeholder="Title" />
                                {
                                    isLoading ?
                                        <button className="btn mt-2 btn-sm btn-info float-end text-uppercase" type="button"> {lan?.loading} ...</button>
                                        :

                                        updateButton === true ? (
                                            <button className="btn mt-2 btn-sm btn-success float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> {lan?.update}</button>
                                        ) : (
                                            <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> {lan?.save}</button>
                                        )}
                            </form>

                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}
