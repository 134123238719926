import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useState, useRef } from "react";
import "../../../CSS/Invoice.css";
import Select from "react-select";
import {  FaEllipsisV } from "react-icons/fa";
import infoIcon from "../../../front_assets/images/information1.png";
import infoIcon2 from "../../../front_assets/images/information2.png";
import remove from "../../../front_assets/images/delete(1).png";
import http from "../../../http";
import Swal from "sweetalert2";
import AppartmentBillingMapModal from "./AppartmentBillingMapModal";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCircleXmark, FaSackDollar } from "react-icons/fa6";
import allCustomStyles from "../../../CSS/ReactModalStyle";
import { FaPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import './Properties.css'
import RentMap from "./RentMap";
import useLanguage from "../../../language/useLanguage";
import { propertiesLang } from "../../../language/sidebar/propertiesLang";

export default function AddProperties() {
  const { id } = useParams();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { colourStyles } = allCustomStyles;
  const [buildings, setBuildings] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [managers, setManagers] = useState([]);
  const [picturePreview, setPicturePreview] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [unitMeasurement, setUnitMeasurement] = useState([]);
  const inputRef = useRef(null);

  const [ownership, setOwnership] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);

  const handleChangeBuildings = (event, value) => {
    if (value?.length > selectedBuildings?.length) {
      const data = value?.map((item) => {
        if (!item.building_id) {
          return {
            id: "",
            building_id: item.id,
            title: item.title,
          };
        } else {
          return item;
        }
      });
      setSelectedBuildings(data);
    } else {
      const existing = [...selectedBuildings];
      const deleted = existing.filter((item) => !value.includes(item));
      Swal.fire({
        title: lan?.areYouSure,
        text: lan?.deleteWarning,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: lan?.yesDelete
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleted[0]?.id) {
            http.delete(`/delete-building/${deleted[0].id}`).then((res) => {
              // setSelectedBuildings(value);
              if (res.data.status == 500) {
                Swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "Error !",
                  text: res.data.message,
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  title: lan?.deleted,
                  text: lan?.deletedMessage,
                  icon: "success"
              });
              }
              settableInfoUpdate(Math.random());
            });
          } else {
            setSelectedBuildings(value);
          }
        }
      });
    }
  };
  const getOrdinal = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = number % 10;
    const suffix =
      lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13
        ? suffixes[lastDigit]
        : suffixes[0];
    return number + suffix;
  };

  const handleBuildingUnits = (event, index) => {
    const { name, value } = event.target;
    const existing = [...selectedBuildings];
    existing[index][name] = value;
    existing[index]["units"] = [];
    setSelectedBuildings(existing);
  };
  // unit counter
  const handleBuilding = (index) => {
    const existing = [...selectedBuildings];
    const noOfUnit = existing[index].unit_per_floor;
    const noOfFloor = existing[index].total_floors;
    if (existing[index]["units"]?.length < 1) {
      for (let i = 1; i <= noOfFloor; i++) {
        for (let j = 1; j <= noOfUnit; j++) {
          const uniqueId = `${index}-${i}-${j}-${new Date().getTime()}`;
          existing[index]["units"].push({
            id: "",
            u_id: uniqueId,
            floor: i,
            unit: j,
            unit_name: "",
            unit_size: "",
            unit_measurement: "",
            unit_owner: "",
            unit_remarks: "",
            unit_ownership_type: "",
          });
        }
      }
      setSelectedBuildings(existing);
    }
  };
  // remove unit

  // unit controller
  const handleUnitChange = (event, index, i) => {
    const { name, value } = event.target;
    const existing = [...selectedBuildings];
    existing[index]["units"][i][name] = value;
    setSelectedBuildings(existing);
  };
  const handleUnitMeasurementChange = (event, index, i) => {
    const existing = [...selectedBuildings];
    existing[index]["units"][i]["unit_measurement"] = event?.id;
    setSelectedBuildings(existing);
  };

  const floorNameValidation = (event, index, i) => {
    event.preventDefault();
    const { value } = event.target;
    if (value?.length > 0) {
      const existing = [...selectedBuildings];
      const validation = existing[index]["units"]?.filter(
        (v) => v?.unit_name === value
      );
      if (validation?.length > 1) {
        toast.warning("Floor name should be unique!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        existing[index]["units"][i]["unit_name"] = "";
        setSelectedBuildings(existing);
      }
    }
  };
  const handleManagerChange = (event, index) => {
    const { id } = event;
    const existing = [...selectedBuildings];
    existing[index]["manager_1"] = id;
    setSelectedBuildings(existing);
  };
  const handleUnitOwner = (name, event, index, i) => {
    const { id } = event;
    const existing = [...selectedBuildings];
    existing[index]["units"][i][name] = id;
    setSelectedBuildings(existing);
  };
  // contact details
  const [contactDetails, setContactDetails] = useState([
    {
      id: "",
      u_id: "1",
      name: "",
      email: "",
      phone_1: "",
      phone_2: "",
      mobile_1: "",
      mobile_2: "",
      designation: "",
      present_address: "",
      permanent_address: "",
      emergency: "",
    },
  ]);
  const handleAddContactDetails = () => {
    setContactDetails([
      ...contactDetails,
      {
        id: "",
        u_id: `${contactDetails?.length + 1}-${new Date().getTime()}`,
        name: "",
        email: "",
        phone_1: "",
        phone_2: "",
        mobile_1: "",
        mobile_2: "",
        designation: "",
        present_address: "",
        permanent_address: "",
        emergency: "",
      },
    ]);
  };
  const handleRemoveContactDetails = (id) => {
    setContactDetails(contactDetails.filter((item) => item.u_id !== id));
  };
  const handleContactDetailsChange = (event, index) => {
    const { name, value } = event.target;
    const existing = [...contactDetails];
    existing[index][name] = value;
    setContactDetails(existing);
  };
  const handleContactDesignationChange = (event, index) => {
    const { id } = event;
    const existing = [...contactDetails];
    existing[index]["designation"] = id;
    setContactDetails(existing);
  };
  const deleteContact = (id) => {
    Swal.fire({
      title: lan?.areYouSure,
      text: lan?.deleteWarning,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: lan?.yesDelete
    }).then((result) => {
      if (result.isConfirmed) {
        http.delete(`/delete-contact/${id}`).then((res) => {
          if (res.data.status === 200) {
            setContactDetails(contactDetails.filter((item) => item.id !== id));
          }
        });
        Swal.fire({
          title: lan?.deleted,
          text: lan?.deletedMessage,
          icon: "success"
      });
      }
    });
  };

  // project info
  const [projectInfo, setProjectInfo] = useState({
    id: "",
    property_type_id: "",
    property_type_name: "",
    company_name: "",
    property_name: "",
    division: "",
    address_line_1: "",
    address_line_2: "",
    district: "",
    police_station: "",
    area: "",
    logo: "",
    about: "",
    area_name: "",
    police_station_name: "",
    district_name: "",
    division_name: "",
  });
  const handleProjectInfoChange = (event) => {
    const { name, value } = event.target;
    setProjectInfo({ ...projectInfo, [name]: value });
  };

  // project info
  const handleNextPage = (id) => {
    document.getElementById(id).click();
  };
  const [isLoding, setisLoding] = useState(false);

  const handleSubmit = (e) => {
    setisLoding(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", projectInfo?.id);
    formData.append("property_type_id", projectInfo?.property_type_id);
    formData.append("company_name", projectInfo?.company_name);
    formData.append("property_name", projectInfo?.property_name);
    formData.append("division", projectInfo?.division);
    formData.append("address_line_1", projectInfo?.address_line_1);
    formData.append("address_line_2", projectInfo?.address_line_2);
    formData.append("district", projectInfo?.district);
    formData.append("police_station", projectInfo?.police_station);
    formData.append("area", projectInfo?.area);
    formData.append("logo", projectInfo?.logo);
    formData.append("about", projectInfo?.about);
    if (selectedBuildings?.length >= 0) {
      formData.append("buildings", JSON.stringify(selectedBuildings));
    }
    if (contactDetails?.length >= 1) {
      formData.append("contacts", JSON.stringify(contactDetails));
    }

    http
      .post("properties", formData)
      .then((res) => {
        setisLoding(false);
        setSelectedBuildings([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: lan?.saveMessage,
          showConfirmButton: false,
          timer: 1500
        })
        getSinglePropertyData(res.data.data.id);
        settableInfoUpdate(Math.random());
      })
      .catch((err) => {
        setisLoding(false);
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: "Error !",
          text: `${err.response.data.message}`,
          showConfirmButton: false,
        });
      });
  };
  const [owners, setOwners] = useState([]);
  const [tenant, setTenant] = useState([]);
  const [division, setDivision] = useState([]);
  const [area, setArea] = useState([]);
  const [district, setDistrict] = useState([]);
  const [policeStations, setPoliceStations] = useState([]);
  const [tableInfoUpdate, settableInfoUpdate] = useState();
  useEffect(() => {
    setSpinner(true);
    if (id !== "null") {
      getSinglePropertyData(id);
    }
    http
      .get(`property-setup-data/${id !== "null" ? id : "null"}`)
      .then((res) => {
        setDivision(res?.data?.data?.divi);
        setDesignation(res.data.data.desig);
        setUnitMeasurement(res.data.data.um);
        setPropertyType(res.data.data.pT);

        if (res.data.data.owners.length > 0) {
          const ow = res.data.data.owners?.map(v => v.owners).filter(
            (v) => v?.owner_ships_type?.name === "Owner"
          );
          console.log("owner", ow)
          setOwners(ow);
          const te = res.data.data.owners?.map(v => v.owners).filter(
            (v) => v?.owner_ships_type?.name === "Tenant"
          );
          setTenant(te);
        }

        setManagers(res.data.data.employees);
        setBuildings(res?.data?.data.building);
        setOwnership(res?.data?.data.oST);

        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [tableInfoUpdate]);

  const getSinglePropertyData = (propertyId) => {
    http.get(`property/${propertyId}`).then((res) => {
      if (res.status === 200) {
        setProjectInfo(res.data);
        setProjectInfo({
          ...projectInfo,
          id: res?.data?.id,
          property_type_id: res?.data?.property_type_id,
          property_type_name: res?.data?.property_types?.name,
          company_name: res?.data?.company_name,
          property_name: res?.data?.property_name,
          division: res?.data?.division,
          division_name: res?.data?.divisions?.name,
          address_line_1: res?.data?.address_line_1,
          address_line_2: res?.data?.address_line_2,
          district: res?.data?.district,
          district_name: res?.data?.districts?.name,
          police_station: res?.data?.police_station,
          police_station_name: res?.data?.police_stations?.police_station,
          area: res?.data?.area,
          area_name: res?.data?.areas?.area,
          logo: res?.data?.logo,
          about: res?.data?.about,
        });

        const divisionID = res?.data?.division;
        const districtID = res?.data?.district;
        const areaId = res?.data?.area;
        console.log(areaId, "areaId");
        setSelectedBuildings(res?.data?.building);
        setContactDetails(res?.data?.contact);
        if (res?.data?.logo) {
          setPicturePreview(`${global.img_Url}/images/${res.data?.logo}`);
        }

        http.get("district").then((res) => {
          const data = res?.data?.data;
          const districtData = data?.filter(
            (item) => item?.division_id == divisionID
          );
          setDistrict(districtData);
        });
        http.get("area").then((res) => {
          const data = res?.data?.data;
          const areaData = data?.filter(
            (item) => item?.district_id == districtID
          );
          setArea(areaData);
        });
        http.get("police-stations").then((res) => {
          const data = res?.data?.data;
          const policeStationsData = data?.filter(
            (item) => item?.area_id == areaId
          );
          console.log("policeStationsData", policeStationsData);
          setPoliceStations(policeStationsData);
        });
        setSpinner(false);
      }
    });
  };

  const [modalIsOpenAppBilling, setModalIsOpenAppBilling] = useState(false);

  const openModalAppBilling = () => {
    setModalIsOpenAppBilling(true);
  };

  const closeModalAppBilling = () => {
    setModalIsOpenAppBilling(false);
  };

  const [rentMapModal, setrentMapModal] = useState(false);

  const openModalRentMap = () => {
    setrentMapModal(true);
  };

  const closeModalRentMap = () => {
    setrentMapModal(false);
  };

  const [floorInfoProps, setfloorInfoProps] = useState({});
  const [buildingInfo, setbuildingInfo] = useState({});

  const handle_Images_File = (event) => {
    const file = event.target.files[0];

    if (file.size < 1000048) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPicturePreview(reader.result);
      };
      reader.readAsDataURL(file);
      const existing = { ...projectInfo };
      existing["logo"] = file;
      setProjectInfo(existing);
    } else {
      inputRef.current.value = "";
      Swal.fire({
        position: "top-center",
        icon: "error",
        title: "Deleted!",
        text: "File size exceeds the limit (1MB).",
        showConfirmButton: true,
      });
    }
  };

  const handleDivisionChange = (e, value) => {
    setProjectInfo({ ...projectInfo, ["division"]: value?.id });
    if (value?.district?.length == 0) {
      setProjectInfo({
        ...projectInfo,
        district: "",
        district_name: "",
        police_station: "",
        area: "",
        area_name: "",
        police_station_name: "",
      });
      setDistrict([]);
      setArea([]);
      setPoliceStations([]);
      console.log(value, "division1");
    } else {
      setDistrict(value?.district);
    }
  };
  const handleDistrictChange = (e, value) => {
    setProjectInfo({ ...projectInfo, ["district"]: value?.id });
    if (value?.area?.length == 0) {
      setProjectInfo({
        ...projectInfo,
        police_station: "",
        area: "",
        area_name: "",
        police_station_name: "",
      });
      console.log(value, "value12");
      setArea([]);
      setPoliceStations([]);
    } else {
      setArea(value?.area);
    }
  };
  const handleAreaChange = (e, value) => {
    console.log(value, "value1");
    setProjectInfo({ ...projectInfo, ["area"]: value?.id });
    setPoliceStations(value?.police_stations);
  };

  const handleOwnerChange = (event, index, i) => {
    const existing = [...selectedBuildings];
    existing[index]["units"][i]["unit_ownership_type"] = event?.id;
    setSelectedBuildings(existing);

    // setOwners(event?.owners)

    // if (event?.name === "Owner") {
    //     setOwners(event?.owners)
    // } else {
    //     setTenant(event?.owners)
    // }
  };


  const [activeIndexBuilding, setactiveIndexBuilding] = useState(0)
const lan = useLanguage(propertiesLang);
  return (
    <div className="page-content bg-white">
      {spinner && <LinearProgress />}
      <div className="property-register ms-1">
        {/* <form action=""> */}
        <ToastContainer />
        <div className="row">
          <h3 className="text-center my-3">{lan?.projectInformation}</h3>
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                {lan?.projectInfo}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                {lan?.projectDetails}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                {lan?.contactDetails}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>
                          {lan?.propertyType} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={propertyType}
                          getOptionLabel={(option) => option?.name}
                          sx={{ width: "100%" }}
                          size="small"
                          onChange={(event, value) => {
                            setProjectInfo({
                              ...projectInfo,
                              property_type_id: value.id,
                            });
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={projectInfo?.property_type_name}
                              required={
                                projectInfo?.property_type_id?.length === 0
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>
                          {lan?.companyName}<span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <input
                          required
                          type="text"
                          onChange={handleProjectInfoChange}
                          value={projectInfo.company_name}
                          name="company_name"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>

                          {lan?.name}<span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <input
                          required
                          type="text"
                          onChange={handleProjectInfoChange}
                          value={projectInfo.property_name}
                          name="property_name"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <label>

                          {lan?.division} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <Autocomplete
                          required
                          disablePortal
                          id="combo-box-demo"
                          options={division}
                          getOptionLabel={(option) => option?.name}
                          sx={{ width: "100%" }}
                          size="small"
                          onChange={(event, value) => {
                            handleDivisionChange(event, value);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value?.name
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={projectInfo?.division_name}
                              required={projectInfo?.division?.length === 0}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>

                          {lan?.district} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <Autocomplete
                          required
                          disablePortal
                          id="combo-box-demo"
                          options={district}
                          getOptionLabel={(option) => option?.name}
                          sx={{ width: "100%" }}
                          size="small"
                          onChange={(event, value) => {
                            handleDistrictChange(event, value);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.name
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={projectInfo?.district_name}
                              required={projectInfo?.district?.length === 0}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <label>

                          {lan?.area} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <Autocomplete
                          required
                          disablePortal
                          id="combo-box-demo"
                          options={area}
                          getOptionLabel={(option) => option?.area}
                          sx={{ width: "100%" }}
                          size="small"
                          onChange={(event, value) => {
                            handleAreaChange(event, value);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.area
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={projectInfo?.area_name}
                              required={projectInfo?.area?.length === 0}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label>

                          {lan?.policeStation} <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <Autocomplete
                          required
                          disablePortal
                          id="combo-box-demo"
                          options={policeStations}
                          getOptionLabel={(option) => option?.police_station}
                          sx={{ width: "100%" }}
                          size="small"
                          onChange={(event, value) => {
                            setProjectInfo({
                              ...projectInfo,
                              police_station: value?.id,
                            });
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={projectInfo?.police_station_name}
                              required={
                                projectInfo?.police_station?.length === 0
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-3">
                        <label className="">{lan?.buildingNo}</label>
                      </div>
                      <div className="col-9">
                        <div style={{ width: "100%" }}>
                          <Autocomplete
                            multiple
                            options={buildings}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.title}
                            onChange={handleChangeBuildings}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.title}
                              </li>
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.title === value.title
                            }
                            size="small"
                            value={selectedBuildings}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={lan?.buildings}
                                placeholder="Selected Buildings"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label htmlFor="about">{lan?.about}</label>
                      </div>
                      <div className="col-9">
                        <textarea
                          name="about"
                          onChange={handleProjectInfoChange}
                          value={projectInfo?.about}
                          className="form-control form-control-sm"
                          id=""
                          cols="30"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <label htmlFor="address1">
                          {lan?.address1}<span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-9">
                        <textarea
                          required
                          name="address_line_1"
                          onChange={handleProjectInfoChange}
                          value={
                            projectInfo?.address_line_1 == "null"
                              ? ""
                              : projectInfo?.address_line_1
                          }
                          className="form-control form-control-sm"
                          id=""
                          cols="30"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label htmlFor="address1">{lan?.address2}</label>
                      </div>
                      <div className="col-9">
                        <textarea
                          name="address_line_2"
                          onChange={handleProjectInfoChange}
                          value={
                            projectInfo?.address_line_2 == "null"
                              ? ""
                              : projectInfo?.address_line_2
                          }
                          className="form-control form-control-sm"
                          id=""
                          cols="30"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-3">
                        <label htmlFor="address1">{lan?.logo}</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="file"
                          ref={inputRef}
                          onChange={(e) => handle_Images_File(e)}
                          id="image"
                          name="image"
                          className="form-control form-control-sm mb-2"
                          accept=".png, .jpg, .jpeg .webp"
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3"></div>
                      <div className="col-9">
                        {picturePreview === null ? (
                          <></>
                        ) : (
                          <img
                            src={picturePreview}
                            alt="Live Preview"
                            style={{ maxWidth: "100%", height: "auto" }}
                            width="120"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-end">
                      <Link
                        to="/properties"
                        className="btn btn-sm btn-primary me-1"
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                          marginTop: "-5px",
                        }}
                      >
                        {lan?.back}
                      </Link>
                      <button
                        type="button"
                        onClick={() => handleNextPage("pills-profile-tab")}
                        className="btn btn-sm btn-primary me-1"
                      >
                        {lan?.next}
                      </button>

                      {isLoding ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                        >
                          {lan?.save}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >


              <ul class="nav nav-tabs building-tabs" id="myTab" role="tablist">

                {
                  selectedBuildings?.length > 0 && selectedBuildings?.map((v, i) => {
                    return <li key={i} class="nav-item" role="presentation" onClick={() => setactiveIndexBuilding(i)}>
                      <button class={`nav-link buildings-nav ${activeIndexBuilding === i ? 'active' : ''}`} id={`complex-tab-${i + 1}`} data-bs-toggle="tab" data-bs-target={`#complex${i + 1}`} type="button" role="tab" aria-controls={`complex${i + 1}`} aria-selected="true">{v?.title}</button>
                    </li>
                  })
                }


              </ul>
              <form onSubmit={handleSubmit}>
                <div class="tab-content" id="myTabContent">
                  {
                    selectedBuildings?.length > 0 && selectedBuildings?.map((building, index) => {
                      return <div key={index} class={`tab-pane fade ${activeIndexBuilding === index ? 'show active' : ''}`} id="complex1" role="tabpanel" aria-labelledby="complex-tab-1">

                        <div className="row mt-2">
                          <div className="col-3">
                            <div className="row mb-2">
                              <div className="col-3">
                                <label>{lan?.manager}</label>
                              </div>
                              <div className="col-9">
                                <Select
                                  className="basic-single mb-2"
                                  onChange={(e) => handleManagerChange(e, index)}
                                  styles={colourStyles}
                                  value={managers?.find(
                                    (manager) => manager.id == building?.manager_1
                                  )}
                                  options={managers}
                                  getOptionLabel={(option) => option.name}
                                />
                              </div>
                            </div>
                          </div>
                          {building?.units?.length > 0 ? (
                            <>
                              <div className="col-3">
                                <div className="row mb-2">
                                  <div className="col-5">
                                    <label> {lan?.totalUnitFloor}</label>
                                  </div>
                                  <div className="col-7">
                                    <input
                                      type="number"
                                      onChange={(e) =>
                                        handleBuildingUnits(e, index)
                                      }
                                      value={building?.unit_per_floor}
                                      name="unit_per_floor"
                                      disabled={
                                        building?.units[0]?.id ? true : false
                                      }
                                      className="form-control form-control-sm"
                                      style={{ padding: "8.5px 10px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <label> {lan?.totalFloor}</label>
                                  </div>
                                  <div className="col-8">
                                    <input
                                      type="number"
                                      onChange={(e) =>
                                        handleBuildingUnits(e, index)
                                      }
                                      value={building?.total_floors}
                                      name="total_floors"
                                      disabled={
                                        building?.units[0]?.id ? true : false
                                      }
                                      className="form-control form-control-sm"
                                      style={{ padding: "8.5px 10px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-3">
                                <div className="row mb-2">
                                  <div className="col-5">
                                    <label> {lan?.totalUnitFloor}</label>
                                  </div>
                                  <div className="col-7">
                                    <input
                                      type="number"
                                      onChange={(e) =>
                                        handleBuildingUnits(e, index)
                                      }
                                      value={building?.unit_per_floor}
                                      name="unit_per_floor"
                                      disabled={
                                        building?.units?.id ? true : false
                                      }
                                      className="form-control form-control-sm"
                                      style={{ padding: "8.5px 10px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <label> {lan?.totalFloor}</label>
                                  </div>
                                  <div className="col-8">
                                    <input
                                      type="number"
                                      onChange={(e) =>
                                        handleBuildingUnits(e, index)
                                      }
                                      value={building?.total_floors}
                                      name="total_floors"
                                      disabled={
                                        building?.units?.id ? true : false
                                      }
                                      className="form-control form-control-sm"
                                      style={{ padding: "8.5px 10px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-1">
                            <button
                              onClick={() => handleBuilding(index)}
                              className="btn btn-sm btn-primary me-3"
                              style={{ marginTop: "0.5px" }}
                            >
                              {lan?.apply}
                            </button>
                          </div>
                          {building?.units?.length > 0 &&
                            building?.units[0].id && (
                              <div className="col-2">
                                <div className="row mb-2">
                                  <div className="form-check">
                                    <input
                                      onChange={() => {
                                        if (
                                          Number(
                                            building?.is_billing_map_for_all
                                          ) === 0
                                        ) {
                                          setbuildingInfo(building);
                                          openModalAppBilling();
                                        } else {
                                          Swal.fire({
                                            title: lan?.areYouSure,
                                            text: lan?.deleteWarning,
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: lan?.yesDelete
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              const data = {
                                                id: building?.id,
                                                building_id:
                                                  building?.units[0].building_id,
                                              };

                                              http
                                                .post(
                                                  "appoitment-building-fee-delete-all",
                                                  data
                                                )
                                                .then((res) => {
                                                  settableInfoUpdate(
                                                    Math.random()
                                                  );
                                                  Swal.fire({
                                                    title: lan?.deleted,
                                                    text: lan?.deletedMessage,
                                                    icon: "success"
                                                });
                                                });
                                            }
                                          });
                                        }
                                      }}
                                      checked={
                                        Number(
                                          building?.is_billing_map_for_all
                                        ) === 0
                                          ? false
                                          : true
                                      }
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {lan?.billingMapForAll}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>

                        <div className="unit-manager-table invoice-item-table mb-3">
                          <table className="">
                            <tr>
                              <td>{lan?.sl}</td>
                              <td>{lan?.floor}</td>
                              <td>{lan?.unit}</td>
                              <td>{lan?.unitSize}</td>
                              <td>{lan?.um}</td>
                              <td>{lan?.ownershipType}</td>
                              <td>{lan?.owner}</td>
                              <td>{lan?.tenant}</td>
                              <td>{lan?.remarks}</td>
                              <td>{lan?.billMapStatus}</td>
                              <td>{lan?.action}</td>
                            </tr>
                            {building?.units &&
                              building?.units?.map((unit, i) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td width="10%">
                                    {getOrdinal(unit?.floor)} {lan?.floor}
                                  </td>
                                  <td width="10%">
                                    <input
                                      required
                                      onBlur={(e) =>
                                        floorNameValidation(e, index, i)
                                      }
                                      onChange={(e) =>
                                        handleUnitChange(e, index, i)
                                      }
                                      value={unit?.unit_name}
                                      type="text"
                                      name="unit_name"
                                      className="form-control form-control-sm border-none text-uppercase"
                                    />
                                  </td>
                                  <td width="10%">
                                    <input
                                      required
                                      onChange={(e) =>
                                        handleUnitChange(e, index, i)
                                      }
                                      value={unit?.unit_size}
                                      type="number"
                                      name="unit_size"
                                      className="form-control form-control-sm "
                                    />
                                  </td>
                                  <td width="10%">
                                    {/* <select required onChange={(e) =>
                                      handleUnitMeasurementChange(e, index, i)
                                    } value={unit?.unit_measurement} name='unit_measurement' className="form-select form-select-sm">
                                      <option value="">Select</option>
                                      {unitMeasurement?.map((v, i) => {
                                        return <option value={v.id}>{v.name}</option>
                                      })}


                                    </select> */}
                                    <Select
                                      styles={colourStyles}
                                      name="unit_measurement"
                                      required
                                      onChange={(e) =>
                                        handleUnitMeasurementChange(e, index, i)
                                      }
                                      options={unitMeasurement}
                                      value={unitMeasurement.find(
                                        (e) => e.id == unit?.unit_measurement
                                      )}
                                      getOptionLabel={(option) => option?.name}
                                    />
                                  </td>
                                  <td className="" width="15%">
                                    <Select
                                      styles={colourStyles}
                                      name="ownership-type"
                                      required
                                      onChange={(e) =>
                                        handleOwnerChange(e, index, i)
                                      }
                                      options={ownership}
                                      value={ownership.find(
                                        (e) => e.id == unit?.unit_ownership_type
                                      )}
                                      getOptionLabel={(option) => option?.name}
                                    />
                                  </td>
                                  <td className="" width="15%">
                                    <Select
                                      styles={colourStyles}
                                      required
                                      onChange={(e) =>
                                        handleUnitOwner("unit_owner", e, index, i)
                                      }
                                      value={owners?.find(
                                        (e) => e.id == unit?.unit_owner
                                      )}
                                      options={owners}
                                      getOptionLabel={(option) => option?.name}
                                      isClearable
                                    />
                                  </td>
                                  <td className="" width="15%">
                                    <Select
                                      styles={colourStyles}
                                      onChange={(e) =>
                                        handleUnitOwner(
                                          "unit_tenant",
                                          e,
                                          index,
                                          i
                                        )
                                      }
                                      value={tenant?.find(
                                        (e) => e.id == unit?.unit_tenant
                                      )}
                                      options={tenant}
                                      getOptionLabel={(option) => option.name}
                                      isClearable
                                    />
                                  </td>
                                  <td width="10%">
                                    <input
                                      onChange={(e) =>
                                        handleUnitChange(e, index, i)
                                      }
                                      type="text"
                                      value={unit?.unit_remarks}
                                      name="unit_remarks"
                                      className="form-control form-control-sm"
                                    />
                                  </td>
                                  <td width="5%">
                                    {unit?.is_bill_map_with_fee_setup ==
                                      undefined ? (
                                      ""
                                    ) : Number(
                                      unit?.is_bill_map_with_fee_setup
                                    ) === 0 ? (
                                      <FaRegCircleXmark
                                        onClick={() => {
                                          setfloorInfoProps(unit);
                                          openModalAppBilling();
                                        }}
                                        style={{
                                          fontSize: "14px",
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                      />
                                    ) : (
                                      <FaCheckCircle
                                        onClick={() => {
                                          Swal.fire({
                                            title: "Are you sure?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Yes, delete it!",
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              if (unit?.id) {
                                                http
                                                  .delete(
                                                    `/delete-bill-map-data/${unit.id}`
                                                  )
                                                  .then((res) => {
                                                    // setSelectedBuildings(value);
                                                    settableInfoUpdate(
                                                      Math.random()
                                                    );
                                                  });
                                              }
                                              Swal.fire({
                                                position: "top-center",
                                                icon: "success",
                                                title: "Deleted!",
                                                text: "Your data has been deleted.",
                                                timer: 2500,
                                              });
                                            }
                                          });
                                        }}
                                        style={{
                                          fontSize: "14px",
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td className="">
                                    <ul className="navbar-nav">
                                      <li className="nav-item dropdown">
                                        <a
                                          className="nav-link dropdown-toggle"
                                          href="#"
                                          id="languageDropdown"
                                          role="button"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <span className="ms-1 me-1 d-none d-md-inline-block">

                                            <FaEllipsisV />
                                          </span>
                                        </a>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="languageDropdown"
                                        >
                                          {unit?.id && (
                                            <>
                                              <a
                                                href="javascript:;"
                                                onClick={() => {
                                                  setfloorInfoProps(unit);
                                                  openModalRentMap();
                                                }}
                                                className="cursor-pointer dropdown-item py-2"
                                              >
                                                <img
                                                  src={infoIcon2}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                                {/* <CiBadgeDollar className="menu-icon" /> */}
                                                <span className="ms-1">

                                                  {lan?.rentMap}
                                                </span>
                                              </a>
                                              <a
                                                href="javascript:;"
                                                onClick={() => {
                                                  setfloorInfoProps(unit);
                                                  openModalAppBilling();
                                                }}
                                                className="cursor-pointer dropdown-item py-2"
                                              >
                                                <img
                                                  src={infoIcon2}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                                {/* <FaDolly className="menu-icon" /> */}
                                                <span className="ms-1">

                                                  {lan?.billingMap}
                                                </span>
                                              </a>
                                            </>
                                          )}

                                          <a
                                            href="javascript:;"
                                            className="cursor-pointer dropdown-item py-2"
                                          >
                                            <img
                                              src={infoIcon}
                                              alt=""
                                              className="img-fluid"
                                            />
                                            <span className="ms-1">

                                              {lan?.ownerDetails}
                                            </span>
                                          </a>
                                          <a
                                            href="javascript:;"
                                            className="cursor-pointer dropdown-item py-2"
                                          >
                                            <img
                                              src={infoIcon2}
                                              alt=""
                                              className="img-fluid"
                                            />
                                            <span className="ms-1">

                                              {lan?.tenantDetails}
                                            </span>
                                          </a>
                                          <button
                                            onClick={() => {
                                              console.log("units index", i);
                                              console.log(
                                                "units add",
                                                selectedBuildings[index]?.units
                                              );

                                              const ext = [...selectedBuildings];
                                              const obj = {
                                                id: "",
                                                u_id: "",
                                                floor: unit?.floor,
                                                unit: "",
                                                unit_name: "",
                                                unit_size: "",
                                                unit_measurement: "",
                                                unit_owner: "",
                                                unit_remarks: "",
                                                unit_ownership_type: "",
                                              };
                                              ext[index]?.units.splice(
                                                i + 1,
                                                0,
                                                obj
                                              );
                                              setSelectedBuildings(ext);
                                            }}
                                            className="cursor-pointer dropdown-item py-2"
                                          >
                                            <img
                                              src={infoIcon2}
                                              alt=""
                                              className="img-fluid"
                                            />
                                            <span className="ms-1">

                                              {lan?.addUnit}
                                            </span>
                                          </button>
                                          {unit?.id === "" && (
                                            <a
                                              onClick={() => {
                                                const existing = [
                                                  ...selectedBuildings,
                                                ];
                                                existing[index]["units"].splice(
                                                  i,
                                                  1
                                                );
                                                // existing[index].total_units = existing[index]?.units?.length;

                                                // existing[index].total_floors = Math.ceil(Number(existing[index]?.units?.length) / Number(existing[index]?.unit_per_floor));
                                                setSelectedBuildings(existing);
                                              }}
                                              className="cursor-pointer dropdown-item py-2"
                                            >

                                              <img
                                                src={remove}
                                                alt=""
                                                className="img-fluid"
                                              />
                                              <span className="ms-1">

                                                {lan?.remove}
                                              </span>
                                            </a>
                                          )}
                                        </div>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                          </table>
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            onClick={() => {
                              const existing = [...selectedBuildings];
                              const floors =
                                Number(existing[index].total_floors) + 1;
                              const data = existing[index];
                              for (
                                let i = 1;
                                i <= Number(data?.unit_per_floor);
                                i++
                              ) {
                                existing[index]["units"].push({
                                  id: "",
                                  u_id: "",
                                  floor: floors,
                                  unit: i,
                                  unit_name: "",
                                  unit_size: "",
                                  unit_measurement: "",
                                  unit_owner: "",
                                  unit_remarks: "",
                                  unit_ownership_type: "",
                                });
                              }

                              existing[index].total_floors = floors;
                              existing[index].total_units =
                                existing[index]?.units?.length;
                              setSelectedBuildings(existing);
                            }}
                            className="btn btn-sm btn-outline-success"
                          >
                            <FaPlus />
                          </button>
                        </div>


                      </div>
                    })
                  }


                </div>
                <div className="col-12 mt-2">
                  <div className="d-flex justify-content-end">
                    <Link
                      to="/properties"
                      className="btn btn-sm btn-primary me-1"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        marginTop: "0px",
                      }}
                    >
                      {lan?.back}
                    </Link>
                    <button
                      type="button"
                      onClick={() => handleNextPage("pills-home-tab")}
                      className="btn btn-sm btn-primary me-1 mt-0"
                    >
                      {lan?.previous}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleNextPage("pills-contact-tab")}
                      className="btn btn-sm btn-primary me-1 mt-0 "
                    >
                      {lan?.next}
                    </button>
                    {selectedBuildings?.length > 0 && (
                      <>
                        {isLoding ? (
                          <button
                            type="button"
                            className="btn btn-sm btn-primary me-1 mt-0"
                          >
                            {lan?.loading}...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary me-1 mt-0"
                          >
                            {lan?.save}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </form>

              {/* <form onSubmit={handleSubmit}>
                {selectedBuildings?.length > 0 &&
                  selectedBuildings?.map((building, index) => (
                    <div key={index} className="mb-4">
                      <div className="row mt-1">
                        <h6 className="mb-3 justify-content-around text-center">
                          Complex : {building?.title}
                        </h6>
                        <div className="col-3">
                          <div className="row mb-2">
                            <div className="col-3">
                              <label>Manager</label>
                            </div>
                            <div className="col-9">
                              <Select
                                className="basic-single mb-2"
                                onChange={(e) => handleManagerChange(e, index)}
                                styles={colourStyles}
                                value={managers?.find(
                                  (manager) => manager.id == building?.manager_1
                                )}
                                options={managers}
                                getOptionLabel={(option) => option.name}
                              />
                            </div>
                          </div>
                        </div>
                        {building?.units?.length > 0 ? (
                          <>
                            <div className="col-3">
                              <div className="row mb-2">
                                <div className="col-5">
                                  <label> Total Unit / Floor</label>
                                </div>
                                <div className="col-7">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      handleBuildingUnits(e, index)
                                    }
                                    value={building?.unit_per_floor}
                                    name="unit_per_floor"
                                    disabled={
                                      building?.units[0]?.id ? true : false
                                    }
                                    className="form-control form-control-sm"
                                    style={{ padding: "8.5px 10px" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row mb-2">
                                <div className="col-4">
                                  <label> Total Floor</label>
                                </div>
                                <div className="col-8">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      handleBuildingUnits(e, index)
                                    }
                                    value={building?.total_floors}
                                    name="total_floors"
                                    disabled={
                                      building?.units[0]?.id ? true : false
                                    }
                                    className="form-control form-control-sm"
                                    style={{ padding: "8.5px 10px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-3">
                              <div className="row mb-2">
                                <div className="col-5">
                                  <label> Total Unit / Floor</label>
                                </div>
                                <div className="col-7">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      handleBuildingUnits(e, index)
                                    }
                                    value={building?.unit_per_floor}
                                    name="unit_per_floor"
                                    disabled={
                                      building?.units?.id ? true : false
                                    }
                                    className="form-control form-control-sm"
                                    style={{ padding: "8.5px 10px" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row mb-2">
                                <div className="col-4">
                                  <label> Total Floor</label>
                                </div>
                                <div className="col-8">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      handleBuildingUnits(e, index)
                                    }
                                    value={building?.total_floors}
                                    name="total_floors"
                                    disabled={
                                      building?.units?.id ? true : false
                                    }
                                    className="form-control form-control-sm"
                                    style={{ padding: "8.5px 10px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-1">
                          <button
                            onClick={() => handleBuilding(index)}
                            className="btn btn-sm btn-primary me-3"
                            style={{ marginTop: "0.5px" }}
                          >
                            Apply
                          </button>
                        </div>
                        {building?.units?.length > 0 &&
                          building?.units[0].id && (
                            <div className="col-2">
                              <div className="row mb-2">
                                <div className="form-check">
                                  <input
                                    onChange={() => {
                                      if (
                                        Number(
                                          building?.is_billing_map_for_all
                                        ) === 0
                                      ) {
                                        setbuildingInfo(building);
                                        openModalAppBilling();
                                      } else {
                                        Swal.fire({
                                          title: "Are you sure?",
                                          text: "Delete bill map for all appartment",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Yes, delete it!",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            const data = {
                                              id: building?.id,
                                              building_id:
                                                building?.units[0].building_id,
                                            };

                                            http
                                              .post(
                                                "appoitment-building-fee-delete-all",
                                                data
                                              )
                                              .then((res) => {
                                                settableInfoUpdate(
                                                  Math.random()
                                                );
                                                Swal.fire({
                                                  position: "top-center",
                                                  icon: "success",
                                                  title: "Deleted!",
                                                  text: "Your data has been deleted.",
                                                  timer: 2500,
                                                });
                                              });
                                          }
                                        });
                                      }
                                    }}
                                    checked={
                                      Number(
                                        building?.is_billing_map_for_all
                                      ) === 0
                                        ? false
                                        : true
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue
                                    id="flexCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    Billing Map for All
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="unit-manager-table invoice-item-table mb-3">
                        <table className="">
                          <tr>
                            <td>SL</td>
                            <td>Floor</td>
                            <td>Unit</td>
                            <td>Unit Size</td>
                            <td>UM</td>
                            <td>Ownership Type</td>
                            <td>Owner</td>
                            <td>Tenant</td>
                            <td>Remarks</td>
                            <td>Bill Map Status</td>
                            <td>Action</td>
                          </tr>
                          {building?.units &&
                            building?.units?.map((unit, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td width="10%">
                                  {getOrdinal(unit?.floor)} Floor
                                </td>
                                <td width="10%">
                                  <input
                                    required
                                    onBlur={(e) =>
                                      floorNameValidation(e, index, i)
                                    }
                                    onChange={(e) =>
                                      handleUnitChange(e, index, i)
                                    }
                                    value={unit?.unit_name}
                                    type="text"
                                    name="unit_name"
                                    className="form-control form-control-sm border-none text-uppercase"
                                  />
                                </td>
                                <td width="10%">
                                  <input
                                    required
                                    onChange={(e) =>
                                      handleUnitChange(e, index, i)
                                    }
                                    value={unit?.unit_size}
                                    type="number"
                                    name="unit_size"
                                    className="form-control form-control-sm "
                                  />
                                </td>
                                <td width="10%">

                                  <Select
                                    styles={colourStyles}
                                    name="unit_measurement"
                                    required
                                    onChange={(e) =>
                                      handleUnitMeasurementChange(e, index, i)
                                    }
                                    options={unitMeasurement}
                                    value={unitMeasurement.find(
                                      (e) => e.id == unit?.unit_measurement
                                    )}
                                    getOptionLabel={(option) => option?.name}
                                  />
                                </td>
                                <td className="" width="15%">
                                  <Select
                                    styles={colourStyles}
                                    name="ownership-type"
                                    required
                                    onChange={(e) =>
                                      handleOwnerChange(e, index, i)
                                    }
                                    options={ownership}
                                    value={ownership.find(
                                      (e) => e.id == unit?.unit_ownership_type
                                    )}
                                    getOptionLabel={(option) => option?.name}
                                  />
                                </td>
                                <td className="" width="15%">
                                  <Select
                                    styles={colourStyles}
                                    required
                                    onChange={(e) =>
                                      handleUnitOwner("unit_owner", e, index, i)
                                    }
                                    value={owners?.find(
                                      (e) => e.id == unit?.unit_owner
                                    )}
                                    options={owners}
                                    getOptionLabel={(option) => option?.name}
                                    isClearable
                                  />
                                </td>
                                <td className="" width="15%">
                                  <Select
                                    styles={colourStyles}
                                    onChange={(e) =>
                                      handleUnitOwner(
                                        "unit_tenant",
                                        e,
                                        index,
                                        i
                                      )
                                    }
                                    value={tenant?.find(
                                      (e) => e.id == unit?.unit_tenant
                                    )}
                                    options={tenant}
                                    getOptionLabel={(option) => option.name}
                                    isClearable
                                  />
                                </td>
                                <td width="10%">
                                  <input
                                    onChange={(e) =>
                                      handleUnitChange(e, index, i)
                                    }
                                    type="text"
                                    value={unit?.unit_remarks}
                                    name="unit_remarks"
                                    className="form-control form-control-sm"
                                  />
                                </td>
                                <td width="5%">
                                  {unit?.is_bill_map_with_fee_setup ==
                                    undefined ? (
                                    ""
                                  ) : Number(
                                    unit?.is_bill_map_with_fee_setup
                                  ) === 0 ? (
                                    <FaRegCircleXmark
                                      onClick={() => {
                                        setfloorInfoProps(unit);
                                        openModalAppBilling();
                                      }}
                                      style={{
                                        fontSize: "14px",
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <FaCheckCircle
                                      onClick={() => {
                                        Swal.fire({
                                          title: "Are you sure?",
                                          text: "You won't be able to revert this!",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Yes, delete it!",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            if (unit?.id) {
                                              http
                                                .delete(
                                                  `/delete-bill-map-data/${unit.id}`
                                                )
                                                .then((res) => {
                                                  // setSelectedBuildings(value);
                                                  settableInfoUpdate(
                                                    Math.random()
                                                  );
                                                });
                                            }
                                            Swal.fire({
                                              position: "top-center",
                                              icon: "success",
                                              title: "Deleted!",
                                              text: "Your data has been deleted.",
                                              timer: 2500,
                                            });
                                          }
                                        });
                                      }}
                                      style={{
                                        fontSize: "14px",
                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </td>
                                <td className="">
                                  <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                      <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="languageDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <span className="ms-1 me-1 d-none d-md-inline-block">

                                          <FaEllipsisV />
                                        </span>
                                      </a>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="languageDropdown"
                                      >
                                        {unit?.id && (
                                          <a
                                            href="javascript:;"
                                            onClick={() => {
                                              setfloorInfoProps(unit);
                                              openModalAppBilling();
                                            }}
                                            className="cursor-pointer dropdown-item py-2"
                                          >
                                            <img
                                              src={infoIcon2}
                                              alt=""
                                              className="img-fluid"
                                            />
                                            <span className="ms-1">

                                              Billing Map
                                            </span>
                                          </a>
                                        )}

                                        <a
                                          href="javascript:;"
                                          className="cursor-pointer dropdown-item py-2"
                                        >
                                          <img
                                            src={infoIcon}
                                            alt=""
                                            className="img-fluid"
                                          />
                                          <span className="ms-1">

                                            Owner Details
                                          </span>
                                        </a>
                                        <a
                                          href="javascript:;"
                                          className="cursor-pointer dropdown-item py-2"
                                        >
                                          <img
                                            src={infoIcon2}
                                            alt=""
                                            className="img-fluid"
                                          />
                                          <span className="ms-1">

                                            Tenant Details
                                          </span>
                                        </a>
                                        <button
                                          onClick={() => {
                                            console.log("units index", i);
                                            console.log(
                                              "units add",
                                              selectedBuildings[index]?.units
                                            );

                                            const ext = [...selectedBuildings];
                                            const obj = {
                                              id: "",
                                              u_id: "",
                                              floor: unit?.floor,
                                              unit: "",
                                              unit_name: "",
                                              unit_size: "",
                                              unit_measurement: "",
                                              unit_owner: "",
                                              unit_remarks: "",
                                              unit_ownership_type: "",
                                            };
                                            ext[index]?.units.splice(
                                              i + 1,
                                              0,
                                              obj
                                            );
                                            setSelectedBuildings(ext);
                                          }}
                                          className="cursor-pointer dropdown-item py-2"
                                        >
                                          <img
                                            src={infoIcon2}
                                            alt=""
                                            className="img-fluid"
                                          />
                                          <span className="ms-1">

                                            Add Unit
                                          </span>
                                        </button>
                                        {unit?.id === "" && (
                                          <a
                                            onClick={() => {
                                              const existing = [
                                                ...selectedBuildings,
                                              ];
                                              existing[index]["units"].splice(
                                                i,
                                                1
                                              );

                                              setSelectedBuildings(existing);
                                            }}
                                            className="cursor-pointer dropdown-item py-2"
                                          >

                                            <img
                                              src={remove}
                                              alt=""
                                              className="img-fluid"
                                            />
                                            <span className="ms-1">

                                              Remove
                                            </span>
                                          </a>
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                        </table>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={() => {
                            const existing = [...selectedBuildings];
                            const floors =
                              Number(existing[index].total_floors) + 1;
                            const data = existing[index];
                            for (
                              let i = 1;
                              i <= Number(data?.unit_per_floor);
                              i++
                            ) {
                              existing[index]["units"].push({
                                id: "",
                                u_id: "",
                                floor: floors,
                                unit: i,
                                unit_name: "",
                                unit_size: "",
                                unit_measurement: "",
                                unit_owner: "",
                                unit_remarks: "",
                                unit_ownership_type: "",
                              });
                            }

                            existing[index].total_floors = floors;
                            existing[index].total_units =
                              existing[index]?.units?.length;
                            setSelectedBuildings(existing);
                          }}
                          className="btn btn-sm btn-outline-success"
                        >
                          <FaPlus />
                        </button>
                      </div>
                    </div>
                  ))}
                <div className="col-12">
                  <div className="d-flex justify-content-end">
                    <Link
                      to="/properties"
                      className="btn btn-sm btn-primary me-1"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        marginTop: "0px",
                      }}
                    >
                      Back
                    </Link>
                    <button
                      type="button"
                      onClick={() => handleNextPage("pills-home-tab")}
                      className="btn btn-sm btn-primary me-1 mt-0"
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      onClick={() => handleNextPage("pills-contact-tab")}
                      className="btn btn-sm btn-primary me-1 mt-0 "
                    >
                      Next
                    </button>
                    {selectedBuildings?.length > 0 && (
                      <>
                        {isLoding ? (
                          <button
                            type="button"
                            className="btn btn-sm btn-primary me-1 mt-0"
                          >
                            loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary me-1 mt-0"
                          >
                            Save
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </form> */}
            </div>
            <AppartmentBillingMapModal
              isOpen={modalIsOpenAppBilling}
              closeModalAppBilling={closeModalAppBilling}
              floorInfoProps={floorInfoProps}
              setfloorInfoProps={setfloorInfoProps}
              buildingInfo={buildingInfo}
              setbuildingInfo={setbuildingInfo}
              settableInfoUpdate={settableInfoUpdate}
              content="Modal Content Goes Here"
            />
            {
              rentMapModal &&
              <RentMap isOpen={rentMapModal}
                closeModalBillMap={closeModalRentMap}
              />
            }

            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <form onSubmit={handleSubmit}>
                {contactDetails?.map((item, i) => {
                  return (
                    <div key={item.u_id} className="row mb-2">
                      <div className="col-md-6">
                        <div className="row mb-2">
                          <div className="col-3">
                            <label>

                              {lan?.name}<span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <input
                              required
                              type="text"
                              onChange={(e) => handleContactDetailsChange(e, i)}
                              value={item?.name}
                              name="name"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <label> {lan?.phone1}</label>
                          </div>
                          <div className="col-9">
                            <input
                              type="number"
                              value={item?.phone_1}
                              onChange={(e) => handleContactDetailsChange(e, i)}
                              name="phone_1"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <label>

                              {lan?.mobile1}<span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <input
                              required
                              type="number"
                              value={item?.mobile_1}
                              onChange={(e) => handleContactDetailsChange(e, i)}
                              name="mobile_1"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <label>

                              {lan?.emergency}<span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <input
                              required
                              type="text"
                              value={item?.emergency_contact}
                              onChange={(e) => handleContactDetailsChange(e, i)}
                              name="emergency_contact"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <label htmlFor="address1">
                              {lan?.presentAddress}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <textarea
                              required
                              value={item?.present_address}
                              onChange={(e) => handleContactDetailsChange(e, i)}
                              name="present_address"
                              className="form-control form-control-sm"
                              id=""
                              cols="30"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row mb-2">
                          <div className="col-3">
                            <label>
                              {lan?.designation}<span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-9">
                            <Select
                              required
                              className="basic-single"
                              styles={colourStyles}
                              onChange={(e) =>
                                handleContactDesignationChange(e, i)
                              }
                              options={designation}
                              value={designation.find(
                                (e) => e.id == item?.designation
                              )}
                              getOptionLabel={(option) => option.name}
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-3">
                            <label> {lan?.phone2}</label>
                          </div>
                          <div className="col-9">
                            <input
                              type="number"
                              value={item?.phone_2}
                              onChange={(e) => handleContactDetailsChange(e, i)}
                              name="phone_2"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <label> {lan?.mobile2}</label>
                          </div>
                          <div className="col-9">
                            <input
                              type="number"
                              value={item?.mobile_2}
                              onChange={(e) => handleContactDetailsChange(e, i)}
                              name="mobile_2"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <label> {lan?.email}</label>
                          </div>
                          <div className="col-9">
                            <input
                              type="email"
                              value={item?.email}
                              onChange={(e) => handleContactDetailsChange(e, i)}
                              name="email"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <label htmlFor="address1"> {lan?.permanentAddress}</label>
                          </div>
                          <div className="col-9">
                            <textarea
                              onChange={(e) => handleContactDetailsChange(e, i)}
                              name="permanent_address"
                              value={item?.permanent_address}
                              className="form-control form-control-sm"
                              id=""
                              cols="30"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex justify-content-end">
                          {contactDetails?.length - 1 === i && (
                            <button
                              onClick={handleAddContactDetails}
                              className="btn btn-sm btn-primary me-1 mt-1"
                            >
                              {lan?.add}
                            </button>
                          )}
                          {contactDetails?.length !== 1 &&
                            (item.id ? (
                              <button
                                type="button"
                                onClick={() => deleteContact(item.id)}
                                className="btn btn-sm btn-primary me-1 mt-1"
                              >
                                {lan?.remove}
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  handleRemoveContactDetails(item.u_id, i)
                                }
                                className="btn btn-sm btn-primary me-1 mt-1"
                              >
                                {lan?.remove}
                              </button>
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="col-12">
                  <div className="d-flex justify-content-end mt-2">
                    <Link
                      to="/properties"
                      className="btn btn-sm btn-primary me-1"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        marginTop: "-5px",
                      }}
                    >
                      {lan?.back}
                    </Link>
                    <button
                      type="button"
                      onClick={() => handleNextPage("pills-profile-tab")}
                      className="btn btn-sm btn-primary me-1"
                    >
                      {lan?.previous}
                    </button>
                    {isLoding ? (
                      <button type="button" className="btn btn-sm btn-primary">
                        {lan?.loading}...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-sm btn-primary">
                        {lan?.save}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
}
