const english = {
    rentSetup: "Rent Setup",
    familyMemberType: "Family Member Type",
    genderType: "Gender Type",
    serviceGroup: "Service Group",
    fees: "Fees",
    serviceUnit: "Service Unit",
    serviceType: "Service Type",
  notice: "Notice",
  helpLine: "Help Line",
  buildingSetup: "Building Setup",
  ownershipType: "Ownership Type",
  propertyType: "Property Type",
  designation: "Designation",
  nationality: "Nationality",
  bloodGroup: "Blood Group",
  division: "Division",
  district: "District",
  area: "Area",
  policeStations: "Police Stations",
  unitMeasurement: "Unit Measurement",
  sl: "SL",
  type: "Type",
  price: "Price",
  action: "Action",
  name: "Name",
  add: "Add",
  edit: "Edit",
  save: "Save",
  update: "Update",
  saveMessage: "Data has been saved successfully",
  updateMessage: "Data has been updated successfully",
  deleteMessage: "Data has been deleted successfully",
  yes: "Yes",
  no: "No",
  cancel : "Cancel",
  title: "Title",
  pdf: "PDF",
  image: "Image",
  helpLineCategory: "Help Line Category",
  helplineNumber: "Helpline Number",
  policeStation: "Police Station",
  areYouSure: "Are you sure?",
  deleteWarning: "You won't be able to revert this!",
  yesDelete: "Yes, delete it!",
  loading: "Loading",
  select: "Select",
  symbol: "Symbol",
  timezone: "Timezone",
  country: "Country",
}
const malay = {
  rentSetup: "Tetapan Sewa",
  familyMemberType: "Jenis Ahli Keluarga",
  genderType: "Jenis Jantina",
  serviceGroup: "Kumpulan Perkhidmatan",
  fees: "Yuran",
  serviceUnit: "Unit Perkhidmatan",
  serviceType: "Jenis Perkhidmatan",
  notice: "Notis",
  helpLine: "Talian Bantuan",
  buildingSetup: "Tetapan Bangunan",
  ownershipType: "Jenis Pemilikan",
  propertyType: "Jenis Harta",
  designation: "Jawatan",
  nationality: "Kewarganegaraan",
  bloodGroup: "Kumpulan Darah",
  division: "Bahagian",
  district: "Daerah",
  area: "Kawasan",
  policeStations: "Balai Polis",
  unitMeasurement: "Ukuran Unit",
  sl: "SL",
  type: "Jenis",
  price: "Harga",
  action: "Tindakan",
  name: "Nama",
  add: "Tambah",
  edit: "Edit",
  save: "Simpan",
  update: "Kemas Kini",
  saveMessage: "Data telah disimpan dengan berjaya",
  updateMessage: "Data telah dikemas kini dengan berjaya",
  deleteMessage: "Data telah dipadamkan dengan berjaya",
  yes: "Ya",
  no: "Tidak",
  cancel: "Batal",
  title: "Tajuk",
  pdf: "PDF",
  image: "Imej",
  helpLineCategory: "Kategori Talian Bantuan",
  helplineNumber: "Nombor Talian Bantuan",
  policeStation: "Balai Polis",
  areYouSure: "Adakah anda pasti?",
deleteWarning: "Anda tidak akan dapat membatalkan ini!",
yesDelete: "Ya, hapuskan!",
deleted: "Dihapus",
loading: "Memuatkan",
select: "Pilih",
symbol: "Simbol",
timezone: "Zon Waktu",
country: "Negara",


}

const thai = {
  rentSetup: "การตั้งค่าเช่า",
  familyMemberType: "ประเภทสมาชิกในครอบครัว",
  genderType: "ประเภทเพศ",
  serviceGroup: "กลุ่มบริการ",
  fees: "ค่าธรรมเนียม",
  serviceUnit: "หน่วยบริการ",
  serviceType: "ประเภทบริการ",
  notice: "ประกาศ",
  helpLine: "สายด่วน",
  buildingSetup: "การตั้งค่าอาคาร",
  ownershipType: "ประเภทการเป็นเจ้าของ",
  propertyType: "ประเภททรัพย์สิน",
  designation: "การกำหนดตำแหน่ง",
  nationality: "สัญชาติ",
  bloodGroup: "กรุ๊ปเลือด",
  division: "แผนก",
  district: "อำเภอ",
  area: "พื้นที่",
  policeStations: "สถานีตำรวจ",
  unitMeasurement: "หน่วยวัด",
  sl: "ลำดับ",
  type: "ประเภท",
  price: "ราคา",
  action: "การกระทำ",
  name: "ชื่อ",
  add: "เพิ่ม",
  edit: "แก้ไข",
  save: "บันทึก",
  update: "อัปเดต",
  saveMessage: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
  updateMessage: "ข้อมูลได้รับการอัปเดตเรียบร้อยแล้ว",
  deleteMessage: "ข้อมูลถูกลบเรียบร้อยแล้ว",
  yes: "ใช่",
  no: "ไม่",
  cancel: "ยกเลิก",
  title: "หัวข้อ",
  pdf: "PDF",
  image: "ภาพ",
  helpLineCategory: "ประเภทสายด่วน",
  helplineNumber: "หมายเลขสายด่วน",
  policeStation: "สถานีตำรวจ",
  areYouSure: "คุณแน่ใจหรือไม่?",
deleteWarning: "คุณจะไม่สามารถย้อนกลับได้!",
yesDelete: "ใช่, ลบเลย!",
deleted: "ลบแล้ว",
loading: "กำลังโหลด",
select: "เลือก",
symbol: "สัญลักษณ์",
timezone: "เขตเวลา",
country: "ประเทศ",

};

const arabic = {
  rentSetup: "إعداد الإيجار",
  familyMemberType: "نوع أفراد الأسرة",
  genderType: "نوع الجنس",
  serviceGroup: "مجموعة الخدمة",
  fees: "الرسوم",
  serviceUnit: "وحدة الخدمة",
  serviceType: "نوع الخدمة",
  notice: "إشعار",
  helpLine: "خط المساعدة",
  buildingSetup: "إعداد المبنى",
  ownershipType: "نوع الملكية",
  propertyType: "نوع الملكية",
  designation: "تعيين",
  nationality: "الجنسية",
  bloodGroup: "فصيلة الدم",
  division: "قسم",
  district: "الحي",
  area: "المنطقة",
  policeStations: "مراكز الشرطة",
  unitMeasurement: "وحدة القياس",
  sl: "م",
  type: "نوع",
  price: "السعر",
  action: "الإجراء",
  name: "الاسم",
  add: "إضافة",
  edit: "تعديل",
  save: "حفظ",
  update: "تحديث",
  saveMessage: "تم حفظ البيانات بنجاح",
  updateMessage: "تم تحديث البيانات بنجاح",
  deleteMessage: "تم حذف البيانات بنجاح",
  yes: "نعم",
  no: "لا",
  cancel: "إلغاء",
  title: "العنوان",
  pdf: "PDF",
  image: "صورة",
  helpLineCategory: "فئة خط المساعدة",
  helplineNumber: "رقم خط المساعدة",
  policeStation: "مركز الشرطة",
  areYouSure: "هل أنت متأكد؟",
  deleteWarning: "لن تتمكن من إعادة هذا!",
  yesDelete: "نعم، احذفه!",
  deleted: "محذوف",
  loading: "جارٍ التحميل",
  select: "اختر",
  symbol: "رمز",
timezone: "المنطقة الزمنية",
country: "البلد",
};

const bengali = {
  rentSetup: "ভাড়ার সেটআপ",
  familyMemberType: "পরিবারের সদস্যের প্রকার",
  genderType: "লিঙ্গের প্রকার",
  serviceGroup: "সার্ভিস গ্রুপ",
  fees: "ফি",
  serviceUnit: "সার্ভিস ইউনিট",
  serviceType: "সার্ভিস প্রকার",
  notice: "বিজ্ঞপ্তি",
  helpLine: "হেল্পলাইন",
  buildingSetup: "বিল্ডিং সেটআপ",
  ownershipType: "মালিকানার প্রকার",
  propertyType: "সম্পত্তির প্রকার",
  designation: "পদবী",
  nationality: "জাতীয়তা",
  bloodGroup: "রক্তের গ্রুপ",
  division: "বিভাগ",
  district: "জেলা",
  area: "এলাকা",
  policeStations: "পুলিশ স্টেশন",
  unitMeasurement: "একক পরিমাপ",
  sl: "ক্রমিক",
  type: "প্রকার",
  price: "মূল্য",
  action: "কার্য",
  name: "নাম",
  add: "যোগ করুন",
  edit: "সম্পাদনা করুন",
  save: "সংরক্ষণ করুন",
  update: "আপডেট করুন",
  yes: "হ্যাঁ",
  no: "না",
  cancel: "বাতিল করুন",
  title: "শিরোনাম",
  pdf: "পিডিএফ",
  image: "চিত্র",
  helpLineCategory: "হেল্পলাইন ক্যাটাগরি",
  helplineNumber: "হেল্পলাইন নম্বর",
  policeStation: "পুলিশ স্টেশন",
  areYouSure: "আপনি কি নিশ্চিত?",
deleteWarning: "আপনি এটি ফিরে পেতে পারবেন না!",
saveMessage: "তথ্য সফলভাবে সংরক্ষিত হয়েছে",
  updateMessage: "তথ্য সফলভাবে আপডেট হয়েছে",
  deleteMessage: "তথ্য সফলভাবে মুছে ফেলা হয়েছে",
yesDelete: "হ্যাঁ, মুছে ফেলুন!",
deleted: "মুছে ফেলা হয়েছে",
loading: "লোড হচ্ছে",
select: "নির্বাচন করুন",
symbol: "প্রতীক",
timezone: "সময় অঞ্চল",
country: "দেশ",
}

export const adminSetupLang = {
    english,
    malay,
    thai, 
    arabic,
    bengali
}