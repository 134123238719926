import React, { useContext } from 'react'
import propertieContext from '../../navbar/propertieContext';
import moment from 'moment';
import Setting from '../Setting/Setting';
import './PrintStatmentHeader.css'

export default function PrintStatmentHeader({ name, start, end }) {
    const { organizationInfo } = useContext(propertieContext);
    const { dateFormat } = Setting;


    return (
        <div className="header-statment d-flex justify-content-center mb-2">
            <div className="text-center">
                {
                    organizationInfo?.user?.organization_logo &&
                    <img
                        src={`${organizationInfo?.user?.organization_logo}`}
                        width="50"
                        height="50"
                        alt="Profile"
                    />
                }
                <h5>{organizationInfo?.user?.organization_name}</h5>
                <p>{organizationInfo?.user?.organization_email}</p>
                <p>{organizationInfo?.user?.organization_mobile}</p>
                <p>{name}</p>

                <h5>{`${moment(start).format(dateFormat)} to ${moment(end).format(dateFormat)}`}</h5>
            </div>
        </div>
    )
}
