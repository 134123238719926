import React from "react";
import moment from "moment";

import Setting from "../../Setting/Setting";

import logo from "./../../../front_assets/Logo_Image/logo.jpg";
import useLanguage from "../../../language/useLanguage";
import { paymentHistoryLang } from "../../../language/billingManagement/paymentHistoryLang";

export default function PaymentSlip({
  ownerDetails,
  singlePaymentHitory,
  amount,
  invoice,
}) {
  const { currencyFormat } = Setting;
  const lan = useLanguage(paymentHistoryLang);

  return (
    <>
      <div className="product_modal">
        <div className="page-content">
          <div className="history-body">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h4 className="text-uppercase">{lan?.paymentReceipt}</h4>
              <h5 className="text-uppercase">
                {lan?.original}
              </h5>
              <img src={logo} width="50" height="50" alt="logo" />
            </div>
            <div className="owner_info mb-2">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-3">{lan?.name} </div>
                    <div className="col-9">
                      :
                      {ownerDetails?.tenant === null
                        ? ownerDetails?.owner?.name
                        : ownerDetails?.tenant?.name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">{lan?.mobile} </div>
                    <div className="col-9">
                      :
                      {ownerDetails?.tenant === null
                        ? ownerDetails?.owner?.mobile_1
                        : ownerDetails?.tenant?.mobile_1}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-3">{lan?.email}</div>
                    <div className="col-9">
                      :
                      {ownerDetails?.tenant === null
                        ? ownerDetails?.owner?.email
                        : ownerDetails?.tenant?.email}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">{lan?.address}</div>
                    <div className="col-9">
                      :
                      {ownerDetails?.tenant === null
                        ? ownerDetails?.owner?.address_line_1
                        : ownerDetails?.tenant?.address_line_1}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex mb-2">
              <div className="col-3 me-1 owner_info">
                <p>{lan?.total} {lan?.amount} :</p>
                <p>{currencyFormat(amount?.grand_total)}</p>
              </div>
              <div className="col-3 me-1 owner_info">
                <p>{lan?.receivedAmount} :</p>
                <p>{currencyFormat(amount?.received_amount)}</p>
              </div>
              <div className="col-3 owner_info">
                <p>{lan?.dueAmount} :</p>
                <p>{currencyFormat(amount?.due)}</p>
              </div>
            </div>

            <div className="owner_info d-flex justify-content-between mb-2">
              <div className="d-flex gap-2">
                <p>{lan?.invoice} :</p>
                <p>{invoice}</p>
              </div>
            </div>

            <div className="unit-manager-table invoice-item-table mb-3">
              <table className="">
                <tr>
                  <td>{lan?.mrn}</td>
                  <td>{lan?.paymentDate}</td>
                  <td>{lan?.time}</td>

                  <td>{lan?.paymentMethod}</td>
                  <td>{lan?.bankCard}</td>
                  <td>{lan?.number}</td>
                  <td>{lan?.expiryDate}</td>
                  <td>{lan?.amount}</td>
                </tr>
                <tr>
                  <td>{singlePaymentHitory?.slip_number}</td>
                  <td>
                    {moment(singlePaymentHitory?.created_at).format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                  <td>
                    {moment(singlePaymentHitory?.created_at).format("h:mm a")}
                  </td>
                  <td>{singlePaymentHitory?.payment_method}</td>
                  <td>{singlePaymentHitory?.name}</td>
                  <td>{singlePaymentHitory?.number}</td>
                  <td>
                    {singlePaymentHitory?.expired_Date &&
                      moment(singlePaymentHitory?.expired_Date).format(
                        "DD/MM/YYYY"
                      )}
                  </td>
                  <td align="right">{singlePaymentHitory?.amount}</td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    
                    <b>{lan?.total}</b>
                  </td>
                  <td align="right">
                    <b>{currencyFormat(singlePaymentHitory?.amount)}</b>
                  </td>
                </tr>
              </table>
              <p className="mt-1">
                {lan?.totalAmountOfReceived}:
                {currencyFormat(singlePaymentHitory?.amount)}
              </p>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <p style={{ borderTop: "1px dashed grey", paddingTop: "10px" }}>
                {lan?.authorizedSignature}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="product_modal">
        <div className="page-content">
        <div className="history-body">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h4 className="text-uppercase">{lan?.paymentReceipt}</h4>
              <h5 className="text-uppercase">
                {lan?.customerCopy}
              </h5>
              <img src={logo} width="50" height="50" alt="logo" />
            </div>
            <div className="owner_info mb-2">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-3">{lan?.name} </div>
                    <div className="col-9">
                      :
                      {ownerDetails?.tenant === null
                        ? ownerDetails?.owner?.name
                        : ownerDetails?.tenant?.name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">{lan?.mobile} </div>
                    <div className="col-9">
                      :
                      {ownerDetails?.tenant === null
                        ? ownerDetails?.owner?.mobile_1
                        : ownerDetails?.tenant?.mobile_1}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-3">{lan?.email}</div>
                    <div className="col-9">
                      :
                      {ownerDetails?.tenant === null
                        ? ownerDetails?.owner?.email
                        : ownerDetails?.tenant?.email}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">{lan?.address}</div>
                    <div className="col-9">
                      :
                      {ownerDetails?.tenant === null
                        ? ownerDetails?.owner?.address_line_1
                        : ownerDetails?.tenant?.address_line_1}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex mb-2">
              <div className="col-3 me-1 owner_info">
                <p>{lan?.total} {lan?.amount} :</p>
                <p>{currencyFormat(amount?.grand_total)}</p>
              </div>
              <div className="col-3 me-1 owner_info">
                <p>{lan?.receivedAmount} :</p>
                <p>{currencyFormat(amount?.received_amount)}</p>
              </div>
              <div className="col-3 owner_info">
                <p>{lan?.dueAmount} :</p>
                <p>{currencyFormat(amount?.due)}</p>
              </div>
            </div>

            <div className="owner_info d-flex justify-content-between mb-2">
              <div className="d-flex gap-2">
                <p>{lan?.invoice} :</p>
                <p>{invoice}</p>
              </div>
            </div>

            <div className="unit-manager-table invoice-item-table mb-3">
              <table className="">
                <tr>
                  <td>{lan?.mrn}</td>
                  <td>{lan?.paymentDate}</td>
                  <td>{lan?.time}</td>

                  <td>{lan?.paymentMethod}</td>
                  <td>{lan?.bankCard}</td>
                  <td>{lan?.number}</td>
                  <td>{lan?.expiryDate}</td>
                  <td>{lan?.amount}</td>
                </tr>
                <tr>
                  <td>{singlePaymentHitory?.slip_number}</td>
                  <td>
                    {moment(singlePaymentHitory?.created_at).format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                  <td>
                    {moment(singlePaymentHitory?.created_at).format("h:mm a")}
                  </td>
                  <td>{singlePaymentHitory?.payment_method}</td>
                  <td>{singlePaymentHitory?.name}</td>
                  <td>{singlePaymentHitory?.number}</td>
                  <td>
                    {singlePaymentHitory?.expired_Date &&
                      moment(singlePaymentHitory?.expired_Date).format(
                        "DD/MM/YYYY"
                      )}
                  </td>
                  <td align="right">{singlePaymentHitory?.amount}</td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    
                    <b>{lan?.total}</b>
                  </td>
                  <td align="right">
                    <b>{currencyFormat(singlePaymentHitory?.amount)}</b>
                  </td>
                </tr>
              </table>
              <p className="mt-1">
                {lan?.totalAmountOfReceived}:
                {currencyFormat(singlePaymentHitory?.amount)}
              </p>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <p style={{ borderTop: "1px dashed grey", paddingTop: "10px" }}>
                {lan?.authorizedSignature}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
