import React, { useState } from "react";
import AuthUser from "./AuthUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../CSS/login.css";
import http from "../http";
import logo from "../front_assets/Logo_Image/logoWhite.png";
import CircularProgress from "@mui/material/CircularProgress";
import { FaCheckCircle } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { GoEyeClosed } from "react-icons/go";

export default function Login() {
  const { setUser } = AuthUser();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isRemember, setIsRemember] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const title = "Innovative solutions for seamless Apartment management";
  const content = [
    {
      title: "Quick integration",
      description: "Few lines of code, within 30 mins",
    },
    {
      title: "Easy management",
      description: "Efficient and user-friendly",
    },
    {
      title: "Secure",
      description: "Top-notch security measures",
    },
  ];

  const submitForm = (e) => {
    e.preventDefault();
    setisLoading(true);
    http
      .post("login-with-saas", { email: email, password: password })
      .then((res) => {
        console.log(res.data);
        setUser(res.data, isRemember);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        toast.error("Invalid Phone or Password", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div className="app-content">
      <div className="content-wrapper">
        <div className="content-body">
          <div className="auth-wrapper auth-basic">
            <div className="auth-inner ">
              <ToastContainer />
              <div className="row m-0 p-0">
                <div
                  style={{
                    height: "100dvh",
                    paddingTop: "5rem",
                    paddingLeft: "2rem",
                    color: "white",
                    background:
                      "linear-gradient(135deg, #035E7B 0%, #039BE5 100%)",
                  }}
                  className="col-md-6 flex-grow-1"
                >
                  <div className="d-flex flex-column h-100">
                    <img src={logo} alt="logo" style={{ width: "200px" }} />
                    <h2
                      sx={{
                        letterSpacing: ".01em",
                        fontSize: "24px",
                        fontWeight: "500",
                        fontFamily: "roboto",
                      }}
                    >
                      {title}
                    </h2>
                    {content.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          alignItems: "center",
                          marginLeft: "3rem",
                          marginTop: "2rem",
                          display: "flex",
                          gap: "0.3rem",
                          justifyContent: "start",
                        }}
                      >
                        <h5 style={{ fontSize: "16px" }}>
                          <FaCheckCircle style={{ fontSize: "22px" }} />
                        </h5>
                        <div>
                          <h5 style={{ marginLeft: "1rem", fontSize: "16px" }}>
                            {item.title}
                          </h5>
                          <h5 style={{ marginLeft: "1rem", fontSize: "16px" }}>
                            {item.description}
                          </h5>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="col-md-6"
                  style={{
                    background: "white",
                    height: "100vh",
                    padding: "3rem 6rem",
                    color: "#545454",
                  }}
                >
                  <h2
                    style={{
                      marginTop: "6rem",
                      fontSize: "24px",
                      fontWeight: "500",
                      fontFamily: "roboto",
                    }}
                  >
                    Welcome to login user
                  </h2>
                  <p style={{ color: "black" }}>
                    Please Login to your account and start the adventure.
                  </p>
                  <form className="auth-login-form mt-4" onSubmit={submitForm}>
                    <div className="mb-1">
                      <label
                        htmlFor="login-email"
                        className="form-label fw-bold"
                      >
                        Phone number
                      </label>
                      <input
                        type="text"
                        className="form-control input-style"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Phone Number"
                        aria-describedby="login-email"
                        tabIndex={1}
                        autofocus
                      />
                    </div>
                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label
                          className="form-label fw-bold"
                          htmlFor="login-password"
                        >
                          Password
                        </label>
                      </div>
                      <div
                        className="input-group input-group-merge form-password-toggle"
                        style={{
                          borderRadius: "5px",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          type={isShowPassword ? "text" : "password"}
                          className="form-control form-control-merge input-style"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          tabIndex={2}
                          placeholder="Enter Password"
                          aria-describedby="login-password"
                        />
                        <span className="input-group-text cursor-pointer">
                          {!isShowPassword ? (
                            <GoEyeClosed
                              color="#545454"
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            />
                          ) : (
                            <FiEye
                              color="#545454"
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            />
                          )}
                        </span>
                        <br />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-check mt-2">
                        <input
                          onChange={(e) => setIsRemember(e.target.checked)}
                          className="form-check-input"
                          type="checkbox"
                          id="remember-me"
                          tabIndex={3}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="remember-me"
                        >
                          {" "}
                          Remember Me{" "}
                        </label>
                      </div>
                      {isLoading ? (
                        <button
                          type="button"
                          className="btn btn-primary float-end mt-2"
                        >
                          {" "}
                          <CircularProgress
                            style={{
                              color: "white",
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </button>
                      ) : (
                        <input
                          type="submit"
                          value="Login"
                          className="btn btn-primary float-end mt-2"
                        />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
