
import MaterialTable from 'material-table'
import React from 'react'
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import '../../../CSS/Table.css'

import Swal from 'sweetalert2';
import http from '../../../http';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useLanguage from '../../../language/useLanguage';
import { feeSetupLang } from '../../../language/feeSetup/feeSetupLang';
const customStyles = {
    content: {
        top: "25%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

export default function FeeServiceSetup() {
    const lan = useLanguage(feeSetupLang);
    const [data, setData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [random, setRandom] = useState();
    const [activeData, setActiveData] = useState({});
    const [modalIsOpen, setIsOpen] = useState(false);
    const [type, setType] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [updateButton, setUpdateButton] = useState(false);
    
    function closeModal() {
        setIsOpen(false);
    }
    const [error, setError] = useState({
        content: ''
    });

    const [remarksData, setRemarksData] = useState({
        content: '',
    })

    

    const active = (id) => {
        setIsOpen(true);
        if (remarksData?.content) {
            setError({ ...error, content: "" })
        }
        else if (!remarksData?.content?.length > 40) {
            setError({ ...error, content: "" })
        }
        const findData = data?.find((item) => item.id == id)
        setActiveData(findData)
    }

    const columns = [
        {
            title: lan?.sl,
            field: "",
            render: (row) => <div>{row.tableData.id + 1}</div>,
            width: "20 !important",
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.group,
            field: 'service_group',
            render: (row) => <div><p>{row?.service?.service_group}</p></div>,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: lan?.type,
            field: `service_type`,
            render: (row) => <div><p>{row?.service_type?.service_type}</p></div>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.effectiveDate,
            field: `effactive_date`,
            render: (row) => <div>{moment(row?.effactive_date).format('LL')}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.endDate,
            field: `end_date`,
            render: (row) => <div> {row?.end_date == null ? (
                <p></p>
            ) :
                (
                    <p> {moment(row?.end_date).format('LL')}</p>
                )}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.active,
            field: `active`,
            render: (row) =>
                <div>
                    {row?.isActive == 0 ? (
                        <p
                            className="badge text-bg-primary bg-danger cursor-pointer"
                            onClick={() => active(row?.id)}
                        >
                            {lan?.inactive}
                        </p>
                    ) : (
                        <p
                            className="badge text-bg-primary bg-primary cursor-pointer"
                            onClick={() => active(row?.id)}
                        >
                            {lan?.active}
                        </p>
                    )
                    }
                </div >,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.user,
            field: `user`,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.remark,
            field: `remarks`,
            render: (row) => <div>{row?.remarks[0]?.remarks}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: lan?.action,
            field: "action",
            render: (row) => (
                <div>
                    {row?.isActive == 1 ? (
                        <>
                            <Link to={`/view-service-fee-setup/${row.id}`}>
                                <button className="btn btn-sm action-btn">
                                    <i className="far fa-eye"></i>
                                </button>
                            </Link>
                            <Link to={`/edit-service-fee-setup/${row.id}`}>
                                <button className="btn btn-sm action-btn">
                                    <i className="far fa-edit"></i>
                                </button>
                            </Link>
                        </>
                    ) : (
                        <Link to={`/view-service-fee-setup/${row.id}`}>
                            <button className="btn btn-sm action-btn">
                                <i className="far fa-eye"></i>
                            </button>
                        </Link>
                    )
                    }
                </div>
            ),
            cellStyle: {
                textAlign: "center",
            },
        },
    ];


    useEffect(() => {
        http
            .get(`fee-setup`)
            .then((res) => {
                setData(res?.data?.data)
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });
        http
            .get(`invalid-fee-service-setup`)
            .then((res) => {
                console.log('res123', res?.data)
                if (res?.data?.data) {
                    Swal.fire({
                        title: 'Add New OR Update Fee Setup',
                        text: res?.data?.message,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Yes, update it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate(`/edit-service-fee-setup/${res?.data?.data?.id}`)
                        }
                    })
                    setSpinner(false);
                }
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });
    }, [random])

    const handleSubmit = (event) => {
        setError({})
        event.preventDefault();
        if (!remarksData?.content) {
            setError({ content: "Required this feild" })
            return false;
        } else if (remarksData?.content?.length > 40) {
            setError({ ...error, content: "Remarks must be no longer then 40 characters" })
            return false;
        } else if (remarksData?.content) {
            setError({ ...error, content: "" })
        }
        else if (!remarksData?.content?.length > 40) {
            setError({ ...error, content: "" })
        }
        const remarks = remarksData?.content;
        http.post(`fee-setup-activate/${activeData.id}`, { remarks: remarks })
            .then((res) => {
                console.log('remarks', res)
                setRandom(Math.random())
                setIsOpen(false)
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Success !',
                    text: 'Banner Deleted Successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                setRandom(Math.random())
            })
    }
    const searchNested = (obj, term) => {
        for (let key in obj) {
            if (typeof obj[key] === 'object') {
                if (searchNested(obj[key], term)) {
                    return true;
                }
            } else if (typeof obj[key] === 'string' && obj[key].toLowerCase().includes(term.toLowerCase())) {
                return true;
            }
        }
        return false;
    };

    // Filter data based on search term
    const filteredData = data.filter(item =>
        searchNested(item, searchTerm) ||
        (item.isActive === 0 && searchTerm.toLowerCase().includes('inActive')) ||
        (item.isActive === 1 && searchTerm.toLowerCase().includes('active'))
    );
    return (
        <div className='page-content adjustment-type-table'>
            <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                <h6>{lan?.feeSetup}</h6>
                <div>
                    <Link to='/add-service-fee-setup'>

                        <button style={{ marginTop: "1px" }} className='btn btn-sm btn-primary float-end'>{lan?.add} </button>
                    </Link>
                </div>
            </div>

            <div className='card'>
                <div className='card-body'>
                    <div className="mb-3">
                        <TextField
                            label="Search"
                            variant="standard"
                            value={searchTerm}
                            style={{ width: "20%" }}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="fee-setup-table">
                        <MaterialTable
                            columns={columns}
                            data={filteredData}
                            isLoading={spinner}
                            options={{
                                search: false,
                                showTitle: false,
                                searchFieldAlignment: "left",
                                pageSize: 10,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [5, 10, 20, 50, 100],
                            }}
                        />
                    </div>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-container">
                    <div className="modal-conatiner-header mb-3">
                        <h2 className="modal-container-header-title">
                            {updateButton ? lan?.remark : lan?.remark}
                        </h2>
                        <button
                            onClick={closeModal}
                            style={{ backgroundColor: "transparent", border: "none" }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="16"
                                width="12"
                                viewBox="0 0 384 512"
                            >
                                <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                            </svg>
                        </button>
                    </div>
                    <form>
                        <div className="form-group">
                            <label htmlFor="content" className="mb-1">
                                {lan?.remarks}
                            </label>
                            <textarea
                                className="form-control"
                                id="content"
                                name="content"
                                value={type}
                                onChange={(event) => setRemarksData({ content: event.target.value })}
                            ></textarea>
                            {error?.content && <div className="text-danger">{error?.content}</div>}
                        </div>
                        <div className="form-group mt-3 float-left">
                            <button className="btn btn-primary btn-sm" onClick={handleSubmit}>{lan?.save}</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}