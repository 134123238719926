const english = {
    billEntry: "Bill Entry",
    properties : "Properties",
    building : "Building",
    flatList : "Flat List",
    searchFlat : "Search Flat",
    name : "Name",
    address : "Address",
    phone: "Phone",
    email: "Email",
    totalBill: "Total Bill",
    billingMonth: "Billing Month",
    dueDate: "Due Date",
    billingDate: "Billing Date",
    code : "Code",
    perUnit: "Per Unit",
    from: "From",
    to: "To",
    qty: "Qty",
    unit: "Unit",
    subTotal: "Sub Total",
    total: "Total",
    submit: "Submit",
    print: "Print",
    recordsNotAvailable: "Records Not Available",
    loading: "Loading",
    warning: "Please add bill in this month",
    warningText : "Warning",
    success: "Success!",
    saveMessage: "Data has been successfully saved",

}
const malay = {
    billEntry: "Masukkan Bil",
    properties: "Hartanah",
    building: "Bangunan",
    flatList: "Senarai Flat",
    searchFlat: "Cari Flat",
    name: "Nama",
    address: "Alamat",
    phone: "Telefon",
    email: "Emel",
    totalBill: "Jumlah Bil",
    billingMonth: "Bulan Bil",
    dueDate: "Tarikh Tamat Tempoh",
    billingDate: "Tarikh Bil",
    code: "Kod",
    perUnit: "Setiap Unit",
    from: "Dari",
    to: "Kepada",
    qty: "Kuantiti",
    unit: "Unit",
    subTotal: "Jumlah Sub",
    total: "Jumlah",
    submit: "Hantar",
    print: "Cetak",
    recordsNotAvailable: "Rekod tidak tersedia",
    loading: "Memuatkan",
    warning: "Sila tambah bil di bulan ini",
    warningText : "Amaran",
    success: "Berjaya!",
    saveMessage: "Data telah berjaya disimpan",
}
const thai = {
    billEntry: "การบันทึกใบเรียกเก็บเงิน",
    properties: "อสังหาริมทรัพย์",
    building: "อาคาร",
    flatList: "รายการแฟลต",
    searchFlat: "ค้นหาแฟลต",
    name: "ชื่อ",
    address: "ที่อยู่",
    phone: "โทรศัพท์",
    email: "อีเมล",
    totalBill: "ยอดรวมใบเรียกเก็บเงิน",
    billingMonth: "เดือนที่เรียกเก็บเงิน",
    dueDate: "วันครบกำหนด",
    billingDate: "วันเรียกเก็บเงิน",
    code: "รหัส",
    perUnit: "ต่อหน่วย",
    from: "จาก",
    to: "ถึง",
    qty: "จำนวน",
    unit: "หน่วย",
    subTotal: "ยอดย่อย",
    total: "ยอดรวม",
    submit: "ส่ง",
    print: "พิมพ์",
    recordsNotAvailable: "ไม่มีบันทึก",
    loading: "กําลังโหลด",
    warning: "กรุณาเพิ่มบันทึกในเดือนนี้",
    warningText : "คําเตือน",
    success: "สำเร็จ!",
    saveMessage: "บันทึกข้อมูลเรียบร้อยแล้ว",
}
const arabic = {
    billEntry: "إدخال الفاتورة",
    properties: "العقارات",
    building: "المبنى",
    flatList: "قائمة الشقق",
    searchFlat: "ابحث عن شقة",
    name: "الاسم",
    address: "العنوان",
    phone: "الهاتف",
    email: "البريد الإلكتروني",
    totalBill: "إجمالي الفاتورة",
    billingMonth: "شهر الفاتورة",
    dueDate: "تاريخ الاستحقاق",
    billingDate: "تاريخ الفاتورة",
    code: "الرمز",
    perUnit: "لكل وحدة",
    from: "من",
    to: "إلى",
    qty: "الكمية",
    unit: "الوحدة",
    subTotal: "الإجمالي الفرعي",
    total: "الإجمالي",
    submit: "تقديم",
    print: "طباعة",
    recordsNotAvailable: "السجلات غير متوفرة",
    loading: "جارٍ التحميل",
    warning: "الرجاء أضافة فاتورة في هذا الشهر",
    warningText: "تحذير",
    success: "نجاح!",
    saveMessage: "تم حفظ البيانات بنجاح",
}
const bengali = {
    billEntry: "বিল এন্ট্রি",
    properties: "সম্পত্তি",
    building: "ভবন",
    flatList: "ফ্ল্যাট তালিকা",
    searchFlat: "ফ্ল্যাট অনুসন্ধান",
    name: "নাম",
    address: "ঠিকানা",
    phone: "ফোন",
    email: "ইমেল",
    totalBill: "মোট বিল",
    billingMonth: "বিলিং মাস",
    dueDate: "নির্ধারিত তারিখ",
    billingDate: "বিলিং তারিখ",
    code: "কোড",
    perUnit: "প্রতি ইউনিট",
    from: "থেকে",
    to: "যেতে",
    qty: "পরিমাণ",
    unit: "ইউনিট",
    subTotal: "উপ-মোট",
    total: "মোট",
    submit: "জমা দিন",
    print: "মুদ্রণ",
    recordsNotAvailable: "রেকর্ডগুলি উপলব্ধ নয়",
    loading: "লোড হচ্ছে",
    warning: "অনুগ্রহ করে এই মাসে বিল যুক্ত করুন",
    warningText: "সতর্ক করুন",
    success: "সফলতা!",
    saveMessage: "ডেটা সফলভাবে সংরক্ষণ করা হয়েছে",
}

export const billEntryLang = {
    english,
    arabic,
    bengali,
    malay,
    thai
}